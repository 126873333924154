import * as React from 'react';
import {ICarouselConfigSourceService, ISource} from 'models/carouselConfigs';
import {Popover, Template, Icon, Box, Stack, TIcons} from '@pluto-tv/assemble';
import {popoverActionsProps} from 'helpers/popoverActionProps';

interface ISourceServiceRowStateProps {
  row: ICarouselConfigSourceService;
  allowedSources: ISource[];
  allowedServices: string[];
}

const SourceServiceRowState = ({
  row,
  allowedSources,
  allowedServices,
}: ISourceServiceRowStateProps): React.ReactElement | null => {
  return !allowedSources.includes(row.source) || !allowedServices.includes(row.service) || !row.service ? (
    <Popover trigger='mouseenter' appendToBody={true}>
      <Template label='trigger'>
        <Icon icon={'warning' as TIcons} verticalAlign='text-bottom' color={'warning' as any} />
      </Template>
      <Template label='popover'>
        <Box
          paddingX='small'
          paddingY='xxsmall'
          background={popoverActionsProps.background}
          width={popoverActionsProps.widthWide}
        >
          <Stack space='xxxsmall'>
            <Icon icon={'warning' as TIcons} iconAlign='baseline' space='small' color={'warning' as any}>
              Unsupported or missing values.
              <br />
              Please update.
            </Icon>
          </Stack>
        </Box>
      </Template>
    </Popover>
  ) : null;
};

export default SourceServiceRowState;
