import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import {
  useDeleteMutation,
  useFindByIdQuery,
  usePublishMutation,
  useUnpublishMutation,
  useUpdateMutation,
} from 'features/featuredGroups/featuredGroupsApi';
import {featuredGroupDetailsValidator} from '../validators';
import NotAuthorized from 'components/notAuthorized';
import DeleteConfirmation from 'components/deleteConfirmation';
import CrudError from 'components/crudError';
import FeaturedGroupDetails from './details';
import {IFeaturedGroup} from 'models/featuredGroup';
import programmingRoutes from 'routes/programming.routes';
import {compact} from 'lodash-es';
import Confirmation from 'components/saveConfirmation/confirmation';
import ConfirmRouteChange from 'components/confirmRouteChange';
const FeaturedGroupEdit = (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const history = useHistory();
  const {ableTo} = useAppPermissions();

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [canSaveDataAfter, setCanSaveDataAfter] = React.useState(false);

  const {
    data: featuredGroupData,
    isError: isFeaturedGroupError,
    error: featuredGroupError,
    isLoading: isFeaturedGroupFetching,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateFeaturedGroup] = useUpdateMutation();
  const [deleteFeaturedGroup] = useDeleteMutation();
  const [publishFeaturedGroup] = usePublishMutation();
  const [unpublishFeaturedGroup] = useUnpublishMutation();

  const canView = ableTo('FEATURED_GROUPS_VIEW');
  const canEdit = ableTo('FEATURED_GROUPS_EDIT');
  const canDelete = ableTo('FEATURED_GROUPS_DELETE');

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IFeaturedGroup>([...featuredGroupDetailsValidator]);

  // Handler for featured group initial load or after save
  React.useEffect(() => {
    setModel({...featuredGroupData});
    setIsLoading(false);
  }, [featuredGroupData, setModel]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.featuredGroupListPage);
    }
  }, [isLeaving, history]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleDeleteOpen = React.useCallback(() => {
    setDeleteOpen(prev => !prev);
  }, []);

  const deleteHandler = async () => {
    try {
      await deleteFeaturedGroup({ids: id}).unwrap();

      Toast.success(`The group was deleted successfully.`);
      setTimeout(() => history.push(programmingRoutes.paths.featuredGroupListPage), 300);
    } catch (e) {
      Toast.error('Error deleting featured group. Please try again.');
    }
  };

  const onSaveData = async () => {
    setIsSaving(true);
    try {
      if (dirtyFields.published !== undefined) {
        setCanSaveDataAfter(Object.keys(dirtyFields).length > 1);
        await handleSavePublishState();
      } else if (Object.keys(dirtyFields).length > 0) {
        await saveData();
        Toast.success('Success', 'Featured Group Updated');
      }
    } catch (error: any) {
      if (error.data?.warnings) {
        setWarnings(error.data.warnings);
      } else {
        const errorMsg = error.data?.message;
        Toast.error('Error', !!errorMsg ? errorMsg : 'Failed to save featured group. Please try again', 8000);
        setWarnings([]);
        setCanSaveDataAfter(false);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const saveData = async () => {
    const transformModel: Partial<IFeaturedGroup> = {...model};

    return await updateFeaturedGroup({
      id,
      featuredGroup: transformModel,
      fields: compact(Object.keys(dirtyFields)),
    }).unwrap();
  };

  const handleSavePublishState = async (acceptWarnings = false) => {
    setIsSaving(true);
    if (model.id && model.activeRegion) {
      if (model.published) {
        await handlePublish(model.id, acceptWarnings);
      } else {
        await handleUnpublish(model.id, model.activeRegion, acceptWarnings);
      }
    }

    if (canSaveDataAfter) {
      await saveData();
      setCanSaveDataAfter(false);
    }

    if (acceptWarnings) {
      setWarnings([]);
      Toast.success('Success', 'Featured Group Updated');
      setIsSaving(false);
    }
  };

  const handlePublish = async (id: string, acceptWarnings: boolean) => {
    return await publishFeaturedGroup({id, acceptWarnings}).unwrap();
  };

  const handleUnpublish = async (id: string, activeRegion: string, acceptWarnings: boolean) => {
    return await unpublishFeaturedGroup({id, activeRegion, acceptWarnings: acceptWarnings}).unwrap();
  };

  const handleWarningsModalCancel = () => {
    setWarnings([]);
  };

  if (isFeaturedGroupError) {
    return <CrudError error={featuredGroupError} />;
  }

  if (isFeaturedGroupFetching || isLoading) {
    return (
      <Box fullHeight={true} id='loadingSpinner'>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (!canView) {
    return <NotAuthorized />;
  }

  return (
    <>
      {warnings.length > 0 && (
        <Confirmation
          isSaving={isSaving}
          onCancel={handleWarningsModalCancel}
          onConfirm={() => handleSavePublishState(true)}
          onDiscard={cancelHandler}
          warnings={warnings}
        />
      )}

      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={onSaveData}
        ignoreHashChanges={true}
        isValid={formState.isValid}
      />

      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
            <Stack space='xxsmall'>
              <Heading level='h1' truncate={true} truncateBackgroundHover='shadow' id='title'>
                {pristineModel.name}
              </Heading>
              <Cluster align='flex-start' space='medium' wrap={false} id='activeRegion'>
                <Cluster space='xxsmall' wrap={false}>
                  <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                    Active Region:
                  </Heading>
                  <Heading level='h5' color='primary'>
                    {pristineModel.activeRegion?.toUpperCase()}
                  </Heading>
                </Cluster>
              </Cluster>
            </Stack>
            <Cluster space='small' align='center' wrap={false}>
              <Popover
                manualTrigger={true}
                visible={publishOpen}
                onClickOutside={() => setPublishOpen(false)}
                allowedPlacements={['bottom', 'bottom-end']}
              >
                <Template label='trigger'>
                  <Button
                    type='solid'
                    icon='arrowdown'
                    iconPosition='right'
                    onClick={() => togglePublishOpen()}
                    permission={canEdit ? '' : 'hidden'}
                    id='status'
                  >
                    {model.published ? (
                      <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                    ) : (
                      <Status label='Unpublished' state='neutral' />
                    )}
                  </Button>
                </Template>
                <Template label='popover'>
                  <Stack space='xxxxsmall'>
                    <Status
                      id='unpublish-button'
                      label='Unpublished'
                      state='neutral'
                      onClick={() => {
                        setFields({published: false});
                        togglePublishOpen();
                      }}
                    />
                    <Status
                      id='publish-button'
                      label='Published'
                      state='success'
                      onClick={() => {
                        setFields({published: true});
                        togglePublishOpen();
                      }}
                    />
                  </Stack>
                </Template>
              </Popover>
              {!pristineModel.published && (
                <Popover
                  manualTrigger={true}
                  permission={canDelete ? '' : 'hidden'}
                  visible={deleteOpen}
                  onClickOutside={() => setDeleteOpen(false)}
                  allowedPlacements={['bottom-end']}
                  maxWidth='22rem'
                >
                  <Template label='trigger'>
                    <Button type='delete' onClick={() => toggleDeleteOpen()} id='deleteGroup'>
                      Delete
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message={`Are you sure you want to delete ${pristineModel.name}?`}
                      cancelButtonFunction={() => toggleDeleteOpen()}
                      proceedButtonFunction={() => deleteHandler()}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <FeaturedGroupDetails form={form} model={model} onBlur={onBlur} onChange={onChange} setFields={setFields} />
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard' permission={canEdit ? '' : 'hidden'}>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={onSaveData}
                  id='save'
                  permission={canEdit ? '' : 'hidden'}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};

export default FeaturedGroupEdit;
