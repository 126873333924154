import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  ContentBox,
  ContentBoxColumn,
  ContentBoxes,
  Heading,
  Help,
  Icon,
  ImageWrapper,
  Notification,
  Paragraph,
  Popover,
  Spinner,
  Status,
  Table,
  Template,
  Toast,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import {IVodCategoryTitle} from 'models/vodCategoryEntry';
import {useState} from 'react';
import {useHighlightItems} from 'views/programming/channel/edit/catalog/hooks/useHighlightItems';

export const CapstanTable = ({
  data,
  isLoading,
  isItemInCollection,
  handleAddToDraft,
  handleReplaceToDraft,
}: {
  data: IVodCategoryTitle[];
  isLoading: boolean;
  isItemInCollection: (row: any) => boolean;
  handleAddToDraft: (rows: IVodCategoryTitle[]) => void;
  handleReplaceToDraft: (rows: IVodCategoryTitle[]) => void;
}): JSX.Element => {
  const {permissions} = useAppPermissions();
  const [selectedRows, setSelectedRows] = useState<IVodCategoryTitle[]>([]);
  const [addedToCollection, setAddedToCollection] = useState<string[]>([]);
  const addReplaceButtonEnabled = selectedRows.length !== 0 && addedToCollection.length !== data.length;
  const {items: selectedItemAdded, add: setSelectedItemAdded} = useHighlightItems();

  const handleAddToCollection = () => {
    setSelectedItemAdded(selectedRows);
    setAddedToCollection([...addedToCollection, ...selectedRows.map(x => x.id)]);
    setSelectedRows([]);
    handleAddToDraft(selectedRows);
    Toast.success('Titles have been added to the collection.');
  };

  const handleReplaceCollection = () => {
    setSelectedItemAdded(selectedRows);
    setAddedToCollection([...addedToCollection, ...selectedRows.map(x => x.id)]);
    setSelectedRows([]);
    handleReplaceToDraft(selectedRows);
    Toast.success('Titles have replaced the existing collection.');
  };

  const handleItemSelection = rows => {
    // check only rows not included in addedToCollection
    const availableRows = rows.filter(row => !isAddedToCollection(row));
    setSelectedRows(availableRows);
  };

  const isAddedToCollection = row => {
    return isItemInCollection(row) || addedToCollection.some(id => id === row.id);
  };

  const handleRowStatus = item => {
    if (selectedItemAdded.includes(item.id)) return 'highlight';

    return item.state;
  };

  const showAvailWindows = (row: IVodCategoryTitle) => {
    if (row.availabilityWindows && row.availabilityWindows) {
      return row.availabilityWindows.map(a => (
        <div key={`avod-${a.id}`}>
          {new Date(a.startDate).toLocaleDateString()} - {new Date(a.endDate).toLocaleDateString()}
        </div>
      ));
    }
  };

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox>
          <Template label='header'>
            <Cluster space='medium' justify='space-between' align='center'>
              <Cluster space='medium' align='flex-end'>
                <Heading level='h3' color='secondary'>
                  Titles
                </Heading>
                <Help>{data.length} Items</Help>
              </Cluster>
              <Cluster align='center' space='medium'>
                <Button
                  permission={permissions.VOD_EDIT}
                  type='secondary'
                  onClick={handleAddToCollection}
                  state={addReplaceButtonEnabled ? 'enabled' : 'disabled'}
                >
                  + Add to Collection {selectedRows.length !== 0 && '(' + selectedRows.length + ')'}
                </Button>
                <Button
                  permission={permissions.VOD_EDIT}
                  type='secondary'
                  onClick={handleReplaceCollection}
                  state={addReplaceButtonEnabled ? 'enabled' : 'disabled'}
                >
                  Replace Collection
                </Button>
              </Cluster>
            </Cluster>
          </Template>
          <Template label='content'>
            <Table
              predicate='id'
              highlightable={true}
              highlightNewRows={data.filter(row => selectedItemAdded.includes(row.id))}
              rowStatus={handleRowStatus}
              emptyMsg='No Results'
              loading={isLoading}
              fixedHeader={true}
              wrapContent={true}
              onSelect={handleItemSelection}
              selectable='multiple'
              selected={selectedRows.filter(row => !isAddedToCollection(row))}
              id='titles-list'
              rowDisabled={row => isAddedToCollection(row)}
              rows={data}
              cols={[
                {
                  label: 'Order',
                  transform: row => row.order,
                },
                {
                  label: '',
                  colMinWidth: '3.5rem',
                  transform: row =>
                    isAddedToCollection(row) ? (
                      <Popover trigger='mouseenter' appendToBody={true}>
                        <Template label='trigger'>
                          <Icon icon='check' color='success' space='xxxsmall' />
                        </Template>
                        <Template label='popover'>
                          <Box background='cavern' padding='small'>
                            <Icon icon='check' color='success' space='xxxsmall' />
                            This title is currently in the Program tab.
                          </Box>
                        </Template>
                      </Popover>
                    ) : (
                      <></>
                    ),
                },
                {
                  label: 'Display Name',
                  transform: row => row.displayName,
                  colMinWidth: '8rem',
                },
                {
                  label: 'Series Name',
                  transform: row => (
                    <Cluster wrap={false} space='small' align='center'>
                      <ImageWrapper
                        backgroundColor='black'
                        width='2.5rem'
                        height='3.65rem'
                        src={row.thumbnail}
                        alt={row.name}
                      />
                      {row.name}
                    </Cluster>
                  ),
                  colMinWidth: '11.5rem',
                },
                {
                  label: 'Type',
                  transform: row => row.seriesType,
                },
                {
                  label: 'Author',
                  transform: row => row?.author || '',
                  colMinWidth: '8rem',
                },
                {
                  label: 'Genre',
                  colMinWidth: '7.5rem',
                  transform: row => row.genre,
                },
                {
                  label: '# of S',
                  colMinWidth: '4.7rem',
                  transform: row => row.seriesCount,
                },
                {
                  label: '# of E',
                  colMinWidth: '4.7rem',
                  transform: row => row.episodeCount,
                },
                {
                  label: 'Published',
                  colMinWidth: '9rem',
                  transform: row => (
                    <Status
                      label={row.published ? 'Published' : 'Unpublished'}
                      state={row.published ? 'success' : 'neutral'}
                    />
                  ),
                },
                {
                  label: 'Availability/Expiration',
                  colMinWidth: '12rem',
                  transform: row => showAvailWindows(row),
                },
              ]}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no content currently available.</Notification>
              </Template>
            </Table>
          </Template>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
