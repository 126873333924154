import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IListIdQuery} from 'models/generic';
import {
  IVodCategoryEntry,
  IVodCategoryBulkEntry,
  IVodCategoryEntryExpanded,
  IVodEntryOrder,
} from 'models/vodCategoryEntry';

interface IListVodEntryPayload {
  metadata: {
    offset: number;
    limit: number;
    totalCount: number;
  };
  data: {
    documents: IVodCategoryEntryExpanded[];
  };
}

interface IVodTotalDuration {
  duration: number;
}

export const vodCategoryEntriesApi = createApi({
  reducerPath: 'vodCategoryEntriesApi',
  tagTypes: ['VodCategoryEntries'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IListVodEntryPayload, IListIdQuery>({
      query: ({id, limit = 1000, offset = 0, sort = 'order:asc'}) => ({
        url: `vod-category-entries/${id}?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['VodCategoryEntries'],
    }),
    insert: builder.mutation<IVodCategoryEntry, Partial<IVodCategoryEntry>>({
      query: vodCategoryEntry => ({
        url: 'vod-category-entry',
        body: vodCategoryEntry,
        method: 'POST',
      }),
      invalidatesTags: ['VodCategoryEntries'],
    }),
    update: builder.mutation<IVodCategoryEntry, {id: string; vodCategoryEntry: Partial<IVodCategoryEntry>}>({
      query: ({vodCategoryEntry}) => ({
        url: `vod-category-entry/${vodCategoryEntry.id}`,
        body: {
          vodCategory: vodCategoryEntry,
          updateFields: ['vodCategory', 'order'],
        },
        method: 'PUT',
      }),
      invalidatesTags: ['VodCategoryEntries'],
    }),
    delete: builder.mutation<IVodCategoryEntry, Partial<IVodCategoryEntry>>({
      query: vodCategoryEntry => ({
        url: `vod-category-entry/${vodCategoryEntry.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VodCategoryEntries'],
    }),
    bulkReorder: builder.mutation<IVodCategoryEntry, {vodCategoryId: string; orderElements: IVodEntryOrder[]}>({
      query: ({vodCategoryId, orderElements}) => ({
        url: 'vod-category-entries/reorder',
        method: 'POST',
        body: {
          vodCategoryId,
          orderElements,
        },
      }),
      invalidatesTags: ['VodCategoryEntries'],
    }),
    bulkInsertEntries: builder.mutation<
      IVodCategoryBulkEntry,
      {
        vodCategoryId: string;
        episodeIds: string[];
        capstanEpisodeIds: string[];
        seriesIds: string[];
        resolveEpisodeIds?: boolean;
      }
    >({
      query: ({vodCategoryId, episodeIds, capstanEpisodeIds, seriesIds, resolveEpisodeIds}) => ({
        url: 'bulk-vod-category-entries',
        method: 'POST',
        body: {
          vodCategoryId,
          seriesIds,
          episodeIds,
          capstanEpisodeIds,
          resolveEpisodeIds,
        },
      }),
      invalidatesTags: ['VodCategoryEntries'],
    }),
    totalDuration: builder.query<IVodTotalDuration, {id: string}>({
      query: ({id}) => ({
        url: `vod-category-entries/${id}/totalDuration`,
        method: 'GET',
      }),
      providesTags: ['VodCategoryEntries'],
    }),
  }),
});

export const {
  useFindQuery,
  useLazyFindQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  useBulkReorderMutation,
  useBulkInsertEntriesMutation,
  useTotalDurationQuery,
  useLazyTotalDurationQuery,
} = vodCategoryEntriesApi;
