import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  FormItem,
  TextInput,
  IValidatorField,
  Select,
  Stack,
  useValidateForm,
} from '@pluto-tv/assemble';
import {IMainCategorySimple} from 'models/vodCollections';
import {IMainCategory} from 'models/mainCategories';
import {orderBy} from 'lodash-es';
import {popoverActionsProps} from 'helpers/popoverActionProps';

export interface IFormMainCategory {
  id: string;
  name?: string;
  catId: string;
  categoryDetails?: any;
  order: number;
}

const emptyModel = {
  id: '',
  name: '',
  catId: '',
  order: 0,
};

const mainCategoryValidator: IValidatorField<IFormMainCategory>[] = [
  {
    name: 'catId',
    label: 'Main Category',
    required: true,
    validators: [
      (catId: string): string | undefined => {
        if (!catId || !catId.trim().length) {
          return 'Category must be selected';
        }
        return undefined;
      },
    ],
  },
  {
    name: 'order',
    label: 'Order Position',
    required: false,
    validators: [
      (order: number): string | undefined => {
        if (order === 0 || order < 0) {
          return 'Order position must be greater than zero';
        }
        return undefined;
      },
    ],
  },
];

export interface IFormMainCategoryFormProps {
  isNew?: boolean;
  value?: Partial<IMainCategorySimple>;
  onCancel(): void;
  onSave(savedObj: IMainCategorySimple): void;
  categories: IMainCategory[];
  visible?: boolean;
}

const MainCategoryForm = ({
  isNew = false,
  onCancel,
  value,
  onSave,
  categories,
  visible,
}: IFormMainCategoryFormProps): JSX.Element => {
  const {
    model,
    onChange,
    setFields,
    form,
    setModel,
    state: formState,
    onBlur,
  } = useValidateForm<IFormMainCategory>(mainCategoryValidator, 'immediate');

  React.useEffect(() => {
    if (visible) {
      setModel(value || emptyModel);
    }
  }, [visible, value, setModel]);

  return (
    <Box
      padding={popoverActionsProps.padding}
      background={popoverActionsProps.background}
      width={popoverActionsProps.width}
    >
      <Stack space='small'>
        <FormItem {...form.catId} onBlur={() => onBlur('catId', false)}>
          <Select
            id='catId'
            placeholder='Category Name'
            value={{
              label: categories.find(cat => cat.id === model.catId)?.name || '',
              value: model.catId,
              details: model,
            }}
            predicate='value'
            searchable={true}
            onSearch={val =>
              orderBy(
                (categories || [])
                  .filter(mc => mc.name?.toLowerCase().startsWith(val.toLowerCase()))
                  .map(mc => ({label: mc.name!, value: mc.id, details: mc}), 'label'),
              ) || []
            }
            options={(categories || []).map(mc => ({label: mc.name!, value: mc.id, details: mc}))}
            onChange={val => {
              setFields({
                id: value?.id,
                catId: val.value,
                name: val.details.name,
                categoryDetails: val.details,
              });
            }}
          />
        </FormItem>
        <FormItem
          {...form.order}
          onBlur={() => onBlur('order')}
          helpText='No value defaults to last position'
          helpTextColor={model.order ? 'info' : 'disabled'}
        >
          <TextInput
            id='order'
            type='number'
            value={isNew && !formState.isDirty ? undefined : model.order}
            onChange={val => {
              onChange('order', val);
            }}
          />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => onSave(model as IMainCategorySimple)}
              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
            >
              {isNew ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
};

export default MainCategoryForm;
