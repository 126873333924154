import {createApi} from '@reduxjs/toolkit/query/react';
import {IListDataPayload} from 'models/generic';
import {ICarouselConfig, ICarouselConfigCreate} from 'models/carouselConfigs';
import {getStaggeredQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
export interface ICarouselConfigQuery {
  offset: number;
  limit: number;
  region?: string[] | string;
  time?: number;
}

export interface ICarouselConfigSearchQuery extends ICarouselConfigQuery {
  name: string;
}

function getParamsFromModel<T extends Partial<Record<keyof T, unknown>>>(searchModel: T) {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return [...acc, {[key]: encodeURIComponent(value)}];
    }
    return acc;
  }, [] as {[key: string]: string | number}[]);
}

export const carouselConfigsApi = createApi({
  reducerPath: 'carouselConfigsApi',
  tagTypes: ['CarouselConfigs', 'CarouselConfigsSearch', 'CarouselConfig'],
  baseQuery: getStaggeredQueryWithAuth(),
  endpoints: builder => ({
    find: builder.query<IListDataPayload<ICarouselConfig>, Partial<ICarouselConfigQuery>>({
      query: params => {
        const paramsString = getParamsFromModel<Partial<ICarouselConfigQuery>>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `carousel/config?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: IListDataPayload<ICarouselConfig>) => {
        return response.data ? response : {data: [], totalCount: 0};
      },
      providesTags: ['CarouselConfigs'],
    }),
    findById: builder.query<ICarouselConfig, string>({
      query: id => {
        return {
          url: `carousel/config/${id}`,
          method: 'GET',
        };
      },
      providesTags: (_result, _error, id) => [{type: 'CarouselConfigs', id}],
    }),
    searchByName: builder.query<IListDataPayload<ICarouselConfig>, Partial<ICarouselConfigSearchQuery>>({
      query: params => {
        const paramsString = getParamsFromModel<Partial<ICarouselConfigQuery>>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `carousel/config/byName?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: IListDataPayload<ICarouselConfig>) => {
        return response.data ? response : {data: [], totalCount: 0};
      },
      providesTags: ['CarouselConfigsSearch'],
    }),
    insert: builder.mutation<ICarouselConfig, Partial<ICarouselConfigCreate>>({
      query: carouselConfig => ({
        url: `carousel/config`,
        method: 'POST',
        body: carouselConfig,
      }),
      invalidatesTags: ['CarouselConfigs', 'CarouselConfig'],
    }),
    update: builder.mutation<ICarouselConfig, Partial<ICarouselConfigCreate>>({
      query: carouselConfig => ({
        url: `carousel/config/${carouselConfig.id}`,
        method: 'PUT',
        body: carouselConfig,
      }),
      invalidatesTags: (_result, _error, {id}) => [{type: 'CarouselConfigs', id}],
    }),
    delete: builder.mutation<ICarouselConfig, string>({
      query: (id: string) => ({
        url: `carousel/config/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        {type: 'CarouselConfig', id},
        {type: 'CarouselConfigs', id},
        {type: 'CarouselConfigsSearch', id},
      ],
    }),
  }),
});

export const {
  useFindQuery,
  useFindByIdQuery,
  useSearchByNameQuery,
  useLazySearchByNameQuery,
  useLazyFindQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} = carouselConfigsApi;
