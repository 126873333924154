import {useSearchQuery} from 'features/hubConfigs/hubConfigsApi';
import {IHubConfig} from 'models/hubConfigs';

const defaultParams = {
  offset: 0,
  limit: 1000,
};

interface IUseAssociatedHubsReturn {
  data?: IHubConfig[];
  isLoading: boolean;
  isError: boolean;
}

export const useAssociatedHubs = (carouselId: string, region?: string): IUseAssociatedHubsReturn => {
  const {data, isLoading, isError} = useSearchQuery(
    {
      ...defaultParams,
      carousel_id: carouselId,
      region: region ? [region] : undefined,
    },
    {
      skip: !carouselId,
    },
  );

  return {
    data: data?.data || ([] as IHubConfig[]),
    isLoading,
    isError,
  };
};
