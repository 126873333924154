import * as React from 'react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  DateTime,
  Dialog,
  Divider,
  FormItem,
  Heading,
  Help,
  IDateRange,
  ITableCol,
  Icon,
  Paragraph,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Table,
  TdMultilineTruncate,
  Template,
  TextInput,
  Toast,
  disableFutureDatesBehavior,
  secondsToHms,
  useCancellableSummon,
  useValidateForm,
} from '@pluto-tv/assemble';
import {isString, uniq} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import {useAppPermissions} from 'app/permissions';
import SeriesList, {ISeriesSearchParams} from 'components/seriesList';
import {DeleteActionWithoutPopover} from 'components/deleteActionWithoutPopover';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {IChannelCatalogQuery} from 'models/channelCatalog';
import {IChannelQueueQuery} from 'models/channelQueue';
import {ISeriesListResult, ISetSeriesToChannel} from 'models/series';
import {useChannelCatalogLibraryDuration} from 'views/programming/channel/edit/catalog/hooks/useChannelCatalogLibraryDuration';
import {useChannelCatalogQueueDuration} from './hooks/useChannelCatalogQueueDuration';
import {RowState} from 'components/rowState';
import {getItemState} from 'views/programming/channel/utils';
import {useTotalQueueCount} from 'views/programming/channel/edit/catalog/hooks/useTotalQueueCount';
import {useRatingsByRegion} from 'views/programming/channel/edit/catalog/hooks/useRatingsByRegion';
import {useTotalLibraryCount} from 'views/programming/channel/edit/catalog/hooks/useTotalLibraryCount';
import {useHighlightItems} from 'views/programming/channel/edit/catalog/hooks/useHighlightItems';
import {channelCatalogValidator} from '../../validators';
import {INestedChannelProps} from '../nestedPropsInterface';
import {useChannelCatalogLibrary} from './hooks/useChannelCatalogLibrary';
import {defaultParams, useChannelCatalogQueue} from './hooks/useChannelCatalogQueue';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import {useMemoryQueueProvider} from 'views/programming/channel/contexts/memoryQueueProvider';
import {withThousandsSeparator} from 'utils/thousands-separator';

const TABLE_COLUMN_NAMES = {
  Episode: 'name',
  Rating: 'rating',
  Series: 'series.name',
  S: 'season',
  E: 'number',
  Duration: 'duration',
} as const;

const TableColumnLabel = {
  name: 'Episode',
  rating: 'Rating',
  'series.name': 'Series',
  season: 'S',
  number: 'E',
  duration: 'Duration',
};

const libraryCols: ITableCol<IChannelCatalogItemWithState>[] = [
  {
    label: '',
    transform: row => <RowState row={row} />,
    colWidth: '1.25rem',
    zeroRightPadding: true,
  },
  {
    label: 'Author',
    field: 'author',
    colWidth: '5.625rem',
  },
  {
    label: 'Series',
    sortable: true,
    colWidth: '12.5rem',
    transform: row => (
      <TdMultilineTruncate
        row={row}
        truncateOnLine={4}
        target='_blank'
        text={row.series?.name || ''}
        url={`/series/${row.series?.id}/details`}
      />
    ),
  },
  {
    label: 'Episode',
    sortable: true,
    colWidth: '10rem',
    transform: row => (
      <TdMultilineTruncate
        row={row}
        truncateOnLine={4}
        target='_blank'
        text={row.name || ''}
        url={`/series/${row.series?.id}/episodes/${row.id}/details`}
      />
    ),
  },
  {
    label: 'Rating',
    sortable: true,
    field: 'rating',
  },
  {
    label: 'S',
    sortable: true,
    field: 'season',
  },
  {
    label: 'E',
    sortable: true,
    colMinWidth: '3.125rem',
    field: 'number',
  },
  {
    label: 'Duration',
    sortable: true,
    transform: row => secondsToHms(row.duration!),
  },
];

export default function CatalogPage({model, setFields}: Pick<INestedChannelProps, 'model' | 'setFields'>): JSX.Element {
  const {id: channelId} = useParams<{id: string}>();
  const {ratings: channelRatings} = useRatingsByRegion(model.activeRegion);
  const {data: genres} = useFindGenresQuery();
  const [summon] = useCancellableSummon();

  const {queueModel} = useMemoryQueueProvider();

  const [libraryRows, setLibraryRows] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [isQueueSortActive, setIsQueueSortActive] = React.useState(false);
  const [isAddingEpisodes, setIsAddingEpisodes] = React.useState(false);
  const [selectedSeriesToAdd, setSelectedSeriesToAdd] = React.useState<ISeriesListResult[]>([]);
  const [catalogSearchType, setCatalogSearchType] = React.useState<'library' | 'queue'>('library');
  const [selectedQueueRows, setSelectedQueueRows] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [selectedLibraryRows, setSelectedLibraryRows] = React.useState<IChannelCatalogItemWithState[]>([]);
  const [isAddSeriesOpen, setIsAddSeriesOpen] = React.useState(false);
  const [seriesSearchParams, setSeriesSearchParams] = React.useState<ISeriesSearchParams | undefined>({
    isSearchExpanded: true,
  } as ISeriesSearchParams);

  const searchModelRef = React.useRef<string | null>(null);
  const seriesAddedRef = React.useRef<string[]>([]);

  const {ableTo, permissions} = useAppPermissions();
  const isDragAndDropDisabled = catalogSearchType === 'queue' && isSearchActive;

  const CAN_EDIT = React.useMemo(() => ableTo('CHANNEL_CATALOG_EDIT'), [ableTo]);

  const {items: selectedSeriesAdded, add: setSelectedSeriesAdded} = useHighlightItems();
  const {items: orderedQueueItems, add: setOrderedQueueItems} = useHighlightItems();

  const {
    queueItems,
    isLazyLoading: isQueueLazyLoading,
    isLoading: isQueueLoading,
    handleLazyLoad: handleQueueLazyLoad,
    handleSorting: handleQueueSorting,
    totalItems: queueTotalItems,
    isError: isQueueError,
    fetch: fetchQueue,
    sort: queueSort,
    shuffle: shuffleQueue,
    addItems: addQueueItems,
    movedToQueue,
    moveAlong,
    removeItems: removeQueueItems,
    duration,
    movedToQueueDuringFilter,
  } = useChannelCatalogQueue(isSearchActive, model.id);

  const {
    libraryItems,
    addItems: addLibraryItems,
    removeItems: removeLibraryItems,
    isLazyLoading: isLibraryLazyLoading,
    isLoading: isLibraryLoading,
    isSuccess: isLibrarySuccess,
    handleLazyLoad,
    handleSorting: handleLibrarySorting,
    totalItems: libraryTotalItems,
    isError: isLibraryError,
    fetch: fetchLibrary,
    sort: librarySort,
    movedToLibrary,
    movedToLibraryDuringFilter,
  } = useChannelCatalogLibrary(movedToQueue, isSearchActive, model.id);

  const totalQueueCount = useTotalQueueCount({
    isSearchActive,
    isQueueFiltered: catalogSearchType === 'queue',
    totalLength: queueTotalItems,
    movedToLibrary,
    movedToQueue,
    movedToQueueDuringFilter,
    movedToLibraryDuringFilter,
  });

  const totalLibraryCount = useTotalLibraryCount({
    totalLength: libraryTotalItems,
    movedToLibrary,
    movedToQueue,
  });

  // Highlight the series that were added to the library through the add series modal
  // when the endpoint is successfully called
  React.useEffect(() => {
    if (isLibrarySuccess && seriesAddedRef.current.length) {
      setSelectedSeriesAdded(seriesAddedRef.current, false);
      seriesAddedRef.current = [];
    }
  }, [isLibrarySuccess, setSelectedSeriesAdded]);

  // These two useEffect are used to lazy load the table when all their items are moved to the library
  // and the container ends up having 0 items but there are still items in the queue endpoint.
  // in this case the Table lazyloading is not triggered, so we need to trigger it manually.
  React.useEffect(() => {
    if (
      isQueueLoading ||
      isQueueLazyLoading ||
      !movedToLibrary.length ||
      queueItems.length ||
      totalQueueCount <= queueItems.length
    )
      return;
    handleQueueLazyLoad();
  }, [handleQueueLazyLoad, isQueueLazyLoading, isQueueLoading, movedToLibrary.length, queueItems, totalQueueCount]);

  React.useEffect(() => {
    if (
      isLibraryLoading ||
      isLibraryLazyLoading ||
      !movedToQueue.length ||
      libraryItems.length ||
      totalLibraryCount <= libraryItems.length
    )
      return;
    handleLazyLoad();
  }, [
    handleLazyLoad,
    isLibraryLazyLoading,
    isLibraryLoading,
    libraryItems.length,
    movedToQueue.length,
    totalLibraryCount,
  ]);

  const {
    fetch: fetchLibraryDuration,
    duration: libraryDuration,
    isFetching: isLibraryDurationFetching,
  } = useChannelCatalogLibraryDuration(channelId, movedToLibrary, movedToQueue);

  const {duration: queueDuration} = useChannelCatalogQueueDuration(
    duration,
    movedToLibrary,
    movedToQueue,
    movedToLibraryDuringFilter,
    movedToQueueDuringFilter,
    isSearchActive,
  );

  const handleQueueItemRemove = React.useCallback(
    (row: IChannelCatalogItemWithState) => {
      removeQueueItems([row]);
      addLibraryItems([row]);
      setSelectedQueueRows(prev => prev.filter(item => item.id !== row.id));
    },
    [removeQueueItems, addLibraryItems],
  );

  const handleSelectedQueueRemove = React.useCallback(() => {
    removeQueueItems(selectedQueueRows);
    addLibraryItems(selectedQueueRows);
    setSelectedQueueRows([]);
  }, [selectedQueueRows, removeQueueItems, addLibraryItems]);

  const queueDraggable = React.useMemo(() => CAN_EDIT && !isDragAndDropDisabled, [isDragAndDropDisabled, CAN_EDIT]);

  const libraryDraggable = React.useMemo(
    () => !isLibraryError && CAN_EDIT && !isDragAndDropDisabled,
    [isDragAndDropDisabled, CAN_EDIT, isLibraryError],
  );

  const dropKeys = React.useMemo(() => ['queueTable', 'libraryTable'], []);

  const queueHighlightNewRows = React.useMemo(
    () => queueItems.filter(row => orderedQueueItems.includes(row.id)),
    [orderedQueueItems, queueItems],
  );

  const libraryHighlightNewRows = React.useMemo(
    () => libraryRows.filter(row => selectedSeriesAdded.includes(row.series.id)),
    [libraryRows, selectedSeriesAdded],
  );

  const handleSorting = React.useCallback(
    (sortField: string) => {
      setIsQueueSortActive(true);
      handleQueueSorting(sortField);
    },
    [handleQueueSorting],
  );

  const queueOnSort = React.useCallback(
    (columnLabel: string) => handleSorting(TABLE_COLUMN_NAMES[columnLabel]),
    [handleSorting],
  );

  const libraryOnSort = React.useCallback(
    (columnLabel: string) => handleLibrarySorting(TABLE_COLUMN_NAMES[columnLabel]),
    [handleLibrarySorting],
  );

  const queueCols = React.useMemo(
    () => [
      ...libraryCols,
      ...(CAN_EDIT
        ? [
            {
              label: 'Actions',
              colWidth: '4rem',
              transform: row => (
                <DeleteActionWithoutPopover
                  row={row}
                  icons={['delete']}
                  onClick={(row, icon) => {
                    switch (icon) {
                      case 'delete':
                        handleQueueItemRemove(row);
                        break;
                      default:
                    }
                  }}
                />
              ),
            } as ITableCol<IChannelCatalogItemWithState>,
          ]
        : []),
    ],
    [CAN_EDIT, handleQueueItemRemove],
  );

  const rowStatus = React.useCallback((row: any) => row.state, []);
  const rowDisabled = React.useCallback(row => !row.id, []);
  const onDropZoneClick = React.useCallback(() => setIsAddSeriesOpen(true), []);

  const queueOnSelect = React.useCallback((rows: IChannelCatalogItemWithState[]) => {
    setSelectedQueueRows(rows);
  }, []);

  const calculateRowHeight = React.useCallback((row: IChannelCatalogItemWithState): number => {
    if (row.author && row.author.length > 10) {
      return 48;
    }

    if (row.series?.name && row.series?.name.length > 27) {
      return 48;
    }

    if (row.name && row.name.length > 20) {
      return 48;
    }

    return 32;
  }, []);

  const {
    form: searchForm,
    model: searchModel,
    setFields: searchSetFields,
    reset: searchReset,
    onBlur: searchOnBlur,
    getValidation: searchGetValidation,
    onChange: searchOnChange,
  } = useValidateForm<IChannelCatalogQuery>(channelCatalogValidator, 'ask');

  React.useEffect(() => {
    if (queueModel === undefined) return;
    setFields({episodesQueue: queueModel});
  }, [queueModel, setFields]);

  React.useLayoutEffect(() => {
    if (isLibraryLoading) {
      const tableElement = document.getElementById('libraryTable');
      if (tableElement) {
        tableElement.parentElement?.scrollTo(0, 0);
      }
    }
  }, [isLibraryLoading]);

  React.useLayoutEffect(() => {
    if (isQueueLoading) {
      const tableElement = document.getElementById('queueTable');
      if (tableElement) {
        tableElement.parentElement?.scrollTo(0, 0);
      }
    }
  }, [isQueueLoading]);

  React.useLayoutEffect(() => {
    if (libraryItems.length)
      setTimeout(() => {
        const EpisodeNameInput = document.getElementById('episodeNameField');
        EpisodeNameInput?.focus({
          preventScroll: true,
        });
      });
  }, [libraryItems]);

  React.useEffect(() => {
    if (isLibraryLoading) return;
    setLibraryRows(
      libraryItems.map(item => ({
        ...item,
        ...getItemState(item),
      })),
    );
  }, [isLibraryLoading, libraryItems]);

  React.useEffect(() => {
    if (isAddSeriesOpen) {
      setTimeout(() => document.getElementById('SeriesSearchTitle')?.focus());
    }
  }, [isAddSeriesOpen]);

  React.useEffect(() => {
    if (model.autoSchedule && !model.episodesQueue?.length) {
      Toast.error(
        'One or more episodes must be available in the Channel Queue when Auto Scheduling feature is enabled.',
      );
    }
  }, [model]);

  const handleQueueShuffle = () => {
    shuffleQueue();
    fetchQueue({...defaultParams, sort: []});
  };

  const handleSearch = async () => {
    const validation = await searchGetValidation();

    if (!validation.state.isValid) {
      return;
    }

    if (catalogSearchType === 'library') {
      fetchLibraryDuration(validation.model);
      fetchLibrary(validation.model as Partial<IChannelCatalogQuery>);
      searchModelRef.current = JSON.stringify(validation.model);
    } else {
      fetchQueue(validation.model as Partial<IChannelQueueQuery>);
    }

    setIsSearchActive(true);
  };

  const handleSearchClear = () => {
    if (isQueueSortActive) {
      fetchQueue(defaultParams);
    } else if (isSearchActive) {
      if (catalogSearchType === 'library') {
        fetchLibrary();
        fetchLibraryDuration();
        searchModelRef.current = null;
      } else {
        fetchQueue(defaultParams);
      }
      searchReset();
    }
    setIsSearchActive(false);
    setIsQueueSortActive(false);
  };

  const handleAddSeries = async () => {
    if (selectedSeriesToAdd.length) {
      setIsAddingEpisodes(true);
      try {
        const result: ISetSeriesToChannel = await summon.put('/series/set-channel', {
          channel: model.id,
          series: uniq(selectedSeriesToAdd.map(r => r._id)),
        });

        if (result.FailedSeries?.length) {
          const failedSeriesNames = result.FailedSeries.map(
            serie => selectedSeriesToAdd.find(selected => selected._id === serie)?.name,
          ).join(', ');
          Toast.error('Error', `The episodes from these series could not be added. ${failedSeriesNames}`, 8000);
        } else {
          Toast.success('Success', 'The episodes were successfully added');
          fetchLibrary();
          fetchLibraryDuration();
          seriesAddedRef.current = result.SuccessSeries;
        }
      } catch (e) {
        Toast.error('Error', 'Could not add the episodes. Please try again', 8000);
      } finally {
        setIsAddSeriesOpen(false);
        setSelectedSeriesToAdd([]);
        setIsAddingEpisodes(false);
      }
    }
  };

  const handleSelectedSeries = (rows: ISeriesListResult | ISeriesListResult[]) => {
    Array.isArray(rows) ? setSelectedSeriesToAdd(rows) : setSelectedSeriesToAdd([rows]);

    setSeriesSearchParams({
      isSearchExpanded: true,
    } as ISeriesSearchParams);
  };

  const handleSelectLibraryRows = (rows: IChannelCatalogItemWithState[]) => {
    setSelectedLibraryRows(rows);
  };

  const handleSearchCatalogSwitch = value => {
    if (isSearchActive) {
      if (value.value === 'library') {
        fetchQueue();
      } else if (value.value === 'queue') {
        fetchLibrary();
        fetchLibraryDuration();
        searchModelRef.current = null;
      }
      setIsSearchActive(false);
    }
    setCatalogSearchType(value.value as 'library' | 'queue');
  };

  const cancelAddSeries = () => {
    setIsAddSeriesOpen(false);
    setSelectedSeriesToAdd([]);
    setSeriesSearchParams({
      isSearchExpanded: true,
    } as ISeriesSearchParams);
  };

  const onLibraryTableDrop = React.useCallback(
    (from: string, _to: string, _fromIndex: number[], _indexes: number, rows: IChannelCatalogItemWithState[]): void => {
      if (from === 'queueTable') {
        addLibraryItems(rows);
        removeQueueItems(rows);
        setSelectedQueueRows([]);
      }
    },
    [addLibraryItems, removeQueueItems],
  );

  const onQueueTableDrop = React.useCallback(
    (from: string, _to: string, fromIndex: number[], indexes: number, rows: IChannelCatalogItemWithState[]): void => {
      if (from === 'queueTable') {
        setOrderedQueueItems(rows, true);
        moveAlong(fromIndex, indexes);
      } else {
        removeLibraryItems(fromIndex, rows);
        addQueueItems(indexes, rows);
        setOrderedQueueItems(rows, false);
        setSelectedLibraryRows([]);
      }
    },
    [addQueueItems, removeLibraryItems, moveAlong, setOrderedQueueItems],
  );

  return (
    <ContentBoxes layout='coverGrid' coverScrolling={true} coverGridMaxCols={2} coverGridFullHeight={true}>
      <Template label='contentBoxesHeader'>
        <ContentBox title='Search Filters'>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <Stack space='xxxsmall'>
              <Cluster growNthChild={3} space='large'>
                <FormItem label='Choose Catalog' child='RadioGroup'>
                  <RadioGroup
                    layout='horizontal'
                    onChange={handleSearchCatalogSwitch}
                    value={{label: catalogSearchType === 'library' ? 'Library' : 'Queue', value: catalogSearchType}}
                    options={[
                      {label: 'Library', value: 'library'},
                      {label: 'Queue', value: 'queue'},
                    ]}
                  />
                </FormItem>
                <Divider direction='vertical' flexChild={true} color='graphite' />
                <Box width='12rem'>
                  <FormItem {...searchForm.name} onBlur={() => searchOnBlur('name')}>
                    <TextInput
                      id='episodeNameField'
                      clearable={true}
                      onChange={val => searchOnChange('name', val)}
                      value={searchModel.name}
                      placeholder='Episode Name'
                    />
                  </FormItem>
                </Box>
                <Box width='17rem'>
                  <FormItem {...searchForm.updatedAt}>
                    <DateTime
                      id='filterByDate'
                      range
                      clearable
                      onBeforeDateRender={disableFutureDatesBehavior}
                      value={
                        searchModel.updatedAt?.start
                          ? {
                              start: new Date(searchModel.updatedAt?.start || ''),
                              end: new Date(searchModel.updatedAt?.stop || ''),
                            }
                          : undefined
                      }
                      appendToBody={true}
                      onChange={value => {
                        if (!value) {
                          searchSetFields({updatedAt: undefined});
                          return;
                        }

                        const start = luxon
                          .fromJSDate((value as IDateRange).start as Date)
                          .startOf('day')
                          .toMillis();
                        const stop = luxon
                          .fromJSDate((value as IDateRange).end as Date)
                          .endOf('day')
                          .toMillis();
                        searchSetFields({
                          updatedAt: {
                            start,
                            stop,
                          },
                        });
                      }}
                      placeholder='Date Modified'
                    />
                  </FormItem>
                </Box>
                <Box width='10.3125rem'>
                  <FormItem {...searchForm.rating}>
                    <Select
                      id='rating'
                      clearable={true}
                      appendToBody={true}
                      value={{label: searchModel.rating || ''}}
                      options={[{label: 'Not Rated', value: 'Not Rated', weight: 0}, ...channelRatings].map(
                        ({value, weight}) => ({label: value, value, weight: +weight}),
                      )}
                      sortField='weight'
                      onChange={value => {
                        searchSetFields({rating: value?.label || ''});
                      }}
                      placeholder='Rating'
                    />
                  </FormItem>
                </Box>
                <Box width='15rem'>
                  <FormItem
                    {...searchForm.genre}
                    onFocus={() => {
                      const genreInput = document.getElementById('genre');
                      genreInput?.focus({
                        preventScroll: true,
                      });
                    }}
                  >
                    <Select
                      id='genre'
                      clearable={true}
                      value={{label: searchModel.genre || ''}}
                      options={(genres || []).map(({genre}) => ({label: genre}))}
                      sortField='label'
                      onChange={value => searchSetFields({genre: value?.label || ''})}
                      searchable={true}
                      searchPlaceholder='Search for Genre'
                      placeholder='Genre'
                    />
                  </FormItem>
                </Box>
                <Box width='6rem'>
                  <FormItem {...searchForm.season} onBlur={() => searchOnBlur('season')}>
                    <TextInput
                      id='seasonNumber'
                      type='number'
                      value={searchModel.season}
                      onChange={val => {
                        searchOnChange('season', isString(val) && val === '' ? (undefined as unknown as number) : val);
                      }}
                      placeholder='S #'
                    />
                  </FormItem>
                </Box>
                <Stack space='large'>
                  <Box height='0.125rem'></Box>
                  <Cluster justify='space-between' align='center'>
                    <div></div>
                    <Cluster space='small'>
                      <Button
                        id='clearSearchButton'
                        ghost={true}
                        onClick={handleSearchClear}
                        state={
                          (isSearchActive || isQueueSortActive) && !isLibraryLoading && !isQueueLoading
                            ? ''
                            : 'disabled'
                        }
                      >
                        Clear
                      </Button>
                      <Button
                        id='searchButton'
                        htmlType='submit'
                        type='primary'
                        state={
                          (catalogSearchType === 'library' && isLibraryLoading) ||
                          (catalogSearchType === 'queue' && isQueueLoading)
                            ? 'thinking'
                            : ''
                        }
                      >
                        Search
                      </Button>
                    </Cluster>
                  </Cluster>
                </Stack>
              </Cluster>
            </Stack>
          </form>
        </ContentBox>
      </Template>
      <Template label='contentBoxesCover'>
        <ContentBox overflow='auto'>
          <Template label='header'>
            <Cluster space='medium' justify='space-between' align='center'>
              <Cluster space='small' align='flex-end'>
                <Heading level='h3' color='secondary'>
                  Library
                </Heading>
                <span id='libraryContentinfo'>
                  <Paragraph color='secondary'>
                    {isLibraryLoading
                      ? 'N/A'
                      : `${withThousandsSeparator(totalLibraryCount)} ${totalLibraryCount === 1 ? 'Item' : 'Items'}`}
                  </Paragraph>
                </span>
              </Cluster>
              <Cluster align='center' space='large'>
                <Cluster align='center' space='xxsmall' id='libraryTotalContentHours'>
                  <Paragraph color='secondary'>Total Content Hours: </Paragraph>
                  <Box width='4.6875rem'>
                    {isLibraryDurationFetching ? (
                      <Spinner center={true} minHeight='0.5rem' size='medium' />
                    ) : (
                      <Paragraph color='secondary'>{secondsToHms(libraryDuration || -1)}</Paragraph>
                    )}
                  </Box>
                </Cluster>
                <Button
                  type='primary'
                  state={isSearchActive ? 'disabled' : 'enabled'}
                  permission={permissions.CHANNEL_CATALOG_EDIT}
                  onClick={() => setIsAddSeriesOpen(true)}
                >
                  + Add Content
                </Button>
              </Cluster>
            </Cluster>
            <Dialog isOpen={isAddSeriesOpen} onClose={cancelAddSeries} width='100%' height='100%'>
              <Template label='header'>
                <Heading level='h2'>Add Series</Heading>
              </Template>
              <Template label='body'>
                <SeriesList
                  actionsCol={false}
                  addNewSeries={false}
                  defaultSearchExpanded={true}
                  showFavoriteSearch={false}
                  showThumbnail={true}
                  checkboxCol='multiple'
                  inModal={true}
                  seriesSearchParams={seriesSearchParams}
                  setSeriesSearchParams={setSeriesSearchParams}
                  type='adding'
                  onSelect={rows => rows && handleSelectedSeries(rows)}
                  channelId={channelId}
                  defaultSearch={{
                    name: '',
                    model: {activeRegions: [model?.activeRegion?.toUpperCase() || ''], published: true},
                    meta: null,
                    sortCol: 'createdAt',
                    sortDir: 'dsc',
                  }}
                />
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} onClick={cancelAddSeries}>
                      Cancel
                    </Button>
                    <Button
                      type='primary'
                      onClick={handleAddSeries}
                      state={isAddingEpisodes ? 'thinking' : !selectedSeriesToAdd.length ? 'disabled' : 'normal'}
                    >
                      Add Series
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Dialog>
          </Template>
          <Template label='content'>
            <Table
              virtual={true}
              estimateItemHeight={calculateRowHeight}
              id='libraryTable'
              predicate='id'
              fixedHeader={true}
              draggable={libraryDraggable}
              dragDisabled={isDragAndDropDisabled}
              dragKey='libraryTable'
              dropKeys={dropKeys}
              onDrop={onLibraryTableDrop}
              loading={isLibraryLoading || isLibraryDurationFetching}
              selectable='multiple'
              highlightNewRows={libraryHighlightNewRows}
              onSort={libraryOnSort}
              sortDir={librarySort.split(':')[1] as 'asc' | 'dsc'}
              sortCol={TableColumnLabel[librarySort.split(':')[0]]}
              rowStatus={rowStatus}
              rowDisabled={rowDisabled}
              wrapContent={true}
              size='small'
              lazyLoading={isLibraryLazyLoading}
              onLazyLoad={handleLazyLoad}
              emptyMsg={
                !isSearchActive && isLibraryError
                  ? 'There was an error retrieving the library. Please try again later.'
                  : undefined
              }
              lazyLoadingMsg='Loading more items...'
              onDropZoneClick={onDropZoneClick}
              dropZoneMsg={
                queueItems.length ? 'Click to add content, or drag & drop from the queue.' : 'Click to add content.'
              }
              onSelect={handleSelectLibraryRows}
              selected={selectedLibraryRows}
              overflowWrap={true}
              cols={libraryCols}
              rows={libraryRows}
            ></Table>
          </Template>
        </ContentBox>
        <ContentBox overflow='auto'>
          <Template label='header'>
            <Stack space='none'>
              <Cluster space='medium' justify='space-between' align='center'>
                <Cluster space='small' align='flex-end'>
                  <Heading level='h3' color='secondary'>
                    Queue
                  </Heading>
                  <span id='queueContentinfo'>
                    <Paragraph color='secondary' size='medium'>
                      {isQueueLoading
                        ? 'N/A'
                        : `${withThousandsSeparator(totalQueueCount)} ${totalQueueCount === 1 ? 'Item' : 'Items'}`}
                    </Paragraph>
                  </span>
                </Cluster>
                <Cluster align='center' space='large'>
                  <Cluster align='center' space='xxsmall' id='queueTotalContentHours'>
                    <Paragraph color='secondary'>Total Content Hours: </Paragraph>
                    <Box width='4.4375rem'>
                      {isQueueLoading ? (
                        <Spinner center={true} minHeight='0.5rem' size='medium' />
                      ) : (
                        <Paragraph color='secondary'>{secondsToHms(queueDuration)}</Paragraph>
                      )}
                    </Box>
                  </Cluster>
                  <Icon
                    icon='shuffle'
                    onClick={handleQueueShuffle}
                    disabled={
                      !ableTo('CHANNEL_CATALOG_EDIT') ||
                      isQueueLoading ||
                      isDragAndDropDisabled ||
                      queueItems.length < 2
                    }
                  />
                  <Button
                    permission={permissions.CHANNEL_CATALOG_EDIT}
                    onClick={handleSelectedQueueRemove}
                    type='secondary'
                    state={!selectedQueueRows.length ? 'disabled' : ''}
                  >
                    Remove
                  </Button>
                </Cluster>
              </Cluster>
              {isDragAndDropDisabled && (
                <div style={{position: 'absolute'}}>
                  <Icon
                    icon='info'
                    size='medium'
                    iconAlign='center'
                    space='xxsmall'
                    color={isDragAndDropDisabled ? 'info' : 'pewter'}
                  >
                    <Help state='info'>
                      Drag & drop has been disabled until all filters and sorting have been cleared.
                    </Help>
                  </Icon>
                </div>
              )}
            </Stack>
          </Template>
          <Template label='content'>
            <Table
              virtual={true}
              estimateItemHeight={calculateRowHeight}
              id='queueTable'
              fixedHeader={true}
              draggable={queueDraggable}
              dragKey='queueTable'
              dropKeys={dropKeys}
              onDrop={onQueueTableDrop}
              dragDisabled={isDragAndDropDisabled}
              loading={!isQueueError && isQueueLoading}
              selectable='multiple'
              highlightNewRows={queueHighlightNewRows}
              onSort={queueOnSort}
              sortDir={queueSort.split(':')[1] as 'asc' | 'dsc'}
              sortCol={TableColumnLabel[queueSort.split(':')[0]]}
              rowStatus={rowStatus}
              rowDisabled={rowDisabled}
              wrapContent={true}
              size='small'
              lazyLoading={isQueueLazyLoading}
              onLazyLoad={handleQueueLazyLoad}
              emptyMsg={
                !isSearchActive && isQueueError
                  ? 'There was an error retrieving the queue. Please try again later.'
                  : undefined
              }
              onDropZoneClick={onDropZoneClick}
              lazyLoadingMsg='Loading more items...'
              dropZoneMsg={libraryItems.length ? 'Drag & drop from the library.' : 'Click to add content.'}
              lazyLoadScrollOffset={5}
              onSelect={queueOnSelect}
              predicate='id'
              selected={selectedQueueRows}
              overflowWrap={true}
              cols={queueCols}
              rows={queueItems}
            ></Table>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
}
