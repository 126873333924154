import * as React from 'react';
import {
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  Button,
  useValidateForm,
  Checkbox,
  FormItem,
  Label,
  Select,
  TagList,
  TextInput,
  Expand,
  hmsToSeconds,
  secondsToHms,
} from '@pluto-tv/assemble';
import {EpisodeFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import {IUserEpisodeSearch} from 'models/users';
import {IListEpisodeQuery} from 'models/episodes';

import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useUserRatings} from 'helpers/useUserRatings';
import {useUserRegions} from 'helpers/useUserRegions';
import {isArray, orderBy, startCase, uniq} from 'lodash-es';
import {TSortDirection} from 'models/generic';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export interface IEpisodeSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isSearchExpanded: boolean;
  inModal: boolean;
  onSinglePage: boolean;
  importing?: boolean;
  showFavoriteSearch: boolean;
  isFetching: boolean;
  search?: IUserEpisodeSearch;
  canViewOO: boolean;
  canViewPartner: boolean;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserEpisodeSearch) => void;
  onClear: () => void;
}

const episodeNameId = 'episodeName';

const EpisodeSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isSearchExpanded,
    inModal,
    onSinglePage,
    importing = false,
    showFavoriteSearch,
    isFetching,
    search,
    canViewOO,
    canViewPartner,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: IEpisodeSearchBarProps) => {
    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: searchSetModel,
      getValidation: searchGetValidation,
      form: searchForm,
      onBlur: searchOnBlur,
      reset: searchReset,
    } = useValidateForm<IListEpisodeQuery>(
      [
        {name: 'name', label: 'Episode Name'},
        {name: 'activeRegions'},
        {name: 'metadataLanguage'},
        {name: 'tags'},
        {name: 'genres'},
        {name: 'rating'},
        {name: 'ageRange'},
        {name: 'tmsID'},
        {name: 'plutoTvOO'},
        {name: 'status'},
        {name: 'published'},
        {name: 'distributeAsAVOD'},
        {name: 'distributeAsLinear'},
        {name: 'seriesID'},
        {name: 'minUpdatedAt'},
        {name: 'minDuration'},
        {name: 'maxDuration'},
        {name: 'season', label: 'Season #'},
        {name: 'availWindow', label: 'In Window'},
        {name: 'contentUUID'},
      ],
      'ask',
    );

    const [searchSelected, setSearchSelected] = React.useState<IUserEpisodeSearch | undefined>();

    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const defaultPlutoTvOOVal = React.useMemo((): boolean | undefined => {
      if (canViewOO && canViewPartner) {
        return searchModel?.plutoTvOO;
      } else if (!canViewOO) {
        return false;
      } else {
        return true;
      }
    }, [searchModel, canViewOO, canViewPartner]);

    const {ageRanges, contentRatings} = useUserRatings();

    const {activeRegions} = useUserRegions();
    const {data: genres} = useFindGenresQuery();
    const {data: languages} = useFindLanguagesQuery();

    const handleSearch = async () => {
      const validation = await searchGetValidation();

      if (!validation.state.isValid) {
        return;
      }

      onSearch({
        name: '',
        model: validation.model,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
      }
    }, [search, searchSetModel]);

    React.useEffect(() => {
      if (!isFetching && isSearchExpanded) {
        const episodeNameInput = document.getElementById(episodeNameId);
        episodeNameInput?.focus({
          preventScroll: true,
        });
      }
    }, [isSearchExpanded, isFetching]);

    const searchSelectedHandler = (search: IUserEpisodeSearch) => {
      setSearchSelected(search);
      searchSetModel(search.model);
      onSearch(search);
    };

    const clearHandler = () => {
      onClear();
      searchReset();
      setSearchSelected(undefined);
    };

    const inProgess = React.useMemo(
      () => isArray(searchModel.status) && ((searchModel.status as string[]) || []).some(s => s === 'in progress'),
      [searchModel],
    );
    const completed = React.useMemo(
      () => isArray(searchModel.status) && ((searchModel.status as string[]) || []).some(s => s === 'completed'),
      [searchModel],
    );
    const onAir = React.useMemo(
      () => isArray(searchModel.status) && ((searchModel.status as string[]) || []).some(s => s === 'on air'),
      [searchModel],
    );
    const archived = React.useMemo(
      () => isArray(searchModel.status) && ((searchModel.status as string[]) || []).some(s => s === 'archived'),
      [searchModel],
    );

    const statusSelected = (field: string, val: boolean) => {
      let existingStatus: string[] = [];

      if (isArray(searchModel.status)) {
        existingStatus = [...(searchModel.status as string[])];
      } else if (typeof searchModel.status === 'string') {
        existingStatus = [searchModel.status as string];
      }

      if (val) {
        existingStatus.push(field);
        if (field === 'archived') {
          // 'archived' option -> uncheck all others
          existingStatus = existingStatus.filter(f => f === field);
        } else {
          // options 'in progress', 'completed' or 'on air' -> unchecks 'archived'
          existingStatus = existingStatus.filter(f => f !== 'archived');
        }
      } else {
        existingStatus = existingStatus.filter(f => f !== field);
      }

      existingStatus = uniq(existingStatus);

      searchSetFields({
        status: existingStatus.length > 0 ? existingStatus : undefined,
      });
    };

    const ratingsForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegions || [];

      if (selectedRegions.length === 0) {
        return contentRatings;
      }

      const newContentRatings = contentRatings.filter(r => r.group === '' || selectedRegions.includes(r.group || ''));

      if (searchModel.rating && !newContentRatings.some(c => c.label === searchModel.rating)) {
        searchSetFields({rating: undefined});
      }

      return newContentRatings;
    }, [searchModel.activeRegions, contentRatings, searchModel.rating, searchSetFields]);

    const ageRangesForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegions || [];

      if (selectedRegions.length === 0) {
        return ageRanges;
      }

      const newAgeRanges = ageRanges.filter(a => selectedRegions.some(r => a.label.includes(r.toLocaleLowerCase())));

      if (searchModel.ageRange && !newAgeRanges.some(c => c.value === searchModel.ageRange)) {
        searchSetFields({ageRange: undefined});
      }

      return newAgeRanges;
    }, [searchModel.activeRegions, ageRanges, searchModel.ageRange, searchSetFields]);

    return (
      <Expand width='21rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box
            background='pewter'
            paddingY={inModal ? 'none' : 'medium'}
            paddingRight='medium'
            paddingLeft={inModal ? 'none' : 'medium'}
            fullHeight={true}
            marginRight={onSinglePage ? 'large' : 'none'}
            borderTop={onSinglePage ? true : false}
            borderSize='0.125rem'
            borderColor='cavern'
          >
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  {showFavoriteSearch && (
                    <EpisodeFavoriteSearch
                      searchModel={{
                        name: '',
                        model: searchModel,
                        sortCol: sortCol,
                        sortDir: sortDir,
                      }}
                      getValidation={searchGetValidation}
                      searchSelected={searchSelected}
                      onSearchSelected={searchSelectedHandler}
                      onClearSelection={clearHandler}
                    />
                  )}
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {/* Need to force form re-render on open searchBar to make autoFocus field work properly */}
                {isSearchExpanded && (
                  <form
                    id='episodeSearchBar'
                    onSubmit={ev => {
                      ev.preventDefault();
                      setTimeout(() => handleSearch());
                    }}
                  >
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      {/* negative margin here to counter act the odd space from the input on the line above. */}
                      <Box marginTop='xsmallNegative'>
                        <Stack space='small'>
                          {!importing && onSinglePage && (
                            <Stack space='small'>
                              <Label>Status</Label>
                              <Stack space='small'>
                                <Checkbox
                                  label='In Progress'
                                  value={inProgess}
                                  onChange={val => statusSelected('in progress', val)}
                                />
                                <Checkbox
                                  label='Completed'
                                  value={completed}
                                  onChange={val => statusSelected('completed', val)}
                                />
                                <Checkbox
                                  label='On Air'
                                  value={onAir}
                                  onChange={val => statusSelected('on air', val)}
                                />
                                <Checkbox
                                  label='Archived'
                                  value={archived}
                                  onChange={val => statusSelected('archived', val)}
                                />
                                <Box height='0.4rem'></Box>
                              </Stack>
                            </Stack>
                          )}
                          <Stack space='xlarge'>
                            {!importing && !onSinglePage && (
                              <Stack space='small'>
                                <Label>Scheduling Type</Label>
                                <Stack space='small'>
                                  <Checkbox
                                    label='Linear'
                                    onChange={(value: boolean) =>
                                      searchSetFields({distributeAsLinear: value ? value : undefined})
                                    }
                                    value={searchModel.distributeAsLinear}
                                  />
                                  <Checkbox
                                    label='VOD'
                                    onChange={(value: boolean) =>
                                      searchSetFields({distributeAsAVOD: value ? value : undefined})
                                    }
                                    value={searchModel.distributeAsAVOD}
                                  />
                                  <Checkbox
                                    label='Archived'
                                    onChange={(value: boolean) =>
                                      value
                                        ? searchSetFields({status: 'archived'})
                                        : searchSetFields({
                                            status: searchModel.status === 'archived' ? '' : searchModel.status,
                                          })
                                    }
                                    value={searchModel.status === 'archived'}
                                  />
                                </Stack>
                              </Stack>
                            )}
                            <FormItem
                              {...searchForm.name}
                              onBlur={() => {
                                searchOnBlur('name');
                              }}
                            >
                              <TextInput
                                id={episodeNameId}
                                placeholder='Search by Episode Name'
                                value={searchModel.name}
                                onChange={val => {
                                  searchOnChange('name', val);
                                }}
                              />
                            </FormItem>
                          </Stack>
                          {!importing && onSinglePage && (
                            <FormItem {...searchForm.season} onBlur={() => searchOnBlur('season')}>
                              <TextInput
                                value={searchModel.season}
                                onChange={val => searchOnChange('season', val)}
                                placeholder='Season Number'
                                type='number'
                                id='season'
                              />
                            </FormItem>
                          )}
                          {!importing && onSinglePage && (
                            <FormItem {...searchForm.availWindow}>
                              <Select
                                id='availWindow'
                                clearable={true}
                                predicate='value'
                                placeholder='Avail. Window'
                                value={{label: '', value: searchModel.availWindow}}
                                onChange={val => searchOnChange('availWindow', val?.value)}
                                options={[
                                  {label: 'Active AVOD avail.', value: 'AVOD'},
                                  {label: 'Active Linear avail.', value: 'linear'},
                                ]}
                              />
                            </FormItem>
                          )}
                          {!onSinglePage && (
                            <Stack space='small'>
                              {!importing && (
                                <FormItem label='Active Region'>
                                  <Select
                                    multiselect={true}
                                    placeholder='Select Active Region'
                                    clearable={true}
                                    id='targetRegion'
                                    value={searchModel.activeRegions?.map(v => ({label: v, value: v})) || []}
                                    options={activeRegions.map(ar => ({
                                      label: `${ar.name} (${ar.code})`,
                                      value: ar.code,
                                    }))}
                                    onChange={value => {
                                      if (!value) {
                                        searchSetFields({activeRegions: []});
                                      } else {
                                        searchSetFields({activeRegions: value.map(v => v.value)});
                                      }
                                    }}
                                    predicate='value'
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem label='Genre'>
                                  <Select
                                    clearable={true}
                                    placeholder='Select Genre'
                                    multiselect={true}
                                    onChange={value => {
                                      if (!value) {
                                        searchSetFields({genres: []});
                                      } else {
                                        searchSetFields({genres: value.map(v => v.label)});
                                      }
                                    }}
                                    value={searchModel.genres?.map(v => ({label: v, value: v} || []))}
                                    id='genre'
                                    options={(genres || []).map(g => ({label: g.genre}))}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <Cluster wrap={false} space='xxxsmall' align='center'>
                                  <FormItem label='Duration'>
                                    <TextInput
                                      id='durationStart'
                                      mask='NN:[0-5]N:[0-5]N'
                                      fixedPlaceholder='00:00:00'
                                      value={secondsToHms(searchModel.minDuration || 0)}
                                      onChange={value => searchSetFields({minDuration: hmsToSeconds(value)})}
                                    />
                                  </FormItem>
                                  <Heading level='h5' color='secondary'>
                                    to
                                  </Heading>
                                  <TextInput
                                    id='durationEnd'
                                    mask='NN:[0-5]N:[0-5]N'
                                    fixedPlaceholder='00:00:00'
                                    value={secondsToHms(searchModel.maxDuration || 0)}
                                    onChange={value => searchSetFields({maxDuration: hmsToSeconds(value)})}
                                  />
                                </Cluster>
                              )}
                              <FormItem label='Published'>
                                <Select
                                  id='published'
                                  clearable={true}
                                  placeholder='Published?'
                                  options={[{label: 'Yes'}, {label: 'No'}]}
                                  value={
                                    searchModel.published === undefined
                                      ? undefined
                                      : searchModel.published
                                      ? {label: 'Yes'}
                                      : {label: 'No'}
                                  }
                                  onChange={value => {
                                    if (!value) {
                                      searchSetFields({published: undefined});
                                    } else {
                                      searchSetFields({published: value.label === 'Yes'});
                                    }
                                  }}
                                />
                              </FormItem>
                              <FormItem label='Episode Status'>
                                <Select
                                  id='status'
                                  clearable={true}
                                  placeholder='Select Episode Status'
                                  options={[
                                    {label: 'In Progress'},
                                    {label: 'Completed'},
                                    {label: 'On Air'},
                                    {label: 'Archived'},
                                  ]}
                                  value={{label: startCase(searchModel.status as string)}}
                                  onChange={value => {
                                    if (!value) {
                                      searchSetFields({
                                        status: undefined,
                                      });
                                    } else {
                                      const isArchived = value.label?.toLowerCase() === 'archived';
                                      if (isArchived) {
                                        searchSetFields({status: 'archived'});
                                      } else {
                                        searchSetFields({
                                          status: value.label?.toLowerCase(),
                                        });
                                      }
                                    }
                                  }}
                                />
                              </FormItem>
                              {!importing && (
                                <FormItem label='Rating'>
                                  <Select
                                    placeholder='Select rating'
                                    clearable={true}
                                    onChange={value => {
                                      if (!value) {
                                        searchSetFields({rating: undefined});
                                      } else {
                                        searchSetFields({rating: value.label});
                                      }
                                    }}
                                    value={{label: searchModel.rating || ''}}
                                    id='rating'
                                    sortField='weight'
                                    options={ratingsForSelectedRegions}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem
                                  label='Pluto TV O&O'
                                  permission={canViewOO && canViewPartner ? 'enabled' : 'disabled'}
                                >
                                  <Select
                                    clearable={true}
                                    predicate='value'
                                    placeholder='Select Pluto TV O&O'
                                    options={[
                                      {label: 'Yes', value: true},
                                      {label: 'No', value: false},
                                    ]}
                                    value={{label: '', value: defaultPlutoTvOOVal}}
                                    onChange={value => searchSetFields({plutoTvOO: value?.value})}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem label='Language'>
                                  <Select
                                    placeholder='Select Language'
                                    multiselect={true}
                                    onChange={value => {
                                      if (!value) {
                                        searchSetFields({metadataLanguage: []});
                                      } else {
                                        searchSetFields({metadataLanguage: value?.map(v => v.value)});
                                      }
                                    }}
                                    value={searchModel.metadataLanguage?.map(mdl => ({
                                      label: mdl || '',
                                      value: mdl,
                                    }))}
                                    id='metadataLanguage'
                                    predicate='value'
                                    clearable={true}
                                    searchable={true}
                                    searchPlaceholder='Search for language'
                                    onSearch={val =>
                                      orderBy(
                                        (languages || [])
                                          .filter(
                                            language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1,
                                          )
                                          .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                                      ) || []
                                    }
                                    options={orderBy(
                                      (languages || []).map(language => ({
                                        label: language.name,
                                        value: language.iso639_1,
                                      })),
                                      'label',
                                    )}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem label='Tags'>
                                  <TagList
                                    placeholder='Search by Tags'
                                    onChange={value => searchSetFields({tags: value})}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem label='Age Range'>
                                  <Select
                                    clearable={true}
                                    placeholder='Select Age Range'
                                    onChange={value => {
                                      if (!value) {
                                        searchSetFields({ageRange: undefined});
                                      } else {
                                        searchSetFields({ageRange: value.value});
                                      }
                                    }}
                                    predicate='value'
                                    value={{value: searchModel.ageRange, label: searchModel.ageRange || ''}}
                                    id='genre'
                                    appendToBody={true}
                                    options={ageRangesForSelectedRegions}
                                  />
                                </FormItem>
                              )}
                              {!importing && (
                                <FormItem label='TMS ID'>
                                  <TextInput
                                    placeholder='Search by TMS ID'
                                    clearable={true}
                                    value={searchModel.tmsID}
                                    onChange={value => {
                                      searchSetFields({tmsID: value});
                                    }}
                                  />
                                </FormItem>
                              )}
                              <FormItem label='Content UUID'>
                                <TextInput
                                  id='contentUUID'
                                  placeholder='Search by Content UUID'
                                  clearable={true}
                                  value={searchModel.contentUUID}
                                  onChange={value => {
                                    searchSetFields({contentUUID: value});
                                  }}
                                />
                              </FormItem>
                            </Stack>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button
                      type='primary'
                      state={isFetching ? 'thinking' : ''}
                      onClick={handleSearch}
                      id='searchButton'
                    >
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

EpisodeSearchBar.displayName = 'EpisodeSearchBar';
export default EpisodeSearchBar;
