import {createApi} from '@reduxjs/toolkit/query/react';
import {IVodCollection, IVodOrder} from 'models/vodCollections';

import {IListPayload} from 'models/generic';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';

export interface IVodCollectionQuery {
  limit?: number;
  kidsMode?: boolean;
  activeRegions?: string[];
  devicesIncluded?: string[];
  devicesExcluded?: string[];
  name?: string;
  archived?: boolean;
  enabled?: boolean;
  unpublished?: boolean;
  offset?: number;
  sort?: string;
  sortDirection?: number;
  publishedVod?: boolean;
}

export const vodCollectionsApi = createApi({
  reducerPath: 'vodCollectionsApi',
  tagTypes: ['VodCollections', 'VodCollectionsSearch', 'VodCollection'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    search: builder.query<IListPayload<IVodCollection>, IVodCollectionQuery>({
      query: ({offset = 0, limit = 10, sort = 'number:asc'}) => ({
        url: `vodCollections?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['VodCollectionsSearch'],
    }),

    find: builder.query<IListPayload<IVodCollection>, IVodCollectionQuery>({
      query: (params: IVodCollectionQuery) => {
        const search = new URLSearchParams();

        const queryParams = {
          sort: params.sort ?? 'name:asc',
          offset: params.offset ?? 0,
          limit: params.limit ?? 25,
          activeRegion: params.activeRegions,
          deviceExcluded: params.devicesExcluded,
          deviceIncluded: params.devicesIncluded,
          name: params.name,
          archived: params.archived !== undefined ? params.archived : undefined,
          enabled:
            params.enabled && params.unpublished
              ? undefined
              : params.enabled
              ? true
              : params.unpublished
              ? false
              : undefined,
        };

        Object.entries(queryParams).forEach(([key, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            value.forEach(v => search.append(key, `${v}`));
          } else if (value != null && value !== '' && !Array.isArray(value)) {
            search.set(key, `${value}`);
          }
        });

        return {
          url: `vod?${search}`,
          method: 'GET',
        };
      },
      providesTags: ['VodCollections'],
    }),

    findById: builder.query<IVodCollection, string>({
      query: (id: string) => ({
        url: `vod/${id}`,
        method: 'GET',
      }),
      providesTags: ['VodCollections'],
    }),

    insert: builder.mutation<IVodCollection, Partial<IVodCollection>>({
      query: vodCollection => ({
        url: `vod`,
        method: 'POST',
        body: vodCollection,
      }),
      invalidatesTags: ['VodCollections', 'VodCollectionsSearch'],
    }),

    update: builder.mutation<IVodCollection, {id: string; vodCollection: Partial<IVodCollection>; fields: string[]}>({
      query: ({id, vodCollection, fields}) => ({
        url: `vod/${id}`,
        method: 'PUT',
        body: {
          id,
          vodParams: vodCollection,
          updateFields: fields,
        },
      }),
      invalidatesTags: ['VodCollection', 'VodCollectionsSearch', 'VodCollections'],
    }),

    bulkUpdate: builder.mutation<
      IVodCollection,
      {devices: string[]; includeGroup: string[]; excludeGroup: string[]; removeGroup: string[]}
    >({
      query: ({devices, includeGroup, excludeGroup, removeGroup}) => ({
        url: `vod/bulk-update-vod`,
        method: 'POST',
        body: {
          devices,
          includeGroup,
          excludeGroup,
          removeGroup,
        },
      }),
      invalidatesTags: ['VodCollection', 'VodCollectionsSearch', 'VodCollections'],
    }),
    bulkReorder: builder.mutation<IVodCollection, {orderElements: IVodOrder[]}>({
      query: ({orderElements}) => ({
        url: `vod/reorder`,
        method: 'POST',
        body: {
          orderElements,
        },
      }),
      invalidatesTags: ['VodCollection', 'VodCollectionsSearch', 'VodCollections'],
    }),
    bulkDelete: builder.mutation<void, {vodCategoryId: string; seriesIds: string[]; episodeIds: string[]}>({
      query: ({vodCategoryId, seriesIds, episodeIds}) => ({
        url: `vod/bulk-delete/${vodCategoryId}`,
        method: 'PUT',
        body: {
          vodCategoryId,
          seriesIds,
          episodeIds,
        },
      }),
      invalidatesTags: ['VodCollections', 'VodCollectionsSearch'],
    }),
    delete: builder.mutation<IVodCollection, string>({
      query: (id: string) => ({
        url: `vodCollection/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VodCollections', 'VodCollectionsSearch'],
    }),
  }),
});

export const {
  useLazyFindQuery,
  useFindQuery,
  useSearchQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useBulkUpdateMutation,
  useBulkReorderMutation,
  useBulkDeleteMutation,
  useDeleteMutation,
} = vodCollectionsApi;
