import * as React from 'react';
// import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  FormItem,
  Heading,
  IValidatorField,
  Select,
  Stack,
  Textarea,
  // TextInput,
  // summon,
  useValidateForm,
} from '@pluto-tv/assemble';
import {IChannel} from 'models/channels';
import {popoverActionsProps} from 'helpers/popoverActionProps';
// import {requiredField} from 'helpers/fieldValidators';
// import {useFindByIdQuery} from 'features/channels/channelsApi';

// const emptyModel = {
//   partnerGrid: '',
//   type: '',
//   strategy: '',
//   notes: '',
// };

const channelEditValidator: IValidatorField<IChannel>[] = [
  {
    name: 'partnerGrid',
    label: 'Partner Grid',
  },
  {
    name: 'type',
    label: 'Type Enabled',
  },
  {
    name: 'strategy',
    label: 'Strategy',
  },
  {
    name: 'notes',
    label: 'Notes',
    validators: [
      val => {
        if (val?.length > 1000) {
          return 'Notes cannot have more than 1000 characters.';
        } else {
          return '';
        }
      },
    ],
  },
];

export interface IChannelEditFormProps {
  value?: Partial<IChannel>;
  onCancel(): void;
  onSave(savedObj: IChannel): void;
  visible?: boolean;
  title?: string;
}

const ChannelEditForm = ({onCancel, value, onSave, visible, title}: IChannelEditFormProps): JSX.Element => {
  const {
    model,
    onChange,
    form,
    setModel,
    setFields,
    state: formState,
    onBlur,
  } = useValidateForm<IChannel>(channelEditValidator, 'immediate');

  // const {id} = useParams<{id: string}>();
  // const {data: channel} = useFindByIdQuery(id);

  React.useEffect(() => {
    if (visible && value) {
      setModel(value);
    }
  }, [setModel, visible, value]);

  return (
    <Box
      padding={popoverActionsProps.padding}
      background={popoverActionsProps.background}
      width={popoverActionsProps.width}
    >
      <Stack space='large'>
        {title && <Heading level='h3'>{title}</Heading>}
        <Stack space='small'>
          <FormItem {...form.partnerGrid} onBlur={() => onBlur('partnerGrid', false)}>
            <Select
              value={{label: '', value: model.partnerGrid}}
              predicate='value'
              clearable={true}
              options={[
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ]}
              onChange={val => {
                setFields({
                  partnerGrid: val?.value || false,
                });
              }}
            />
          </FormItem>
          <FormItem {...form.type} onBlur={() => onBlur('type', false)}>
            <Select
              value={{label: '', value: model.type}}
              predicate='value'
              clearable={true}
              options={[
                {label: 'Branded', value: 'Branded'},
                {label: 'Franchise', value: 'Franchise'},
                {label: 'O&O', value: 'O&O'},
                {label: 'Single Series', value: 'Single Series'},
              ]}
              onChange={val => {
                setFields({
                  type: val?.value || undefined,
                });
              }}
            />
          </FormItem>
          <FormItem {...form.strategy} onBlur={() => onBlur('strategy', false)}>
            <Select
              value={{label: '', value: model.strategy}}
              predicate='value'
              clearable={true}
              options={[
                {label: 'Español', value: 'Espanol'},
                {label: 'Exposure', value: 'Exposure'},
                {label: 'License Fee', value: 'License Fee'},
                {label: 'Stunt', value: 'Stunt'},
              ]}
              onChange={val => {
                setFields({
                  strategy: val?.value || undefined,
                });
              }}
            />
          </FormItem>
          <FormItem {...form.notes} onBlur={() => onBlur('notes', false)}>
            <Textarea
              id='notes'
              onChange={value => onChange('notes', value)}
              value={model.notes}
              minHeight='6.25rem'
              placeholder='Add Additional Notes'
            />
          </FormItem>
        </Stack>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => onSave(model as IChannel)}
              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
            >
              Update
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
};

export default ChannelEditForm;
