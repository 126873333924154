import * as React from 'react';
import CarouselList from 'components/carouselList/CarouselList';
import {ISearchData} from 'components/hooks/hub/useSearchHubData';
import {CarouselLazyLoadProvider} from 'components/carouselList/providers/CarouselLazyLoadProvider';
import {useLazyFindQuery, useLazySearchByNameQuery} from 'features/carouselConfigs/carouselConfigsApi';
import {ICarouselConfig, ICarouselConfigSearch} from 'models/carouselConfigs';
import {CarouselSearchLazyLoadProvider} from 'components/carouselList/providers/CarouselSearchLazyLoadProvider';
import useSearchCarouselData from 'components/hooks/carousels/useServiceSearchCarousel';

export interface ICarouselConfigListProps {
  actionsCol?: boolean;
  addNewCarouselConfig?: boolean;
  checkboxCol?: boolean | 'multiple';
  inModal?: boolean;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  onSelect?: () => void;
  showFavoriteSearch?: boolean;
  isSearchExpanded?: boolean;
}

export default (props: ICarouselConfigListProps): React.ReactElement => {
  return (
    <CarouselLazyLoadProvider
      useProvider={() =>
        useSearchCarouselData<ISearchData<ICarouselConfig>, ICarouselConfigSearch>(useLazyFindQuery as any)
      }
    >
      <CarouselSearchLazyLoadProvider
        useProvider={() =>
          useSearchCarouselData<ISearchData<ICarouselConfig>, ICarouselConfigSearch>(useLazySearchByNameQuery as any)
        }
      >
        <CarouselList {...props} />
      </CarouselSearchLazyLoadProvider>
    </CarouselLazyLoadProvider>
  );
};
