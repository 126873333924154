import * as React from 'react';
import {Box, Button, Cluster, FormItem, IValidatorField, Select, Stack, useValidateForm} from '@pluto-tv/assemble';
import {camelCase} from 'lodash-es';

import {IPartner} from 'models/partners';
import {requiredField} from 'helpers/fieldValidators';
import {popoverActionsProps} from 'helpers/popoverActionProps';

const customReferenceTypeName = {
  sponsorshipPartner: 'Sponsorship Partner',
  entitlementPartner: 'Entitlement Partner',
  deliveryPartner: 'Delivery Partner',
};

const CUSTOM_REFERENCE_TYPES = [...Object.values(customReferenceTypeName)] as const;

const emptyModel = {
  type: '',
  value: '',
};

export interface IFormCustomReference {
  id: string;
  type: string;
  value: string;
}

const customReferenceValidator: IValidatorField<IFormCustomReference>[] = [
  {
    name: 'type',
    label: 'Custom Reference',
  },
  {
    name: 'value',
    label: 'Partner',
  },
].map(requiredField);

export interface IFormCustomReferenceFormProps {
  isNew?: boolean;
  value?: Partial<IFormCustomReference>;
  onCancel(): void;
  onSave(savedObj: IFormCustomReference): void;
  partners: IPartner[];
  visible?: boolean;
}

const CustomReferenceForm = ({
  isNew = false,
  onCancel,
  value,
  onSave,
  partners,
  visible,
}: IFormCustomReferenceFormProps): JSX.Element => {
  const {
    model,
    setFields,
    form,
    setModel,
    state: formState,
    onBlur,
  } = useValidateForm<IFormCustomReference>(customReferenceValidator, 'immediate');

  React.useEffect(() => {
    if (visible) {
      setModel(value || emptyModel);
    }
  }, [visible, value, setModel]);

  return (
    <Box
      padding={popoverActionsProps.padding}
      background={popoverActionsProps.background}
      width={popoverActionsProps.width}
      id='customReferenceForm'
    >
      <Stack space='small'>
        <FormItem {...form.type} onBlur={() => onBlur('type', false)}>
          <Select
            id='customReferenceType'
            placeholder='Select a Custom Reference'
            predicate='value'
            value={{
              label: customReferenceTypeName[model.type as keyof typeof customReferenceTypeName],
              value: model.type,
            }}
            options={CUSTOM_REFERENCE_TYPES.map(type => ({
              label: type,
              value: camelCase(type),
            }))}
            onChange={val =>
              setFields({
                type: val.value,
              })
            }
          />
        </FormItem>
        <FormItem {...form.value} onBlur={() => onBlur('value', false)}>
          <Select
            id='partner'
            placeholder='Select a Partner'
            value={{label: partners.find(partner => partner.id === model.value)?.name || '', value: model.value}}
            predicate='value'
            searchable={true}
            onSearch={val =>
              (partners || [])
                .filter(partner => partner.name.toLowerCase().includes(val.toLowerCase()))
                .map(partner => ({label: partner.name, value: partner.id})) || []
            }
            options={(partners || []).map(partner => ({label: partner.name, value: partner.id}))}
            onChange={val =>
              setFields({
                value: val.value,
              })
            }
          />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id='addUpdateCustomReference'
              type='primary'
              onClick={() => onSave(model as IFormCustomReference)}
              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
            >
              {isNew ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
};

export default CustomReferenceForm;
