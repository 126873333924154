import {createApi} from '@reduxjs/toolkit/query/react';
import {getStaggeredQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {IListDataPayload} from 'models/generic';
import {CarouselBuilderHeaders} from 'views/programming/hubManager/hooks/constants';
import {IAutomatedCarouselQuery} from 'views/programming/hubManager/hooks/interfaces';

export const mlCarouselsApi = createApi({
  reducerPath: 'mlCarouselsApi',
  tagTypes: ['mlCarouselContent'],
  baseQuery: getStaggeredQueryWithAuth(1),
  endpoints: builder => ({
    find: builder.query<IListDataPayload<any>, {token: string} & IAutomatedCarouselQuery>({
      query: params => {
        const queryParams: any = {...params};

        delete queryParams['token'];
        delete queryParams['activeRegion'];

        const paramsString = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `/ml/carousel?${searchParams}`,
          method: 'GET',
          headers: {
            [CarouselBuilderHeaders.Authentication]: params.token,
          },
        };
      },
      providesTags: ['mlCarouselContent'],
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {useFindQuery, useLazyFindQuery} = mlCarouselsApi;
