import * as React from 'react';
import {Cluster, Icon, Popover, Template, TIcons} from '@pluto-tv/assemble';

import DeleteConfirmation from 'components/deleteConfirmation';
import ActionConfirmation from 'components/actionConfirmation';

export interface ITableActions<T> {
  icons: TIcons[];
  children?: React.ReactNode;
  displayField?: string;
  deleteOption?: boolean;
  removeOption?: boolean;
  duplicateOption?: boolean;
  archiveOption?: boolean;
  unarchiveOption?: boolean;
  row: T;
  altTitle?: string;
  onClick(row: T, icon: TIcons): void;
}

const TableActionsFn = <T,>({
  children,
  deleteOption,
  removeOption,
  duplicateOption,
  archiveOption,
  unarchiveOption,
  icons,
  onClick,
  displayField = 'name',
  row,
  altTitle,
}: ITableActions<T>) => {
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [duplicateVisible, setDuplicateVisible] = React.useState(false);
  const [archiveVisible, setArchiveVisible] = React.useState(false);
  const [unarchiveVisible, setUnarchiveVisible] = React.useState(false);

  return (
    <Cluster wrap={false}>
      {React.Children.map(children, child => child)}
      {icons.map(icon => (
        <Icon id={`${icon}Action`} key={icon} icon={icon} onClick={() => onClick(row, icon)} space='small' />
      ))}
      {duplicateOption && (
        <Popover
          appendToBody={true}
          manualTrigger={true}
          visible={duplicateVisible}
          onClickOutside={() => setDuplicateVisible(false)}
          maxWidth='22rem'
          allowedPlacements={['top-start', 'top-end', 'bottom-end', 'bottom-start']}
        >
          <Template label='trigger'>
            <Icon
              id='duplicateAction'
              icon='copy'
              onClick={() => setDuplicateVisible(true)}
              space={deleteOption ? 'small' : 'none'}
            />
          </Template>
          <Template label='popover'>
            <ActionConfirmation
              message={
                !altTitle
                  ? 'Are you sure you want to duplicate ' + row[displayField] + '?'
                  : 'Are you sure you want to duplicate this ' + altTitle + '?'
              }
              cancelButtonFunction={() => setDuplicateVisible(false)}
              proceedButtonFunction={() => {
                setDuplicateVisible(false);
                onClick(row, 'copy');
              }}
            />
          </Template>
        </Popover>
      )}
      {archiveOption && (
        <Popover
          appendToBody={true}
          manualTrigger={true}
          visible={archiveVisible}
          onClickOutside={() => setArchiveVisible(false)}
          maxWidth='22rem'
          allowedPlacements={['top-start', 'top-end', 'bottom-end', 'bottom-start']}
        >
          <Template label='trigger'>
            <Icon
              id='archiveAction'
              icon='archive'
              onClick={() => setArchiveVisible(true)}
              space={deleteOption ? 'small' : 'none'}
            />
          </Template>
          <Template label='popover'>
            <ActionConfirmation
              message={
                !altTitle
                  ? 'Are you sure you want to archive ' + row[displayField] + '?'
                  : `Are you sure you want to archive ${row[displayField]} ? ${
                      row['published'] || row['enabled'] ? `This action will also unpublish the collection.` : ''
                    }`
              }
              cancelButtonFunction={() => setArchiveVisible(false)}
              proceedButtonFunction={() => {
                setArchiveVisible(false);
                onClick(row, 'archive');
              }}
            />
          </Template>
        </Popover>
      )}
      {unarchiveOption && (
        <Popover
          appendToBody={true}
          manualTrigger={true}
          visible={unarchiveVisible}
          onClickOutside={() => setUnarchiveVisible(false)}
          maxWidth='22rem'
          allowedPlacements={['top-start', 'top-end', 'bottom-end', 'bottom-start']}
        >
          <Template label='trigger'>
            <Icon
              id='unarchiveAction'
              icon='unarchive'
              onClick={() => setUnarchiveVisible(true)}
              space={deleteOption ? 'small' : 'none'}
            />
          </Template>
          <Template label='popover'>
            <ActionConfirmation
              message={
                !altTitle
                  ? 'Are you sure you want to unarchive ' + row[displayField] + '?'
                  : 'Are you sure you want to unarchive this ' + altTitle + '?'
              }
              cancelButtonFunction={() => setUnarchiveVisible(false)}
              proceedButtonFunction={() => {
                setUnarchiveVisible(false);
                onClick(row, 'unarchive');
              }}
            />
          </Template>
        </Popover>
      )}
      {(deleteOption || removeOption) && (
        <Popover
          appendToBody={true}
          manualTrigger={true}
          visible={deleteVisible}
          onClickOutside={() => setDeleteVisible(false)}
          maxWidth='22rem'
          allowedPlacements={['top-start', 'top-end', 'bottom-end', 'bottom-start']}
        >
          <Template label='trigger'>
            <Icon
              id='deleteAction'
              color='delete'
              icon='delete'
              hoverColor='delete'
              onClick={() => setDeleteVisible(true)}
            />
          </Template>
          <Template label='popover'>
            <DeleteConfirmation
              message={
                !altTitle
                  ? 'Are you sure you want to ' + `${deleteOption ? 'delete ' : 'remove '}` + row[displayField] + '?'
                  : 'Are you sure you want to ' + `${deleteOption ? 'delete' : 'remove'}` + ' this ' + altTitle + '?'
              }
              cancelButtonFunction={() => setDeleteVisible(false)}
              proceedButtonFunction={() => {
                setDeleteVisible(false);
                onClick(row, 'delete');
              }}
            />
          </Template>
        </Popover>
      )}
    </Cluster>
  );
};

TableActionsFn.displayName = 'TableActions';

export const TableActions = React.memo(TableActionsFn) as typeof TableActionsFn;
