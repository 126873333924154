import * as React from 'react';
import {uniqBy, compact, startCase, cloneDeep, orderBy, some} from 'lodash-es';
import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Copy,
  FormItem,
  Heading,
  Paragraph,
  Popover,
  Select,
  Stack,
  Table,
  TagList,
  TdLink,
  TIcons,
  Template,
  Textarea,
  Notification,
  Spinner,
  ITableCol,
  Icon,
} from '@pluto-tv/assemble';

import {TableActions} from 'components/tableActions';
import {useAppPermissions} from 'app/permissions';
import {msToTimecode, timecodeToMs} from 'helpers/msToTimecode';
import {IPopover} from 'helpers/popoverInterface';
import {IClipTranscode, ISource, ISubClip} from 'models/clips';
import {useFindSubclipsByIdQuery} from 'features/clips/clipsApi';

import {INestedClipId, INestedClipProps} from '../nestedPropsInterface';
import ClipSourceForm from '../../components/clip-source-form';

const FRAME_RATES = [23.976, 23.98, 24, 25, 29.97, 30, 50, 59.94, 60];

const timecodeRegex = /[^0-9;:]/g;

interface IClipMediaProps extends INestedClipProps, INestedClipId {}

export default ({
  model,
  // pristineModel,
  setFields,
  onBlur,
  onChange,
  form,
  clipId,
}: IClipMediaProps): JSX.Element => {
  const {data: fragment, isFetching, isError} = useFindSubclipsByIdQuery(clipId);

  const {ableTo, permissions} = useAppPermissions();

  const [breakpointErrMsg, setBreakpointErrMsg] = React.useState<string>();
  const [sourcePopoverOpen, setSourcePopoverOpen] = React.useState<IPopover>({});
  const [subClips, setSubClips] = React.useState<ISubClip[]>();
  const [transcodes, setTranscodes] = React.useState<IClipTranscode[]>();

  const [showPolicy, setShowPolicy] = React.useState(false);

  React.useEffect(() => {
    if (isError) {
      setSubClips([]);
    }
  }, [isError]);

  React.useEffect(() => {
    if (fragment) {
      setSubClips(
        orderBy(
          fragment.map(subclip => ({
            ...subclip,
            startAt: parseFloat(subclip.startAt.toString()),
          })),
          'startAt',
          'asc',
        ),
      );
    }
  }, [fragment]);

  React.useEffect(() => {
    if (model && model.transcodes) {
      setTranscodes(
        model.transcodes.sort((a, b) => new Date(b.finishedAt).valueOf() - new Date(a.finishedAt).valueOf()),
      );
    }

    setShowPolicy(some(model.sources, source => source.adSparxAdPolicy));
  }, [model]);

  const handleSourceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.sources);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        sources: cloned,
      });
    } else if (icon === 'edit') {
      setSourcePopoverOpen({[index]: true});
    }
  };

  const handleSourcesUpdate = (source: ISource, index = -1) => {
    const cloned = cloneDeep(model.sources || []);

    if (index >= 0) {
      cloned.splice(index, 1, source);
    } else {
      cloned.push(source);
    }

    setFields({
      sources: cloned,
    });

    setSourcePopoverOpen({});
  };

  const parseBreakpoints = (breakpoints: string[]) => {
    setBreakpointErrMsg(undefined);

    const res = uniqBy(
      compact(
        breakpoints.map(bp => {
          if (!bp || !bp.toString().trim().length || bp.toString().trim().match(timecodeRegex)) {
            setBreakpointErrMsg('Breakpoint is invalid');
            return;
          }

          let breakpointMs = 0;

          if (bp.toString().indexOf(':') > -1 || bp.toString().indexOf(';') > -1) {
            breakpointMs = timecodeToMs(bp, model.framerate);
          }

          if (breakpointMs <= 0) {
            breakpointMs = parseInt(bp, 10);
          }

          // Don't add breakpoint if it's longer than the source
          if (breakpointMs > (model.duration || 0) * 1000) {
            setBreakpointErrMsg('Breakpoint is outside of clip duration');
            return;
          }

          return breakpointMs;
        }),
      ),
      a => msToTimecode(a, model.framerate),
    );

    onChange('breakpoints', res);
  };

  if (isFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Media Information'>
          <Stack space='medium'>
            <FormItem {...form.origin} permission={permissions.CLIP_EDIT}>
              <Textarea
                onChange={value => onChange('origin', {...model.origin, url: value})}
                value={model.origin?.url}
                id='originUrl'
              />
            </FormItem>
            <FormItem {...form.url} onBlur={() => onBlur('url')} permission={permissions.CLIP_EDIT}>
              <Textarea onChange={value => onChange('url', value)} value={model.url} id='url' />
            </FormItem>
            <FormItem {...form.framerate} permission={permissions.CLIP_EDIT}>
              <Select
                onChange={value => setFields({framerate: value.value})}
                value={{label: model.framerate?.toString() || '', value: model.framerate}}
                id='framerate'
                predicate='value'
                options={FRAME_RATES.map(r => ({label: r.toString(), value: r}))}
              />
            </FormItem>
            <FormItem {...form.color} permission={permissions.CLIP_EDIT}>
              <Select
                onChange={value => setFields({color: value.value})}
                value={{label: model.color || '', value: model.color || 'c'}}
                id='color'
                predicate='value'
                options={[
                  {label: 'C - Color', value: 'c'},
                  {label: 'B/W - Black and White', value: 'b/w'},
                ]}
              />
            </FormItem>
            <Cluster space='small'>
              <Paragraph color='secondary'>Origin Type:</Paragraph>
              <Heading level='h4'>{model.origin?.type || 'MP4'}</Heading>
            </Cluster>
            <Cluster space='small'>
              <Paragraph color='secondary'>Provider:</Paragraph>
              <Heading level='h4'>{model.provider}</Heading>
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Breakpoints'>
          <FormItem
            {...form.breakpoints}
            permission={permissions.CLIP_EDIT}
            helpText={form?.breakpoints?.helpText ? form?.breakpoints?.helpText : breakpointErrMsg}
            helpTextColor={breakpointErrMsg ? 'error' : undefined}
          >
            <TagList
              onChange={parseBreakpoints}
              value={(model.breakpoints || []).map(bp => msToTimecode(bp, model.framerate))}
              id='breakpoints'
            />
          </FormItem>
        </ContentBox>
        <ContentBox title='Content Markups'>
          <Table
            flushTop={true}
            maxHeight='18.75rem'
            emptyMsg='No content markups on this clip'
            cols={[
              {
                label: 'Type',
                transform: row => startCase(row.type),
              },
              {
                label: 'Start Time',
                transform: row => msToTimecode(row.start || 0, model.framerate),
                colWidth: '9.375rem',
              },
              {
                label: 'End Time',
                transform: row => msToTimecode(row.end || 0, model.framerate),
                colWidth: '9.375rem',
              },
            ]}
            rows={model.markups}
          ></Table>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Transcode'>
          <Table
            maxHeight='18.75rem'
            flushTop={true}
            id='transcodeTable'
            emptyMsg='No transcodes on this clip'
            cols={[
              {
                label: 'Mode',
                field: 'transcodeMode',
                verticalAlign: 'top',
              },
              {
                label: 'Transcode Dates',
                colWidth: '9.375rem',
                transform: row => (
                  <Stack>
                    {row.output.map((output, i) => (
                      <TdLink
                        key={`${output._id}${i}`}
                        title={new Date(row.finishedAt).toLocaleString()}
                        row={output}
                        target='_blank'
                        url={'https://admin.plutotv.hybrik.com/wf/main#/jobs/completed?jid=' + row.externalID}
                      />
                    ))}
                  </Stack>
                ),
              },
              {
                label: 'Output',
                colWidth: '9.375rem',
                transform: row => (
                  <Stack>
                    {row.output.map((output, i) => (
                      <TdLink key={`${output._id}${i}`} title={output.type} row={output} url={output.url} />
                    ))}
                  </Stack>
                ),
              },
            ]}
            rows={transcodes}
          ></Table>
        </ContentBox>
        <ContentBox title='Subclips'>
          <Stack space='xlarge'>
            <Table
              maxHeight='20.625rem'
              flushTop={true}
              id='subclipsTable'
              emptyMsg='No subclips on this clip'
              cols={[
                {
                  label: 'Breakpoint',
                  transform: row => ((row.startAt || 0) > 0 ? msToTimecode(row.startAt || 0, model.framerate) : ''),
                },
                {
                  label: 'Milliseconds (Ms)',
                  field: 'startAt',
                },
                {
                  label: 'Output(s)',
                  transform: row =>
                    row.sources && row.sources.length > 0 ? (
                      <Cluster space='medium'>
                        {row.sources.map(source => (
                          <TdLink key={source.id} title={source.type} row={source} url={source.file} />
                        ))}
                      </Cluster>
                    ) : (
                      <TdLink title='HLS' row={row} url={row.url} />
                    ),
                },
              ]}
              rows={subClips}
            ></Table>
            <Paragraph color='secondary'>
              * 0 is not an actual breakpoint, but we consider the subclip starting at the beginning
            </Paragraph>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBox title='Clip Sources' gridItemFull={true}>
        <Template label='header'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h3' color='secondary'>
              Clip Sources
            </Heading>
            {form.sources?.helpText && (
              <Notification size='small' type='error'>
                {form.sources?.helpText}
              </Notification>
            )}
            {ableTo('CLIP_EDIT') && (
              <Popover
                id='clipSourcesAddPopover'
                manualTrigger={true}
                visible={sourcePopoverOpen.add}
                onClickOutside={() => setSourcePopoverOpen({})}
                allowedPlacements={['top-end', 'bottom-end']}
              >
                <Template label='trigger'>
                  <Button id='clipSourcesAddButton' type='primary' onClick={() => setSourcePopoverOpen({add: true})}>
                    + Add
                  </Button>
                </Template>
                <Template label='popover'>
                  <ClipSourceForm
                    isNew={true}
                    value={{}}
                    onSave={val => handleSourcesUpdate(val)}
                    onCancel={() => setSourcePopoverOpen({})}
                  />
                </Template>
              </Popover>
            )}
          </Cluster>
        </Template>
        <Template label='content'>
          <Table
            flushTop={true}
            id='clipSourcesTable'
            fixedHeader={true}
            wrapContent={true}
            maxHeight='12rem'
            cols={[
              {
                label: 'Type',
                field: 'type',
              },
              {
                label: 'File URL',
                colMaxWidth: '43.75rem',
                transform: row => <Copy text={row.file} toCopy={row.file} />,
              },
              ...(showPolicy
                ? [
                    {
                      label: 'Policy',
                      transform: row => (row.adSparxAdPolicy ? row.adSparxAdPolicy : 'N/A'),
                    } as ITableCol<ISource>,
                  ]
                : []),
              ...(ableTo('CLIP_EDIT')
                ? [
                    {
                      label: 'Actions',
                      colWidth: '6.25rem',
                      transform: (row, _col, index) => (
                        <Cluster>
                          <TableActions
                            row={row}
                            icons={[]}
                            deleteOption={true}
                            displayField='name'
                            altTitle='source'
                            onClick={(row, icon) => handleSourceClick(icon, index)}
                          >
                            <Popover
                              id='clipSourcesEditPopover'
                              appendToBody={true}
                              manualTrigger={true}
                              visible={sourcePopoverOpen[index]}
                              onClickOutside={() => setSourcePopoverOpen({})}
                              allowedPlacements={['top-end', 'bottom-end']}
                            >
                              <Template label='trigger'>
                                <Icon space='small' icon='edit' onClick={() => handleSourceClick('edit', index)} />
                              </Template>
                              <Template label='popover'>
                                <ClipSourceForm
                                  onCancel={() => setSourcePopoverOpen({})}
                                  value={row}
                                  onSave={val => handleSourcesUpdate(val, index)}
                                />
                              </Template>
                            </Popover>
                          </TableActions>
                        </Cluster>
                      ),
                    } as ITableCol<ISource>,
                  ]
                : []),
            ]}
            rows={model.sources}
          ></Table>
        </Template>
      </ContentBox>
    </ContentBoxes>
  );
};
