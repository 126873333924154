import * as React from 'react';
import {
  Expand,
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  FormItem,
  Select,
  TextInput,
  Button,
  useValidateForm,
} from '@pluto-tv/assemble';
import {FormSearchWrapper} from 'components/formSearchWrapper';
import {programmingTypes} from 'helpers/programmingTypes';
import {useUserRegions} from 'helpers/useUserRegions';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {map, times} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import {TSortDirection} from 'models/generic';
import {IUserRunLimitTrackerSearch} from 'models/users';
import {searchRunLimitsValidator} from './validators';
import {RunLimitTrackerFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export interface IRunLimitTrackerSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isSearchExpanded: boolean;
  showFavoriteSearch: boolean;
  isFetching: boolean;
  search?: IUserRunLimitTrackerSearch;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserRunLimitTrackerSearch) => void;
  onClear: () => void;
}

const VALID_YEARS = map(times(10, num => `${2022 + num}`));
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const RunLimitTrackerSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isSearchExpanded,
    showFavoriteSearch,
    isFetching,
    search,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: IRunLimitTrackerSearchBarProps) => {
    const {
      form: searchForm,
      model: searchModel,
      onBlur: searchOnBlur,
      onChange: searchOnChange,
      setModel: searchSetModel,
      getValidation: searchGetValidation,
      setFields: searchSetFields,
      reset: searchResetModel,
    } = useValidateForm(searchRunLimitsValidator, 'ask');

    const [searchSelected, setSearchSelected] = React.useState<IUserRunLimitTrackerSearch | undefined>();

    const {activeRegions} = useUserRegions();
    const {data: partners} = useFindPartnersQuery();
    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const runLimitTitleInputId = 'title';

    const handleSearch = async () => {
      const validation = await searchGetValidation();

      if (!validation.state.isValid) {
        return;
      }

      onSearch({
        name: '',
        model: validation.model,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
      }
    }, [search, searchSetModel]);

    React.useLayoutEffect(() => {
      if (!isFetching && isSearchExpanded) {
        const runLimitTitleInput = document.getElementById(runLimitTitleInputId);

        runLimitTitleInput?.focus({
          preventScroll: true,
        });
      }
    }, [isFetching, isSearchExpanded]);

    const searchSelectedHandler = (search: IUserRunLimitTrackerSearch) => {
      setSearchSelected(search);
      searchSetModel(search.model);
      onSearch(search);
    };

    const clearHandler = () => {
      onClear();
      searchResetModel();
      setSearchSelected(undefined);
    };

    React.useEffect(
      () =>
        searchSetModel({
          month: luxon.now().toFormat('MMM'),
          year: luxon.now().toFormat('yyyy'),
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return (
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box background='pewter' padding='medium' fullHeight={true}>
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  {showFavoriteSearch && (
                    <RunLimitTrackerFavoriteSearch
                      searchModel={{
                        name: '',
                        model: searchModel,
                        sortCol: sortCol,
                        sortDir: sortDir,
                      }}
                      getValidation={searchGetValidation}
                      searchSelected={searchSelected}
                      onSearchSelected={searchSelectedHandler}
                      onClearSelection={clearHandler}
                    />
                  )}
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {isSearchExpanded && (
                  <Stack space='small'>
                    <Cluster wrap={false} space='xsmall' justify='space-between' fullWidth={true} growNthChild='all'>
                      <FormItem {...searchForm.month}>
                        <Select
                          placeholder='Month'
                          options={MONTHS.map(month => ({label: month}))}
                          onChange={val => searchSetFields({month: val?.label})}
                          value={{label: searchModel.month || ''}}
                        />
                      </FormItem>
                      <FormItem {...searchForm.year}>
                        <Select
                          placeholder='Year'
                          options={VALID_YEARS.map(year => ({label: year}))}
                          onChange={val => searchSetFields({year: val?.label})}
                          value={{label: searchModel.year || ''}}
                        />
                      </FormItem>
                    </Cluster>
                    <FormSearchWrapper onSearch={handleSearch}>
                      <FormItem
                        {...searchForm.title}
                        onBlur={() => {
                          searchOnBlur('title');
                        }}
                      >
                        <TextInput
                          placeholder='Title'
                          id={runLimitTitleInputId}
                          value={searchModel.title}
                          onChange={val => {
                            searchOnChange('title', val);
                          }}
                        />
                      </FormItem>
                    </FormSearchWrapper>
                    <FormItem label='Partner'>
                      <Select
                        clearable={true}
                        id='partner'
                        predicate='value'
                        searchable={true}
                        onSearch={val =>
                          (partners || [])
                            .filter(partner => partner.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                            .map(partner => ({label: partner.name, value: partner.id})) || []
                        }
                        placeholder='Partner'
                        options={(partners || []).map(partner => ({label: partner.name, value: partner.id}))}
                        onChange={val => searchSetFields({partnerId: val?.value})}
                        value={{label: '', value: searchModel.partnerId}}
                      />
                    </FormItem>
                    <FormItem label='Region'>
                      <Select
                        clearable={true}
                        options={activeRegions.map(ar => ({
                          label: `${ar.name} (${ar.code})`,
                          value: ar.code,
                        }))}
                        placeholder='Region(s)'
                        multiselect={true}
                        onChange={value => {
                          const regions = value;

                          searchSetFields({
                            activeRegions: (regions || []).map(region => region.value),
                          });
                        }}
                        predicate='value'
                        value={(searchModel.activeRegions || []).map(region => ({label: '', value: region}))}
                      />
                    </FormItem>
                    <FormItem label='Programming Type'>
                      <Select
                        clearable={true}
                        placeholder='Select a Type'
                        onChange={value => searchSetFields({programmingType: value?.label})}
                        value={{label: searchModel.programmingType || ''}}
                        options={programmingTypes.map(pT => ({label: pT}))}
                      />
                    </FormItem>
                    <FormSearchWrapper onSearch={handleSearch}>
                      <FormItem {...searchForm.licenseFee} onBlur={() => searchOnBlur('licenseFee')}>
                        <TextInput
                          type='number'
                          placeholder='License Fee'
                          value={searchModel.licenseFee}
                          onChange={val => searchOnChange('licenseFee', val)}
                        />
                      </FormItem>
                    </FormSearchWrapper>
                  </Stack>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='xxxsmall'>
                    <Button state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button type='primary' state={isFetching ? 'thinking' : ''} onClick={handleSearch}>
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

RunLimitTrackerSearchBar.displayName = 'RunLimitTrackerSearchBar';
export default RunLimitTrackerSearchBar;
