import * as React from 'react';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  FormItem,
  Heading,
  ITableCol,
  Popover,
  Select,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  useValidateForm,
  ISelectOption,
} from '@pluto-tv/assemble';

import {debounce} from 'lodash';
import {useAppPermissions} from 'app/permissions';
import {TableActions} from 'components/tableActions';
import {INestedSeriesProps} from '../nestedPropsInterface';
import {IChannel, ISeriesVodCategoryEntry} from 'models/series';
import vodCollectionRoutes from 'routes/programming.routes';
import {useLazyFindQuery as useFindVodCategoriesQuery} from 'features/vodCollections/vodCollectionsApi';
import {useLazyFindLiteQuery as useFindChannelsQuery} from 'features/channels/channelsApi';
import {ISelectedCategory, ISelectedChannel} from 'models/episodes';
import {seriesChannelsValidator, seriesVodCategoryValidator} from 'views/content/series/validators';
import {useSeriesPermissions} from 'views/content/series/permissions/useSeriesPermissions';
import {IVodCategory} from 'models/vodCategories';
import {IVodCollection} from 'models/vodCollections';

interface IPopover {
  add?: boolean;
  edit?: boolean;
  id?: string;
}

export default ({model, pristineModel, setFields}: INestedSeriesProps): JSX.Element => {
  const {ableTo, permissions} = useAppPermissions();
  const {CAN_EDIT, editPermission} = useSeriesPermissions(pristineModel);

  const [
    searchVodCategories,
    {data: collectionsData, isSuccess: isCollectionsSuccess, isLoading: isCollectionsLoading},
  ] = useFindVodCategoriesQuery();

  const [
    searchVodCategoriesForTable,
    {
      data: collectionsDataForTable,
      isSuccess: isCollectionsSuccessForTable,
      isError: isCollectionsErrorForTable,
      error: collectionsErrorForTable,
    },
  ] = useFindVodCategoriesQuery();

  const [searchChannels, {data: channelsItems, isSuccess: isChannelsItemsSuccess, isLoading: isChannelsItemsLoading}] =
    useFindChannelsQuery();

  const [vodCategoriesTable, setVodCategoriesTable] = React.useState<ISeriesVodCategoryEntry[]>([]);
  const [vodCategoriesOpen, setVodCategoriesOpen] = React.useState<IPopover>({});

  const [inputValueCollection, setInputValueCollection] = React.useState<string | undefined>(undefined);
  const [selectedValueCollection, setSelectedValueCollection] = React.useState<string | undefined>(undefined);

  const [vodCategoriesDictionary, setVodCategoriesDictionary] = React.useState<{[key: string]: IVodCategory}>({});
  const [vodSelectOptions, setVodSelectOptions] = React.useState<ISelectOption[]>([]);

  React.useEffect(() => {
    if (vodCategoriesOpen.add || (vodCategoriesOpen.edit && vodCategoriesOpen.id)) {
      searchVodCategories({
        limit: 100,
        activeRegions: [model.activeRegion as string],
        archived: false,
      }).then(response => {
        const data = response?.data?.data || [];
        const options = data.map((collection: IVodCollection) => ({
          label: collection.name,
          value: collection.id,
        }));
        setVodSelectOptions(options);
      });
    }
  }, [vodCategoriesOpen, model.activeRegion, searchVodCategories]);

  React.useEffect(() => {
    if (isCollectionsSuccess) {
      setVodCategoriesDictionary(
        collectionsData?.data.reduce((dic, cat) => {
          dic[cat.id] = cat;
          return dic;
        }, {}) || {},
      );
    }
  }, [collectionsData, isCollectionsSuccess]);

  React.useEffect(() => {
    if (model.activeRegion) {
      searchVodCategoriesForTable({
        limit: 1, // Just one item to check for availability
        activeRegions: [model.activeRegion as string],
        archived: false,
      });
    }
  }, [model.activeRegion, searchVodCategoriesForTable]);

  const {
    form: selectedCategoryForm,
    model: selectedCategoryModel,
    setFields: selectedCategorySetFields,
    state: formState,
    reset: selectedCategoryReset,
  } = useValidateForm<ISelectedCategory>(seriesVodCategoryValidator);

  const {
    model: selectedChannelModel,
    setFields: selectedChannelSetFields,
    state: channelFormState,
    reset: selectedChannelReset,
  } = useValidateForm<ISelectedChannel>(seriesChannelsValidator);

  const handleCancelAddOrUpdateCategory = () => {
    resetSearch();

    setVodCategoriesOpen({});
    setChannelsOpen({});
  };

  const handleAddOrUpdateCategoryEntry = () => {
    if (!selectedCategoryModel.category) {
      return;
    }

    const selectedVodCategory = vodCategoriesDictionary[selectedCategoryModel.category];

    if (!selectedVodCategory) {
      return;
    }

    if (vodCategoriesOpen.add) {
      setFields({
        vodCategoryEntries: [
          ...(vodCategoriesTable || []),
          {
            id: `new-${Date.now()}`,
            vodCategory: selectedVodCategory,
            order: selectedCategoryModel.order || 1,
            createdAt: new Date(),
            updatedAt: new Date(),
            series: model.id || '',
            changeType: 'I',
          },
        ],
      });
    } else if (vodCategoriesOpen.edit && vodCategoriesOpen.id) {
      setFields({
        vodCategoryEntries: (vodCategoriesTable || []).map(vod => {
          if (vod.id !== selectedCategoryModel?.id) {
            return vod;
          }
          return {
            ...vod,
            order: selectedCategoryModel?.order || 0,
            vodCategory: selectedVodCategory,
            updatedAt: new Date(),
            changeType: vodCategoriesOpen.id?.includes('new-') ? 'I' : 'U',
          };
        }),
      });
    }
    setSelectedValueCollection(undefined);
    setInputValueCollection('');
    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  const handleEdit = (vodCategory: ISeriesVodCategoryEntry) => {
    setVodCategoriesOpen({edit: true, id: vodCategory.id});

    setSelectedValueCollection(vodCategory.vodCategory.id);

    selectedCategorySetFields({
      id: vodCategory.id,
      category: vodCategory.vodCategory.id,
      order: vodCategory.order,
    });
  };

  const handleDelete = (vodCategory: ISeriesVodCategoryEntry) => {
    const cts = (vodCategoriesTable || [])
      .map(voce => {
        if (voce.id !== vodCategory?.id) {
          return voce;
        }

        return voce.changeType === 'I' ? {...voce, changeType: 'X'} : {...voce, changeType: 'D'};
      })
      .filter(voce => voce.changeType !== 'X');

    setFields({
      vodCategoryEntries: cts as ISeriesVodCategoryEntry[],
    });
    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  const handleVodCancel = () => {
    resetSearch();
    setVodCategoriesOpen({});
  };

  // CHANNELS SECTION
  const [channelsTable, setChannelsTable] = React.useState<IChannel[]>([]);
  const [channelsOpen, setChannelsOpen] = React.useState<IPopover>({});
  const [channelsDictionary, setChannelsDictionary] = React.useState<{[key: string]: IChannel}>({});
  const [searchQuery] = React.useState(''); // State to store the search query

  const lastQueryRef = React.useRef<string | undefined>(undefined);
  const [inputValue, setInputValue] = React.useState<string | undefined>(undefined);
  const [selectedValueChannel, setSelectedValueChannel] = React.useState<string | undefined>(undefined);

  const [channelSelectOptions, setChannelSelectOptions] = React.useState<ISelectOption[]>([]);

  React.useEffect(() => {
    if (channelsOpen.add) {
      searchChannels({
        limit: 100,
        activeRegions: [model.activeRegion as string],
        archived: false,
      }).then(response => {
        const data = response?.data?.data || [];
        const options = data
          .filter(ch => !channelsTable.find(existingCh => existingCh.id === ch.id))
          .map(ch => ({
            label: ch.name,
            value: ch.id,
          }));
        setChannelSelectOptions(options);

        setChannelsDictionary(prevDict => {
          const newDict = {...prevDict};
          data.forEach(ch => {
            const channel: IChannel = {
              ...ch,
              displayName: ch.name,
            };
            newDict[ch.id] = channel;
          });
          return newDict;
        });
      });
    }
  }, [channelsOpen, model.activeRegion, searchChannels, channelsTable]);

  React.useEffect(() => {
    // Initial load
    if (model.id && (inputValue === undefined || inputValueCollection === undefined)) {
      searchChannels({limit: 100, activeRegions: [model.activeRegion as string], archived: false});
      searchVodCategories({limit: 100, activeRegions: [model.activeRegion as string], archived: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.activeRegion, model.id, searchChannels, searchVodCategories]);

  React.useEffect(() => {
    // Empty search
    if (model.id && (inputValue === '' || inputValueCollection === '')) {
      searchVodCategories({limit: 100, activeRegions: [model.activeRegion as string], archived: false}).then(
        response => {
          const data = response?.data?.data || [];
          const options = data.map(cat => ({
            label: cat.name,
            value: cat.id,
          }));
          setVodSelectOptions(options);

          setVodCategoriesDictionary(prevDict => {
            const newDict = {...prevDict};
            data.forEach(cat => {
              const category: any = {
                ...cat,
                displayName: cat.name,
              };
              newDict[cat.id] = category;
            });
            return newDict;
          });
        },
      );

      searchChannels({
        limit: 100,
        activeRegions: [model.activeRegion as string],
        archived: false,
      }).then(response => {
        const data = response?.data?.data || [];
        const options = data
          .filter(ch => !channelsTable.find(existingCh => existingCh.id === ch.id))
          .map(ch => ({
            label: ch.name,
            value: ch.id,
          }));
        setChannelSelectOptions(options);

        setChannelsDictionary(prevDict => {
          const newDict = {...prevDict};
          data.forEach(ch => {
            const channel: IChannel = {
              ...ch,
              displayName: ch.name,
            };
            newDict[ch.id] = channel;
          });
          return newDict;
        });
      });
    }
  }, [
    model.activeRegion,
    model.id,
    inputValue,
    inputValueCollection,
    channelsTable,
    searchChannels,
    searchVodCategories,
  ]);

  React.useEffect(() => {
    if (searchQuery.trim().length > 0) {
      const debouncedSearch = debounce(() => {
        searchChannels({name: searchQuery, limit: 10, activeRegion: model.activeRegion, archived: false});
      }, 1000); // millisecs

      debouncedSearch();

      // cancel previous debounce if the effect runs again
      return () => {
        debouncedSearch.cancel();
      };
    }
  }, [searchQuery, model.activeRegion, searchChannels]);

  React.useEffect(() => {
    if (isChannelsItemsSuccess) {
      setChannelsDictionary(
        channelsItems?.data.reduce((dic, cat) => {
          dic[cat.id] = cat;
          return dic;
        }, {}) || {},
      );
    }
  }, [channelsItems, isChannelsItemsSuccess]);

  const handleDeleteChannel = (channel: IChannel) => {
    const channnelsUpdated = (channelsTable || []).filter(ch => ch.id !== channel.id);

    setFields({channels: channnelsUpdated});
    selectedChannelReset();
    setChannelsOpen({});
  };

  const handleAddOrUpdateChannels = () => {
    if (!selectedChannelModel.channel) {
      return;
    }

    const selectedChannel = channelsDictionary[selectedChannelModel.channel];

    if (!selectedChannel) {
      return;
    }

    if (channelsOpen.add) {
      setFields({
        channels: [
          ...(channelsTable || []),
          {
            ...selectedChannel,
          },
        ],
      });

      setChannelsTable(prevChannels => [...prevChannels, {...selectedChannel}]);
    }

    setChannelsOpen({});
    selectedChannelReset();
  };

  React.useEffect(() => {
    if (!model) {
      return;
    }

    const vodCat: ISeriesVodCategoryEntry[] = [];
    const chan: IChannel[] = [];

    model.vodCategoryEntries?.forEach(
      vodCategory =>
        vodCategory.vodCategory &&
        vodCat.push({
          ...vodCategory,
        }),
    );

    model.channels?.forEach(
      channel =>
        channel.name &&
        chan.push({
          ...channel,
        }),
    );

    setVodCategoriesTable(vodCat);
    setChannelsTable(chan);
  }, [model]);

  const handleSearchVod = React.useCallback(
    async (query: string): Promise<ISelectOption[]> => {
      setInputValueCollection(query);

      if (query.trim().length === 0 || query === lastQueryRef.current) {
        return [];
      }

      lastQueryRef.current = query; // Store the current query to avoid repeated calls

      const vodQuery = () =>
        searchVodCategories({
          name: query,
          limit: 10,
          activeRegions: [model.activeRegion as string],
          archived: false,
        });

      try {
        const response = await vodQuery();
        const data: any[] = response?.data?.data || [];

        if (data.length === 0) {
          const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
          setVodSelectOptions(noMatches);
          return noMatches;
        }

        const options = data.map(d => ({
          label: d.name,
          value: d.id,
        }));

        setVodSelectOptions(options);

        return options;
      } catch (error) {
        const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
        setVodSelectOptions(noMatches);
        return noMatches;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.activeRegion, searchVodCategories, vodCategoriesTable],
  );

  const handleSearchChannels = React.useCallback(
    async (query: string): Promise<ISelectOption[]> => {
      setInputValue(query);

      if (query.trim().length === 0 || query === lastQueryRef.current) {
        return [];
      }

      lastQueryRef.current = query; // Store the current query to avoid repeated calls

      const channelsQuery = () =>
        searchChannels({
          name: query,
          limit: 10,
          activeRegion: model.activeRegion,
          archived: false,
        });

      try {
        const response = await channelsQuery();
        const data: any[] = response?.data?.data || [];

        if (data.length === 0) {
          const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
          setChannelSelectOptions(noMatches);
          return noMatches;
        }

        const options = data.map(d => ({
          label: d.name,
          value: d.id,
        }));

        setChannelSelectOptions(options);

        setChannelsDictionary(prevDict => {
          const newDict = {...prevDict};
          data.forEach(ch => {
            const channel: IChannel = {
              ...ch,
              displayName: ch.name,
            };
            newDict[ch.id] = channel;
          });
          return newDict;
        });

        return options;
      } catch (error) {
        const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
        setChannelSelectOptions(noMatches);
        return noMatches;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.activeRegion, searchChannels, channelsTable],
  );

  const handleSearchVodDebounced = React.useMemo(() => {
    return debounce((query: string): Promise<ISelectOption[]> => {
      return handleSearchVod(query);
    }, 300);
  }, [handleSearchVod]);

  const handleSearchChannelsDebounced = React.useMemo(() => {
    return debounce((query: string): Promise<ISelectOption[]> => {
      return handleSearchChannels(query);
    }, 300);
  }, [handleSearchChannels]);

  const handleSearchSafeVod = (query: string): Promise<ISelectOption[]> => {
    if (query === '') setInputValueCollection('');
    return new Promise(resolve => {
      const result = handleSearchVodDebounced(query);

      if (result !== undefined) {
        return result
          .then(resolve)
          .catch(() => resolve([{label: 'No matches found', value: 'no-matches', disabled: true}]));
      }
      return resolve([{label: 'Loading...', value: 'loading', disabled: true}]);
    });
  };

  const handleSearchSafeChannels = (query: string): Promise<ISelectOption[]> => {
    if (query === '') setInputValue('');
    return new Promise(resolve => {
      const result = handleSearchChannelsDebounced(query);

      if (result !== undefined) {
        return result
          .then(resolve)
          .catch(() => resolve([{label: 'No matches found', value: 'no-matches', disabled: true}]));
      }
      return resolve([{label: 'Loading...', value: 'loading', disabled: true}]);
    });
  };

  const resetSearch = () => {
    lastQueryRef.current = undefined;

    setSelectedValueCollection(undefined);
    setSelectedValueChannel(undefined);

    setInputValueCollection(undefined);
    setInputValue(undefined);

    selectedCategoryReset();
    selectedChannelReset();

    setVodSelectOptions([]);
    setChannelSelectOptions([]);

    setVodCategoriesOpen({});
    setChannelsOpen({});
  };

  const noVodCollectionsAvailable =
    (isCollectionsSuccessForTable && collectionsDataForTable?.data.length === 0) ||
    (isCollectionsErrorForTable &&
      'status' in (collectionsErrorForTable || {}) &&
      (collectionsErrorForTable as any)?.status === 404);

  return (
    <ContentBoxes layout='stack'>
      <ContentBox>
        <Template label='header'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h3' color='secondary'>
              VOD Collections
            </Heading>
            {CAN_EDIT && (
              <Popover
                manualTrigger={true}
                visible={vodCategoriesOpen.add || (vodCategoriesOpen.edit && !!vodCategoriesOpen.id)}
                onClickOutside={handleVodCancel}
                permission={editPermission && permissions.VOD_EDIT}
              >
                <Template label='trigger'>
                  <Button
                    id='addVodCollectionButton'
                    type='primary'
                    permission={editPermission && permissions.VOD_EDIT}
                    onClick={() => setVodCategoriesOpen({add: true})}
                    state={isCollectionsLoading ? 'thinking' : noVodCollectionsAvailable ? 'disabled' : ''}
                  >
                    + Add
                  </Button>
                </Template>
                <Template label='popover'>
                  <form id='vodCollectionForm'>
                    <Box padding='small' background='charcoal' width='100%'>
                      <Stack space='small'>
                        <FormItem label='Collection Name' {...selectedCategoryForm.category}>
                          <Select
                            id='category'
                            width='220px'
                            placeholder='Search Collections'
                            predicate='value'
                            value={
                              selectedValueCollection
                                ? {
                                    label: vodCategoriesDictionary[selectedValueCollection]?.name ?? '',
                                    value: selectedValueCollection,
                                  }
                                : {label: inputValueCollection || '', value: inputValueCollection || ''}
                            }
                            onChange={value => {
                              setSelectedValueCollection(value.value);
                              setInputValueCollection(undefined);
                              selectedCategorySetFields({category: value.value});
                            }}
                            options={vodSelectOptions}
                            searchable={true}
                            searchPlaceholder='Search Collections'
                            onSearch={query => handleSearchSafeVod(query)} // Trigger lookup on search
                          />
                        </FormItem>
                        <FormItem label='Order' {...selectedCategoryForm.order}>
                          <TextInput
                            id='categoryOrder'
                            type='number'
                            onChange={value => selectedCategorySetFields({order: value})}
                            value={selectedCategoryModel.order}
                          />
                        </FormItem>
                        <Cluster justify='space-around'>
                          <Button ghost={true} onClick={handleCancelAddOrUpdateCategory}>
                            Cancel
                          </Button>
                          <Button
                            id='addUpdateVodCategoryButton'
                            type='primary'
                            state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                            onClick={handleAddOrUpdateCategoryEntry}
                          >
                            {vodCategoriesOpen.edit && !!vodCategoriesOpen.id ? 'Update' : '+ Add'}
                          </Button>
                        </Cluster>
                      </Stack>
                    </Box>
                  </form>
                </Template>
              </Popover>
            )}
          </Cluster>
        </Template>
        <Template label='content'>
          <Table
            id='seriesVodCollectionsTable'
            flushTop={true}
            fixedHeader={true}
            emptyMsg={
              noVodCollectionsAvailable
                ? 'No VOD collections available for the selected active region'
                : vodCategoriesTable.length === 0
                ? 'No VOD Collections'
                : ''
            }
            cols={[
              {
                label: 'Collection Name',
                colMaxWidth: '25rem',
                transform: row => (
                  <TdLink
                    row={row}
                    title={row.vodCategory.name}
                    target='_blank'
                    url={vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row.vodCategory.id)}
                  />
                ),
              },
              {
                label: 'Display Name',
                transform: row => row.vodCategory.displayName,
              },
              {
                label: 'Published',
                colMinWidth: '6.875rem',
                transform: row => (
                  <Status
                    label={row.vodCategory.enabled ? 'Published' : 'Unpublished'}
                    state={row.vodCategory.enabled ? 'success' : 'neutral'}
                  />
                ),
              },
              {
                label: 'Archived',
                transform: row => (row.vodCategory.archived ? 'Yes' : 'No'),
              },
              {
                label: 'Order',
                transform: row => row.order,
              },
              ...(CAN_EDIT && ableTo('VOD_EDIT')
                ? [
                    {
                      label: 'Actions',
                      colWidth: '6.25rem',
                      transform: row => (
                        <TableActions
                          altTitle='collection'
                          row={row}
                          icons={['edit']}
                          deleteOption={ableTo('SERIES_DELETE') && ableTo('VOD_EDIT')}
                          onClick={(row, icon) => {
                            switch (icon) {
                              case 'edit':
                                handleEdit(row);
                                break;
                              case 'delete':
                                handleDelete(row);
                                break;
                              default:
                            }
                          }}
                        />
                      ),
                    } as ITableCol<ISeriesVodCategoryEntry>,
                  ]
                : []),
            ]}
            rows={vodCategoriesTable.filter(vod => vod.changeType !== 'D') || []}
          ></Table>
        </Template>
      </ContentBox>
      <ContentBox>
        <Template label='header'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h3'>Channels</Heading>
            {CAN_EDIT && (
              <Popover manualTrigger={true} visible={channelsOpen.add} onClickOutside={() => setChannelsOpen({})}>
                <Template label='trigger'>
                  <Button
                    id='addChannelsButton'
                    type='primary'
                    permission={editPermission && permissions.CHANNEL_EDIT}
                    onClick={() => setChannelsOpen({add: true})}
                    state={isChannelsItemsLoading ? 'thinking' : ''}
                  >
                    + Add
                  </Button>
                </Template>
                <Template label='popover'>
                  <form id='channelForm'>
                    <Box padding='small' background='charcoal' width='100%'>
                      <Stack space='small'>
                        <FormItem label='Select Channel'>
                          <Select
                            width='220px'
                            placeholder='Search Channels'
                            id='channel'
                            predicate='value'
                            value={
                              selectedValueChannel
                                ? {
                                    label: channelsDictionary[selectedValueChannel]?.name ?? '',
                                    value: selectedValueChannel,
                                  }
                                : {label: inputValue || '', value: inputValue || ''}
                            }
                            onChange={value => {
                              setSelectedValueChannel(value.value);
                              setInputValue(undefined);
                              selectedChannelSetFields({channel: value.value});
                            }}
                            options={channelSelectOptions}
                            searchable={true}
                            searchPlaceholder='Search Channels'
                            onSearch={handleSearchSafeChannels}
                          />
                        </FormItem>
                        <Cluster justify='space-around'>
                          <Button ghost={true} onClick={handleCancelAddOrUpdateCategory}>
                            Cancel
                          </Button>
                          <Button
                            id='addUpdateChannelButton'
                            type='primary'
                            state={!channelFormState.isValid || !channelFormState.isDirty ? 'disabled' : ''}
                            onClick={handleAddOrUpdateChannels}
                          >
                            + Add
                          </Button>
                        </Cluster>
                      </Stack>
                    </Box>
                  </form>
                </Template>
              </Popover>
            )}
          </Cluster>
        </Template>
        <Template label='content'>
          <Table
            id='seriesChannelsTable'
            flushTop={true}
            fixedHeader={true}
            emptyMsg='No Channels'
            cols={[
              {
                label: 'Channel Name',
                colMaxWidth: '25rem',
                transform: row => <TdLink row={row} title={row.name} url={`/channels/${row.id}/catalog`} />,
              },
              {
                label: 'Published',
                colMinWidth: '6.875rem',
                transform: row => (
                  <Status
                    label={row.published ? 'Published' : 'Unpublished'}
                    state={row.published ? 'success' : 'neutral'}
                  />
                ),
              },
              {
                label: 'Archived',
                transform: row => (row.archived ? 'Yes' : 'No'),
              },
              // NOTE: An actively scheduled piece of content cant be removed.
              // we will need to disable the 'Yes, Proceed' button or something
              // this is the case. Let's talk more about this when you get here Joe.
              ...(CAN_EDIT && ableTo('CHANNEL_EDIT')
                ? [
                    {
                      label: 'Actions',
                      colWidth: '3rem',

                      transform: row => (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <TableActions
                            row={row}
                            icons={[]}
                            deleteOption={ableTo('SERIES_DELETE')}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'delete':
                                  handleDeleteChannel(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        </div>
                      ),
                    } as ITableCol<IChannel>,
                  ]
                : []),
            ]}
            rows={channelsTable}
          ></Table>
          <Cluster justify='flex-end'></Cluster>
        </Template>
      </ContentBox>
    </ContentBoxes>
  );
};
