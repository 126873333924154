import {set, get} from 'lodash-es';
import {IPermission} from 'models/permissions';

export type TPermissionChangeCallback = (permission: string[], enable: boolean) => void;

type PermissionsTree = {
  [parent: string]: {
    path: string;
    _id: string;
    name: string;
    category: string;
    displayName: string;
    dependsOn: string[];
    parent?: string;
  }[];
};

export const buildPermissionsTree = (permissions: IPermission[] | undefined): PermissionsTree | null =>
  !permissions
    ? null
    : permissions
        .map(p => ({...p, path: p.category.split('_').join('.')}))
        .reduce((acum, item) => {
          set(
            acum,
            item.path,
            !item.parent
              ? [
                  ...(get(acum, item.path) || []),
                  {name: item.name, displayName: item.displayName, dependsOn: item.dependsOn},
                ]
              : {
                  ...(get(acum, item.path) || {}),
                  [item.parent]: [
                    ...(get(acum, `${item.path}[${item.parent}]`) || []),
                    {name: item.name, displayName: item.displayName, dependsOn: item.dependsOn},
                  ],
                },
          );

          return acum;
        }, {});
