import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Divider,
  Heading,
  Icon,
  Paragraph,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {cloneDeep, intersection, some} from 'lodash-es';

import {
  useFindByIdQuery,
  usePublishTimelineDraftsMutation,
  useUpdateMutation,
  useUpdateTimelineDraftsMutation,
} from 'features/channels/channelsApi';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';

import {
  channelDetailsValidator,
  channelEditValidator,
  channelDistributionValidator,
  channelSettingsValidator,
  channelArtworksValidator,
  channelProgramValidator,
} from '../validators';

import {useAppPermissions} from 'app/permissions';
import ConfirmRouteChange from 'components/confirmRouteChange';
import programmingRoutes from 'routes/programming.routes';
import {IChannel, ITimelineDraft} from 'models/channels';
import DeleteConfirmation from 'components/deleteConfirmation';

import ChannelDetails from './details';
import ChannelDistribution from './distribution';
import ChannelSettings from './settings';
import ChannelArtwork from './artwork';
import ChannelCatalog from './catalog';
import ChannelProgram from './program';
import CrudError from 'components/crudError';
import {IMainCategory} from 'models/mainCategories';
import {
  errorMsgTransform,
  getOverlapsOnCalendar,
  getPushingFutureOnCalendar,
  isChannelGracenoteReady,
} from 'views/programming/channel/utils';
import {MemoryQueueApiProvider} from '../contexts/memoryQueueApiProvider';
import {MemoryQueueProvider} from '../contexts/memoryQueueProvider';
import {useFindQuery as useFindTerritoriesQuery} from 'features/territories/territoriesApi';
import {sortCodes} from 'helpers/dmaDistributionCodes';
import {IProgramFormModel} from './nestedPropsInterface';
import {getMinStart} from './program/utilities';
import {ITimeline, useDiscardTimelineDraftsMutation} from 'features/channelTimelines/channelTimelinesApi';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';
import {ICalendarGap, IGapOrOverlap} from '../utils/models';
import ConfirmDraftSaveModal from './program/components/ConfirmDraftSaveModal';

const channelTabs = ['details', 'distribution', 'settings', 'artwork', 'catalog', 'program'] as const;

export type TChannelTabs = typeof channelTabs[number];

const getActiveTab = (pathname: string): TChannelTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as TChannelTabs;

  if (channelTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'details';
};

export default (): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const {permissions} = useAppPermissions();
  const history = useHistory();
  const activeTab: TChannelTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [isDraft, setIsDraft] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasFlaggedFields, setHasFlaggedFields] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [isGracenoteFormValid, setIsGracenoteFormValid] = React.useState(true);
  const [isCfaasFormValid, setIsCfaasFormValid] = React.useState(true);
  const [clearWeekVisible, setClearWeekVisible] = React.useState(false);
  const [discardDraftVisible, setDiscardDraftVisible] = React.useState(false);
  const [lastProgrammingUpdate, setLastProgrammingUpdate] = React.useState(new Date());
  const toggleClearWeekVisible = () => {
    setClearWeekVisible(!clearWeekVisible);
  };
  const gapsStateRef = React.useRef<ICalendarGap[]>([]);

  const [isPublishingDraft, setIsPublishingDraft] = React.useState<boolean>(false);
  const [timelineGaps, setTimelineGaps] = React.useState<IGapOrOverlap[]>([]);
  const [timelineOverlaps, setTimelineOverlaps] = React.useState<IGapOrOverlap[]>([]);
  const [timelinePushing, setTimelinePushing] = React.useState<number>(0);
  const [hasIntegrationEnabled, setHasIntegrationEnabled] = React.useState<boolean>(false);
  const pendingOperation = React.useRef<any>(null);

  const setGapsState = React.useCallback((gaps: ICalendarGap[]) => {
    gapsStateRef.current = gaps;
  }, []);
  const [gapChecksPending, setGapChecksPending] = React.useState<boolean>(false);
  const [isChannelDraftDiscarded, setIsChannelDraftDiscarded] = React.useState<number>();

  const {
    data: channel,
    isError: isChannelError,
    error: channelError,
    isLoading: isChannelFetching,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [discardTimelineDrafts, {isLoading: isDiscardTimelineDraftsLoading}] = useDiscardTimelineDraftsMutation();

  const handleDiscardDraft = async () => {
    setDiscardDraftVisible(false);

    try {
      await discardTimelineDrafts({id}).unwrap();
      Toast.success('Success', 'Channel Timeline Draft discarded');
      setIsChannelDraftDiscarded(new Date().getTime());
    } catch (error) {
      Toast.error('Error', 'Failed to discard channel draft timelines. Please try again', 8000);
    }
  };

  const {data: mainCategories} = useFindMainCategoriesQuery();
  const {data: territories} = useFindTerritoriesQuery();

  const [updateChannel] = useUpdateMutation();
  const [updateTimelineDrafts] = useUpdateTimelineDraftsMutation();
  const [publishTimelineDrafts] = usePublishTimelineDraftsMutation();
  const [activeChannel, setActiveChannel] = React.useState<IChannel>();
  const [territoriesList, setTerritoriesList] = React.useState<string[]>([]);

  const {
    //form: programForm,
    // onChange: programOnChange,
    model: programModel,
    state: programFormState,
    pristineModel: programPristineModel,
    setFields: programSetFields,
    dirtyFields: programDirtyFields,
    setModel: programSetModel,
  } = useValidateForm<IProgramFormModel>([
    {
      name: 'draft',
      required: false,
    },
    {
      name: 'published',
      required: false,
    },
    {
      name: 'startDate',
      required: false,
    },
    {
      name: 'endDate',
      required: false,
    },
  ]);

  // shortcut to save a draft calendar
  useKeyboardShortcut('ctrl + s, command + s', event => {
    event.preventDefault();
    if (programModel.draft !== programPristineModel.draft) {
      saveDraftCalendar();
    }
  });

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IChannel>([
    ...channelEditValidator,
    ...channelDetailsValidator,
    ...channelDistributionValidator,
    ...channelSettingsValidator,
    ...channelArtworksValidator,
    ...channelProgramValidator,
  ]);

  const {ableTo} = useAppPermissions();

  React.useEffect(() => {
    setHasIntegrationEnabled(!!(model.cfaasIntegration?.enabled || model.gracenoteIntegration?.enabled));
  }, [model.cfaasIntegration?.enabled, model.gracenoteIntegration?.enabled]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.channelListPage);
    }
  }, [isLeaving, history]);

  React.useEffect(() => {
    if (channel) {
      setActiveChannel(channel);
    }
  }, [channel]);

  React.useEffect(() => {
    if (territories?.length) {
      setTerritoriesList(
        [...territories].sort((r1, r2) => (r1.name.toUpperCase() > r2.name.toUpperCase() ? 1 : -1)).map(r => r.id),
      );
    }
  }, [territories]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const checkBeforeSaveDraftCalendar = React.useCallback(() => false, []);

  const checkGapsAndOverlaps = React.useCallback(
    (isPublishing: boolean): boolean => {
      const gaps =
        gapsStateRef.current
          .filter(g => g.end > new Date().getTime())
          .map(g => ({
            date: new Date(g.start),
            duration: g.end - g.start,
          })) || [];

      const overlaps = getOverlapsOnCalendar(programModel.draft || []);

      const pushing = getPushingFutureOnCalendar(
        programModel.endDate || 0,
        programModel.draft || [],
        isPublishing ? programPristineModel.published || [] : programPristineModel.draft || [],
      );

      if (!gaps.length && !overlaps.length && pushing <= 0) {
        return false;
      } else {
        setIsPublishingDraft(isPublishing);
        setTimelineGaps(gaps);
        setTimelineOverlaps(overlaps);
        setTimelinePushing(pushing);
        return true;
      }
    },
    [programModel?.draft, programModel?.endDate, programPristineModel?.draft, programPristineModel?.published],
  );

  const savePublishedCalendar = () => {
    if (!checkGapsAndOverlaps(true)) {
      performSavePublishedCalendar();
    }
  };

  const performSaveDraftCalendar = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const miliSecsToPushTimeline = getPushingFutureOnCalendar(
        programModel.endDate || 0,
        programModel.draft || [],
        programPristineModel.draft || [],
      );

      const deletedIds = programPristineModel.draft
        ?.filter(pristineItem => !programModel.draft?.some(newItem => newItem.id === pristineItem.id))
        .map(deletedTimeline => deletedTimeline.id);
      const channelIsGracenoteReady = isChannelGracenoteReady(model);
      await updateTimelineDrafts({
        id: id,
        deleteIds: deletedIds || [],
        weekEnd: programModel.endDate,
        afterEnd: miliSecsToPushTimeline > 0 ? Math.round(miliSecsToPushTimeline / 1000) : undefined,
        timelines:
          programModel.draft?.map(draftTimeline => {
            const isNew = draftTimeline.id.includes('-');

            const tlDraft: ITimelineDraft = {
              id: isNew ? undefined : draftTimeline.id,
              channel: id,
              episode: draftTimeline.episode.id,
              start: draftTimeline.start.toISOString(),
              stop: draftTimeline.stop.toISOString(),
              manualOverrideMarker: draftTimeline.manualOverrideMarker,
            };

            if (isNew) {
              const creationDate = new Date().toISOString();

              tlDraft.createdAt = creationDate;
              tlDraft.updatedAt = creationDate;
              if (channelIsGracenoteReady) {
                tlDraft.manualOverrideMarker = creationDate;
              }
            }

            return tlDraft;
          }) || [],
      }).unwrap();
      Toast.success('Success', 'Channel Timeline Draft Updated');
      setLastProgrammingUpdate(new Date());
      if (pendingOperation.current) pendingOperation.current();
    } catch (e: any) {
      const errorMsg = e.data?.message ? errorMsgTransform(e.data.message) : '';
      Toast.error('Error', !!errorMsg ? errorMsg : 'Failed to save channel. Please try again', 8000);
    } finally {
      setIsSaving(false);
      pendingOperation.current = null;
    }
  }, [id, model, programModel?.draft, programModel?.endDate, programPristineModel?.draft, updateTimelineDrafts]);

  const saveDraftCalendar = React.useCallback(
    async (pendingCallback?: () => void) => {
      if (typeof pendingCallback === 'function') {
        pendingOperation.current = pendingCallback;
      }

      if (!checkGapsAndOverlaps(false)) {
        performSaveDraftCalendar();
      } else {
        throw new Error('Timeline gaps and overlaps are not allowed');
      }
    },
    [checkGapsAndOverlaps, performSaveDraftCalendar],
  );

  const performSavePublishedCalendar = async () => {
    setIsSaving(true);
    try {
      await publishTimelineDrafts({id: id});
      Toast.success('Success', 'Channel Timeline Published');
      setLastProgrammingUpdate(new Date());
    } catch (e: any) {
      const errorMsg = e.data?.message ? errorMsgTransform(e.data.message) : '';
      Toast.error('Error', !!errorMsg ? errorMsg : 'Failed to save channel. Please try again', 8000);
    } finally {
      setIsPublishingDraft(false);
      setIsSaving(false);
    }
  };

  const clearWeek = () => {
    const minStart = getMinStart();
    programSetFields({
      ...programModel,
      draft: programModel.draft?.filter((draftTimeline: ITimeline) => draftTimeline.start <= minStart),
    });
    setClearWeekVisible(false);
  };

  const saveData = React.useCallback(async () => {
    setIsSaving(true);

    /**
     * To avoid confusion on enabling one integration but selecting
     * another "autoEpgSource", this value is set here to always match
     * which integration is enabled.
     */
    if (model?.gracenoteIntegration?.enabled) {
      dirtyFields.autoEpgSource = true;
      model.autoEpgSource = 'gracenote';
    }

    if (model?.cfaasIntegration?.enabled) {
      dirtyFields.autoEpgSource = true;
      model.autoEpgSource = 'cfaas';
    }

    if (model.autoEpgSource === 'none') {
      model.autoEpgSource = '';
    }

    const transformModel: Partial<IChannel> = {
      ...model,
      autoScheduleDays: model.autoScheduleDays || 1,
    };

    // Save data to API here
    try {
      const result = await updateChannel({id, channel: transformModel, fields: Object.keys(dirtyFields)}).unwrap();
      Toast.success('Success', 'Channel Updated');
      setActiveChannel(result);
    } catch (e: any) {
      const errorMsg = e.data?.message ? errorMsgTransform(e.data.message) : '';
      Toast.error('Error', !!errorMsg ? errorMsg : 'Failed to save channel. Please try again', 8000);
    } finally {
      setIsSaving(false);
    }
  }, [id, model, dirtyFields, updateChannel]);

  React.useEffect(() => {
    // Fields we need to have special messaging for
    const checkedFields = ['duration', 'breakpoints', 'origin'];

    setHasFlaggedFields(some(checkedFields, field => dirtyFields[field]));
  }, [dirtyFields]);

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleArchiveOpen = React.useCallback(() => {
    setArchiveOpen(prev => !prev);
  }, []);

  // Handler for channel initial load or after save
  React.useEffect(() => {
    if (activeChannel) {
      const clonedChannel = cloneDeep(activeChannel);

      // See https://plutotv.atlassian.net/browse/CMS-3196
      const distributionInclude = clonedChannel.distribution?.include?.sort() || [];
      const distributionExclude = clonedChannel.distribution?.exclude?.sort() || [];
      const regionFilterInclude = (intersection(territoriesList, clonedChannel.regionFilter?.include) || []).sort();
      const regionFilterExclude = (intersection(territoriesList, clonedChannel.regionFilter?.exclude) || []).sort();

      clonedChannel.distribution = {
        include: distributionInclude,
        exclude: distributionExclude,
      };

      clonedChannel.regionFilter = {
        include: regionFilterInclude,
        exclude: regionFilterExclude,
      };

      if (clonedChannel?.gracenoteIntegration?.seriesType === '') {
        clonedChannel.gracenoteIntegration.seriesType = undefined;
      }

      if (clonedChannel.dmaDistribution) {
        clonedChannel.dmaDistribution = sortCodes(clonedChannel.dmaDistribution);
      }

      if (mainCategories && clonedChannel.categories) {
        clonedChannel.categories = mainCategories
          .filter((mc: IMainCategory) => clonedChannel.categories.find(ct => ct.catId === mc.id))
          .map((cat: IMainCategory) => {
            return {
              catId: cat.id,
              name: cat.name,
              order: cat.order,
            };
          });
      }

      setModel(clonedChannel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel, mainCategories]);

  const programHasChanges = React.useMemo(
    () => programFormState?.isValid && programFormState?.isDirty && programDirtyFields?.draft,
    [programDirtyFields?.draft, programFormState?.isDirty, programFormState?.isValid],
  );

  const cancelAttemptToLeave = React.useCallback(() => {
    setIsLeaving(false);
    return null;
  }, []);

  if (isChannelFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (isChannelError) {
    return <CrudError error={channelError} />;
  }

  return (
    <>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={saveData}
        hasFlaggedFields={hasFlaggedFields}
        ignoreHashChanges={true}
        isValid={formState.isValid}
      />
      <ConfirmDraftSaveModal
        isOpen={timelineGaps.length > 0 || timelineOverlaps.length > 0 || timelinePushing > 0}
        gaps={timelineGaps}
        overlaps={timelineOverlaps}
        pushingFuture={timelinePushing}
        reviewChanges={() => {
          setTimelineGaps([]);
          setTimelineOverlaps([]);
          setTimelinePushing(0);
          setIsPublishingDraft(false);
          pendingOperation.current = null;
        }}
        saveAndProceed={() => {
          setTimelineGaps([]);
          setTimelineOverlaps([]);
          setTimelinePushing(0);
          if (isPublishingDraft) {
            performSavePublishedCalendar();
          } else {
            performSaveDraftCalendar();
          }
        }}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel.name}
                </Heading>
                <Cluster align='flex-start' space='medium' wrap={false}>
                  <Cluster space='xxsmall' wrap={false}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Active Region:
                    </Heading>
                    <Heading level='h5' color='primary'>
                      {pristineModel.activeRegion?.toUpperCase()}
                    </Heading>
                  </Cluster>
                  <Heading level='h5' color='secondary'>
                    |
                  </Heading>
                  <Cluster space='xxsmall' wrap={false} fullWidth={true}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Main Categories:
                    </Heading>
                    <Heading level='h5' color='primary' truncate={true} truncateBackgroundHover='shadow'>
                      {model.categories
                        ?.filter(c => c.name)
                        ?.map(c => c.name)
                        ?.join(' , ')}
                    </Heading>
                  </Cluster>
                </Cluster>
              </Stack>

              <Cluster space='small' align='center' wrap={false}>
                <Popover
                  manualTrigger={true}
                  visible={archiveOpen}
                  onClickOutside={() => setArchiveOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      state={model.published ? 'disabled' : 'enabled'}
                      onClick={() => toggleArchiveOpen()}
                      permission={permissions.CHANNEL_EDIT}
                    >
                      {model.archived ? (
                        <Icon space='xxxsmall' icon='archive' color='neutral'>
                          Archived&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Icon>
                      ) : (
                        <Icon space='xxxsmall' icon='check' color='success'>
                          Not Archived
                        </Icon>
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='small'>
                      <Icon
                        space='xxxsmall'
                        icon='check'
                        color='success'
                        onClick={() => {
                          setFields({archived: false});
                          toggleArchiveOpen();
                        }}
                      >
                        Not Archived
                      </Icon>
                      <Icon
                        space='xxxsmall'
                        icon='archive'
                        color='neutral'
                        onClick={() => {
                          setFields({archived: true});
                          toggleArchiveOpen();
                        }}
                      >
                        Archived
                      </Icon>
                    </Stack>
                  </Template>
                </Popover>
                <Popover
                  manualTrigger={true}
                  visible={publishOpen}
                  onClickOutside={() => setPublishOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={permissions.CHANNEL_EDIT}
                    >
                      {model.published ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='small'>
                      <Status
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({published: false});
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({published: true, archived: false});
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
              </Cluster>
            </Cluster>
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              <Button
                active={activeTab === 'details'}
                onClick={() => history.push(programmingRoutes.paths.channelEditDetailsPage.replace(':id', id))}
              >
                Details
              </Button>
              <Button
                active={activeTab === 'distribution'}
                onClick={() => history.push(programmingRoutes.paths.channelEditDistributionPage.replace(':id', id))}
              >
                Distribution
              </Button>
              <Button
                active={activeTab === 'settings'}
                onClick={() => history.push(programmingRoutes.paths.channelEditSettingsPage.replace(':id', id))}
              >
                Settings
              </Button>
              <Button
                active={activeTab === 'artwork'}
                onClick={() => history.push(programmingRoutes.paths.channelEditArtworkPage.replace(':id', id))}
              >
                Artwork
              </Button>
              {ableTo('CHANNEL_CATALOG_VIEW') && (
                <Button
                  active={activeTab === 'catalog'}
                  onClick={() => history.push(programmingRoutes.paths.channelEditCatalogPage.replace(':id', id))}
                >
                  Catalog
                </Button>
              )}
              {ableTo('CHANNEL_EPG_VIEW') && (
                <Button
                  active={activeTab === 'program'}
                  onClick={() => history.push(programmingRoutes.paths.channelEditProgramPage.replace(':id', id))}
                >
                  Program
                </Button>
              )}
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'details' && (
            <ChannelDetails
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'distribution' && (
            <ChannelDistribution
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'settings' && (
            <ChannelSettings
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              setIsGracenoteFormValid={setIsGracenoteFormValid}
              setIsCfaasFormValid={setIsCfaasFormValid}
            />
          )}
          {activeTab === 'artwork' && (
            <ChannelArtwork
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          <MemoryQueueProvider>
            <MemoryQueueApiProvider channelId={model.id}>
              {activeTab === 'catalog' && <ChannelCatalog model={model} setFields={setFields} />}
              {activeTab === 'program' && (
                <>
                  <ChannelProgram
                    model={model}
                    programModel={programModel}
                    onGapsChange={setGapsState}
                    programHasChanges={programHasChanges!}
                    setProgramFields={programSetFields}
                    setProgramModel={programSetModel}
                    onClickSaveHandler={saveDraftCalendar}
                    attemptToLeave={checkBeforeSaveDraftCalendar}
                    cancelAttemptToLeave={cancelAttemptToLeave}
                    isDraft={isDraft}
                    lastProgrammingUpdate={lastProgrammingUpdate}
                    setGapChecksPending={setGapChecksPending}
                    isChannelDraftDiscarded={isChannelDraftDiscarded}
                    isDiscardTimelineDraftsLoading={isDiscardTimelineDraftsLoading}
                  />
                </>
              )}
            </MemoryQueueApiProvider>
          </MemoryQueueProvider>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            {activeTab !== 'program' && (
              <Cluster justify='space-between'>
                <div></div>
                <Cluster space='xxxsmall'>
                  <Button ghost={true} onClick={cancelHandler} id='discard'>
                    Discard
                  </Button>
                  <Button
                    type='primary'
                    state={
                      !formState.isValid || !formState.isDirty || !isGracenoteFormValid || !isCfaasFormValid
                        ? 'disabled'
                        : isSaving
                        ? 'thinking'
                        : ''
                    }
                    onClick={saveData}
                    id='save'
                    permission={permissions.CHANNEL_EDIT}
                  >
                    Save Changes
                  </Button>
                </Cluster>
              </Cluster>
            )}
            {activeTab === 'program' && (
              <Cluster justify='space-between'>
                {/* The folowing true/false calls will need to be toggled based on if the calendar is in a Draft/Published state. */}
                {/* In the following true is showing a Draft state and false hold the Published UI */}
                {isDraft && (
                  <>
                    <Box marginTop='xxsmall'>
                      {!hasIntegrationEnabled && (
                        <Popover
                          manualTrigger={true}
                          visible={clearWeekVisible && ableTo('CHANNEL_EPG_EDIT')}
                          appendToBody={true}
                          onClickOutside={() => {
                            setClearWeekVisible(false);
                          }}
                          allowedPlacements={['top-start']}
                          state={ableTo('CHANNEL_EPG_EDIT') ? 'enabled' : 'disabled'}
                        >
                          <Template label='trigger'>
                            <Paragraph size='small'>
                              <Icon
                                size='small'
                                icon='delete'
                                color='delete'
                                textColor='delete'
                                hoverColor='deleteLight'
                                space='xxxsmall'
                                onClick={() => toggleClearWeekVisible()}
                                disabled={!ableTo('CHANNEL_EPG_EDIT')}
                              >
                                CLEAR WEEK
                              </Icon>
                            </Paragraph>
                          </Template>
                          <Template label='popover'>
                            <DeleteConfirmation
                              message={'Are you sure clear the week out completely?'}
                              cancelButtonFunction={() => {
                                setClearWeekVisible(false);
                              }}
                              proceedButtonFunction={() => {
                                clearWeek();
                              }}
                            />
                          </Template>
                        </Popover>
                      )}
                    </Box>
                    <Cluster space='xxxsmall'>
                      {!hasIntegrationEnabled && (
                        <Popover
                          manualTrigger={true}
                          visible={discardDraftVisible && ableTo('CHANNEL_EPG_EDIT')}
                          appendToBody={true}
                          onClickOutside={() => {
                            setDiscardDraftVisible(false);
                          }}
                          state={ableTo('CHANNEL_EPG_EDIT') ? 'enabled' : 'disabled'}
                          allowedPlacements={['top-start', 'top-end']}
                          maxWidth='24.75rem'
                        >
                          <Template label='trigger'>
                            <Button
                              ghost={true}
                              onClick={() => setDiscardDraftVisible(true)}
                              id='discard'
                              state={
                                isDiscardTimelineDraftsLoading || !ableTo('CHANNEL_EPG_EDIT') || isSaving
                                  ? 'disabled'
                                  : ''
                              }
                            >
                              Discard Draft
                            </Button>
                          </Template>

                          <Template label='popover'>
                            <DeleteConfirmation
                              message={
                                'Are you sure you want to wipe the draft schedule? It will be replaced with the current published timelines.'
                              }
                              cancelButtonFunction={() => {
                                setDiscardDraftVisible(false);
                              }}
                              proceedButtonFunction={handleDiscardDraft}
                            />
                          </Template>
                        </Popover>
                      )}
                      <Button
                        type='secondary'
                        onClick={() => setIsDraft(false)}
                        id='viewPublished'
                        permission={permissions.CHANNEL_EPG_VIEW}
                      >
                        View Published Calendar
                      </Button>
                      {!hasIntegrationEnabled && (
                        <Button
                          type='secondary'
                          state={
                            !programHasChanges || isDiscardTimelineDraftsLoading || !ableTo('CHANNEL_EPG_EDIT')
                              ? 'disabled'
                              : isSaving || gapChecksPending
                              ? 'thinking'
                              : ''
                          }
                          onClick={async () => {
                            try {
                              await saveDraftCalendar();
                            } catch (e) {}
                          }}
                          id='saveDraft'
                        >
                          Save Draft
                        </Button>
                      )}
                      {!hasIntegrationEnabled && (
                        <Button
                          type='primary'
                          state={
                            programHasChanges || isDiscardTimelineDraftsLoading || !ableTo('CHANNEL_EPG_EDIT')
                              ? 'disabled'
                              : isSaving
                              ? 'thinking'
                              : ''
                          }
                          onClick={() => savePublishedCalendar()}
                          id='punlishDraft'
                        >
                          Publish Draft
                        </Button>
                      )}
                    </Cluster>
                  </>
                )}
                {!isDraft && (
                  <>
                    <div></div>
                    <Button
                      type='secondary'
                      onClick={() => setIsDraft(true)}
                      id='viewDraft'
                      permission={permissions.CHANNEL_EPG_VIEW}
                    >
                      View Draft Calendar
                    </Button>
                  </>
                )}
              </Cluster>
            )}
          </Box>
        </Template>
      </Cover>
    </>
  );
};
