import {Box, Button, Cluster, DateTime, FormItem, Stack, States, TextInput} from '@pluto-tv/assemble';
import {popoverActionsProps} from 'helpers/popoverActionProps';
import {isNumber} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import * as React from 'react';

export interface IAvailabilityWindowFormProps {
  onCancel: () => void;
  onSave: (savedObj: IAvailbilityWindow) => void;
  value?: Partial<IAvailbilityWindow>;
}

export interface IAvailbilityWindow {
  _id?: string;
  screenings: number;
  startDate: string | Date;
  endDate: string | Date;
}

const AvailabilityWindowForm = React.memo(({onCancel, onSave, value}: IAvailabilityWindowFormProps) => {
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const [screenings, setScreenings] = React.useState<number>();

  const [formState, setFormState] = React.useState<States>('disabled');
  const [endWarning, setEndWarning] = React.useState<string>();
  const [screenWarning, setScreenWarning] = React.useState<string>();
  const [isUpdating, setIsUpdating] = React.useState<boolean>();

  React.useEffect(() => {
    const now = new Date();

    if (value) {
      const startDayISO = luxon.fromISO(value.startDate as string).toISODate();
      const endDayISO = luxon.fromISO(value.endDate as string).toISODate();

      setStartDate(value.startDate ? luxon.fromISO(startDayISO).toJSDate() : now);
      setEndDate(value.endDate ? luxon.fromISO(endDayISO).toJSDate() : now);
      setScreenings(value.screenings);
      setIsUpdating(true);
    } else {
      setStartDate(now);
      setEndDate(now);
      setScreenings(undefined);
      setIsUpdating(false);
    }
  }, [value]);

  React.useEffect(() => {
    if (!endDate || !startDate) {
      setFormState('disabled');
      return;
    }

    if (!isNumber(screenings)) {
      setFormState('disabled');
      setScreenWarning('Screenings must be a number');
      return;
    } else if (isNumber(screenings) && screenings < -1) {
      setFormState('disabled');
      setScreenWarning('Screenings cannot be lower than -1');
      return;
    }

    if (endDate && startDate) {
      const luxonStart = luxon.fromJSDate(startDate);
      const luxonEnd = luxon.fromJSDate(endDate);

      if (luxonStart.startOf('day').toMillis() > luxonEnd.endOf('day').toMillis()) {
        setFormState('disabled');
        setEndWarning('End date must be after start date');
        return;
      }
    }

    setScreenWarning(undefined);
    setEndWarning(undefined);
    setFormState('');
  }, [endDate, startDate, screenings]);

  const updateSaveWindow = () => {
    if (!endDate || !startDate || !isNumber(screenings) || (isNumber(screenings) && screenings < -1)) {
      return;
    }

    // Since we're saving everything to PDT, we need to strip the browser TZ info and
    // just start with the date from the picker. We'll parse that as PDT in the next step
    const startDayISO = luxon.fromJSDate(startDate).toISODate();
    const endDayISO = luxon.fromJSDate(endDate).toISODate();

    onSave({
      endDate: luxon.fromISO(endDayISO, {zone: 'America/Los_Angeles'}).endOf('day').toISO(),
      startDate: luxon.fromISO(startDayISO, {zone: 'America/Los_Angeles'}).startOf('day').toISO(),
      screenings: screenings!,
    });
  };

  return (
    <Box
      padding={popoverActionsProps.padding}
      background={popoverActionsProps.background}
      width={popoverActionsProps.width}
    >
      <Stack space='small'>
        <FormItem label='Start Date'>
          <DateTime
            placeholder='Start Date'
            id='linearAvailWindowStartTime'
            value={startDate}
            onChange={val => setStartDate(val as Date)}
          />
        </FormItem>
        <FormItem label='End Date' helpText={endWarning} state={endWarning ? 'error' : ''}>
          <DateTime
            placeholder='End Date'
            id='linearAvailWindowEndTime'
            value={endDate}
            onChange={val => setEndDate(val as Date)}
          />
        </FormItem>
        <FormItem label='Screenings' helpText={screenWarning} state={screenWarning ? 'error' : ''}>
          <TextInput
            id='linearAvailWindowScreening'
            type='number'
            value={screenings}
            onChange={val => setScreenings(val)}
          />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={onCancel}>
              Cancel
            </Button>
            <Button id='saveLinearAvailableWindow' state={formState} type='primary' onClick={updateSaveWindow}>
              {!isUpdating ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
});

AvailabilityWindowForm.displayName = 'AvailabilityWindowForm';
export default AvailabilityWindowForm;
