import * as React from 'react';
import {
  Box,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  FormItem,
  Grid,
  Select,
  Spinner,
  Stack,
  TagList,
  TextInput,
  Toggle,
  ISelectOption,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';

import CrudError from 'components/crudError';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';

import {INestedChannelSettingsProps} from '../nestedPropsInterface';
import {orderBy, sortBy} from 'lodash-es';
import {IAutoEpgSource, ICfaasIntegration, IGracenoteIntegration} from 'models/channels';
import {
  channelCfaasIntegrationValidator,
  channelGracenoteIntegrationValidator,
} from 'views/programming/channel/validators';

export default ({
  model,
  setFields,
  onBlur,
  onChange,
  form,
  setIsGracenoteFormValid,
  setIsCfaasFormValid,
  pristineModel,
}: INestedChannelSettingsProps): JSX.Element => {
  const [seoAliasesList, setSeoAliasesList] = React.useState<ISelectOption[]>([]);
  const [subgenresOptions, setSubgenresOptions] = React.useState<ISelectOption[]>([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = React.useState<ISelectOption[]>([]);
  const [integrationType, setIntegrationType] = React.useState<IAutoEpgSource | undefined>(model.autoEpgSource);

  const {data: genres, isFetching: isFetchingGenres, isError: isErrorGenres} = useFindGenresQuery();
  const {data: categories, isFetching: isFetchingCategories, isError: isErrorCategories} = useFindCategoriesQuery();

  const {
    form: gracenoteForm,
    model: gracenoteModel,
    state: gracenoteFormState,
    setModel: gracenoteISetModel,
    setFields: gracenoteSetFields,
    pristineModel: gracenotePristineModel,
  } = useValidateForm<IGracenoteIntegration>(channelGracenoteIntegrationValidator, 'immediate');

  const {
    form: cfaasForm,
    model: cfaasModel,
    state: cfaasFormState,
    setModel: cfaasISetModel,
    setFields: cfaasSetFields,
    pristineModel: cfaasPristineModel,
  } = useValidateForm<ICfaasIntegration>(channelCfaasIntegrationValidator, 'immediate');
  enum IntegrationType {
    None = 'None',
    Gracenote = 'Gracenote Live Linear',
    CFAAS = 'CFAAS Live Linear',
  }

  const dataIntegrationType: ISelectOption[] = [
    {value: 'none', label: IntegrationType.None},
    {value: 'gracenote', label: IntegrationType.Gracenote},
    {value: 'cfaas', label: IntegrationType.CFAAS},
  ];

  React.useEffect(() => {
    if (seoAliasesList.length < (model.seoAliases || []).length) {
      const list = handleFilteredList(model.seoAliases || []);
      setSeoAliasesList(list);
    }
  }, [model.seoAliases, seoAliasesList.length]);

  React.useEffect(() => {
    gracenoteISetModel(pristineModel.gracenoteIntegration || {});
  }, [gracenoteISetModel, pristineModel.gracenoteIntegration]);

  React.useEffect(() => {
    cfaasISetModel(pristineModel.cfaasIntegration || {});
  }, [cfaasISetModel, pristineModel.cfaasIntegration]);

  React.useEffect(() => {
    if (model.autoSchedule && !model.episodesQueue?.length) {
      Toast.error(
        'One or more episodes must be available in the Channel Queue in order to enable the Auto Scheduling feature.',
      );
      setIsGracenoteFormValid(false);
      setIsCfaasFormValid(false);
    }
    setIsGracenoteFormValid(gracenoteFormState.isValid);
    setIsCfaasFormValid(cfaasFormState.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.autoSchedule, setIsGracenoteFormValid, setIsCfaasFormValid]);

  React.useEffect(() => {
    if (gracenotePristineModel.enabled !== gracenoteModel.enabled) {
      // Setting values in case some of them are undefined to ensure the validations to run
      const gracenoteIntegration: IGracenoteIntegration = {
        enabled: !!gracenoteModel.enabled,
        dynamicClip: gracenoteModel.dynamicClip ? gracenoteModel.dynamicClip : gracenoteModel.enabled ? '' : undefined,
        genre: gracenoteModel.genre ? gracenoteModel.genre : gracenoteModel.enabled ? '' : undefined,
        subGenre: gracenoteModel.subGenre ? gracenoteModel.subGenre : gracenoteModel.enabled ? '' : undefined,
        category: gracenoteModel.category ? gracenoteModel.category : gracenoteModel.enabled ? '' : undefined,
        subCategory: gracenoteModel.subCategory ? gracenoteModel.subCategory : gracenoteModel.enabled ? '' : undefined,
        seriesType: gracenoteModel.enabled ? 'live' : undefined,
      };

      setFields({gracenoteIntegration});
      gracenoteSetFields(gracenoteIntegration);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gracenoteModel.enabled, gracenotePristineModel]);

  React.useEffect(() => {
    if (cfaasPristineModel.enabled !== cfaasModel.enabled) {
      // Setting values in case some of them are undefined to ensure the validations to run
      const cfaasIntegration: ICfaasIntegration = {
        enabled: !!cfaasModel.enabled,
        clipId: cfaasModel.clipId ? cfaasModel.clipId : cfaasModel.enabled ? '' : undefined,
        genre: cfaasModel.genre ? cfaasModel.genre : cfaasModel.enabled ? '' : undefined,
        subGenre: cfaasModel.subGenre ? cfaasModel.subGenre : cfaasModel.enabled ? '' : undefined,
        category: cfaasModel.category ? cfaasModel.category : cfaasModel.enabled ? '' : undefined,
        subCategory: cfaasModel.subCategory ? cfaasModel.subCategory : cfaasModel.enabled ? '' : undefined,
        sourceId: {
          key: cfaasModel.sourceId?.key ? cfaasModel.sourceId?.key : cfaasModel.enabled ? '' : undefined,
          value: cfaasModel.sourceId?.value ? cfaasModel.sourceId?.value : cfaasModel.enabled ? '' : undefined,
        },
      };

      setFields({cfaasIntegration});
      cfaasSetFields(cfaasIntegration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfaasModel.enabled, cfaasPristineModel]);

  React.useEffect(() => {
    if (gracenoteModel.enabled && cfaasModel.enabled) {
      Toast.error('Gracenote and CFAAS integrations cannot be enabled at the same time');
    }
    if (gracenoteModel.enabled && model.autoSchedule) {
      Toast.error('Auto Schedule and Gracenote integration cannot be enabled at the same time.');
    }
    if (cfaasModel.enabled && model.autoSchedule) {
      Toast.error('Auto Schedule and CFAAS integration cannot be enabled at the same time.');
    }
  }, [gracenoteModel.enabled, cfaasModel.enabled, model.autoSchedule]);

  React.useEffect(() => {
    if (integrationType === 'gracenote' && gracenoteModel.genre && genres?.length) {
      setSubgenresOptions(
        orderBy(
          genres.find(g => g.genre === gracenoteModel.genre)?.subGenres.map(subg => ({label: subg})) || [],
          'label',
        ),
      );
    }
  }, [gracenoteModel.genre, genres, integrationType]);

  React.useEffect(() => {
    if (integrationType === 'cfaas' && cfaasModel.genre && genres?.length) {
      setSubgenresOptions(
        orderBy(genres.find(g => g.genre === cfaasModel.genre)?.subGenres.map(subg => ({label: subg})) || [], 'label'),
      );
    }
  }, [cfaasModel.genre, genres, integrationType]);

  React.useEffect(() => {
    if (integrationType === 'gracenote' && gracenoteModel.category && categories?.length) {
      setSubCategoriesOptions(
        orderBy(
          categories.find(g => g.category === gracenoteModel.category)?.subCategories.map(subc => ({label: subc})) ||
            [],
          'label',
        ),
      );
    }
  }, [gracenoteModel.category, categories, integrationType]);

  React.useEffect(() => {
    if (integrationType === 'cfaas' && cfaasModel.category && categories?.length) {
      setSubCategoriesOptions(
        orderBy(
          categories.find(g => g.category === cfaasModel.category)?.subCategories.map(subc => ({label: subc})) || [],
          'label',
        ),
      );
    }
  }, [cfaasModel.category, categories, integrationType]);

  React.useMemo(() => {
    setIsGracenoteFormValid(!(gracenoteModel.enabled && model.autoSchedule) && gracenoteFormState.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gracenoteFormState.isValid]);

  React.useMemo(() => {
    setIsCfaasFormValid(!(cfaasModel.enabled && model.autoSchedule) && cfaasFormState.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfaasFormState.isValid]);

  React.useMemo(() => {
    const multipleIntegrationsEnabled = !(cfaasModel.enabled && gracenoteModel.enabled);
    setIsCfaasFormValid(multipleIntegrationsEnabled);
    setIsGracenoteFormValid(multipleIntegrationsEnabled);
  }, [cfaasModel.enabled, gracenoteModel.enabled, setIsCfaasFormValid, setIsGracenoteFormValid]);

  const handleOnChangeGracenote = (items: IGracenoteIntegration) => {
    gracenoteSetFields(items as Partial<IGracenoteIntegration>);
    setFields({
      gracenoteIntegration: {...model.gracenoteIntegration, ...(items as Partial<IGracenoteIntegration>)},
    });
  };

  const handleOnChangeCfaas = (items: ICfaasIntegration) => {
    cfaasSetFields(items as Partial<ICfaasIntegration>);
    setFields({
      cfaasIntegration: {...model.cfaasIntegration, ...(items as Partial<ICfaasIntegration>)},
    });
  };

  React.useEffect(() => {
    // integrationType initialization when model is available
    if (integrationType === undefined && model && model.id) {
      if (model.autoEpgSource && (model.autoEpgSource === 'cfaas' || model.autoEpgSource === 'gracenote')) {
        setIntegrationType(model.autoEpgSource);
      } else if (model.gracenoteIntegration?.enabled) {
        setIntegrationType('gracenote');
      } else if (model.cfaasIntegration?.enabled) {
        setIntegrationType('cfaas');
      } else {
        setIntegrationType('none');
      }
    }
  }, [model, integrationType]);

  const handleOnChangeIntegrationType = (integration: ISelectOption) => {
    // When change, disable the other Integration to avoid validation conflicts
    const {value} = integration;
    setIntegrationType(value as IAutoEpgSource);
    setFields({
      autoEpgSource: value === 'none' && !pristineModel.autoEpgSource ? undefined : value,
    });

    if (value === 'none') {
      if (model.gracenoteIntegration && model.gracenoteIntegration.enabled) {
        handleOnChangeGracenote({
          enabled: false,
        });
      }
      if (model.cfaasIntegration && model.cfaasIntegration.enabled) {
        handleOnChangeCfaas({
          enabled: false,
        });
      }
    }

    if (value === 'gracenote') {
      handleOnChangeGracenote({
        seriesType: gracenotePristineModel.enabled ? 'live' : undefined,
      });
    }
  };

  const getOptionByValue = (value: string) => dataIntegrationType.find(option => option.value === value);
  const integrationTypeValue = getOptionByValue(integrationType || 'none') || {value: '', label: ''};

  const handleFilteredList = (seoAliasesArray: string[]) => {
    const list = seoAliasesArray.map((el: any, index) => ({label: el, value: el, order: index}));
    return sortBy(list, 'label');
  };

  const {
    // ableTo,
    permissions,
  } = useAppPermissions();

  if (isErrorGenres || isErrorCategories) {
    return <CrudError error='Error loading page data' />;
  }

  if (isFetchingGenres || isFetchingCategories) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Auto Scheduling'>
          <Cluster space='xxxlarge'>
            <FormItem {...form.autoSchedule} child='Toggle' permission={permissions.CHANNEL_EDIT}>
              <Toggle
                label='Yes'
                onChange={value => {
                  if (value) {
                    if (gracenoteModel.enabled) {
                      Toast.error('Auto Schedule and Gracenote integration cannot be enabled at the same time.');
                    }
                    if (cfaasModel.enabled) {
                      Toast.error('Auto Schedule and CFAAS integration cannot be enabled at the same time.');
                    }
                    handleOnChangeGracenote({
                      enabled: false,
                    });
                    handleOnChangeCfaas({
                      enabled: false,
                    });
                  }
                  onChange('autoSchedule', value);
                }}
                value={model.autoSchedule}
                id='autoScheduleToogle'
              />
            </FormItem>
            <FormItem
              {...form.autoScheduleDays}
              required={model.autoSchedule}
              onBlur={() => onBlur('autoScheduleDays')}
              permission={permissions.CHANNEL_EDIT}
            >
              <TextInput
                id='autoScheduleDays'
                type='number'
                onChange={value => {
                  onChange('autoScheduleDays', value);
                }}
                value={model.autoScheduleDays}
                maxLength={2}
              />
            </FormItem>
          </Cluster>
        </ContentBox>
        <ContentBox title='Configuration'>
          <Stack space='medium'>
            <FormItem {...form.seoAliases} permission={permissions.CHANNEL_EDIT}>
              <TagList
                value={model.seoAliases}
                onChange={value =>
                  setFields({
                    seoAliases: orderBy([...new Set(value || [])] as string[], seoAliases => seoAliases.toLowerCase()),
                  })
                }
                id='seoAliases'
              />
            </FormItem>
            <FormItem {...form.tags} permission={permissions.CHANNEL_EDIT}>
              <TagList
                value={model.tags}
                onChange={value =>
                  setFields({tags: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase())})
                }
                id='tags'
              />
            </FormItem>
            <Stack space='xlarge'>
              <FormItem
                {...form.logoOverlayEnable}
                label='Channel Logo Overlay'
                child='Toggle'
                permission={permissions.CHANNEL_EDIT}
                helpTextColor='info'
                helpText='Positions the channel logo as an overlay on the client app. when enabled'
              >
                <Toggle
                  label='Yes'
                  onChange={value => onChange('logoOverlayEnable', value)}
                  value={model.logoOverlayEnable}
                  id='logoOverlayEnable'
                />
              </FormItem>
              <FormItem
                {...form.googleDai}
                label='Google DAI + AES 128 Enabled'
                child='Toggle'
                permission={permissions.CHANNEL_EDIT}
              >
                <Toggle
                  label='Yes'
                  onChange={value => onChange('googleDai', value)}
                  value={model.googleDai}
                  id='googleDai'
                />
              </FormItem>
            </Stack>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Auto Publishing'>
          <Stack space='xxlarge'>
            <Stack space='xlarge'>
              <Stack space='small'>
                <Grid maxCols={2} rowGap='small' columnGap='xlarge'>
                  <FormItem label='Integration Type'>
                    <Select
                      options={dataIntegrationType}
                      id='autoPublishingIntegrationType'
                      onChange={value => handleOnChangeIntegrationType(value)}
                      value={integrationTypeValue}
                      placeholder='Select Integration Type'
                    />
                  </FormItem>
                </Grid>
                {!!integrationType &&
                  integrationType !== 'none' &&
                  (integrationType === 'gracenote' ? (
                    <>
                      <Cluster space='xxxlarge'>
                        <FormItem
                          {...gracenoteForm.enabled}
                          child='Toggle'
                          permission={permissions.CHANNEL_EDIT}
                          state={cfaasModel.enabled && gracenoteModel.enabled ? 'error' : undefined}
                        >
                          <Toggle
                            label='Yes'
                            onChange={val => {
                              if (val) {
                                onChange('autoSchedule', false);
                              }
                              handleOnChangeGracenote({enabled: val});
                            }}
                            value={gracenoteModel.enabled}
                            id='gracenoteToogle'
                          />
                        </FormItem>
                        <FormItem {...form.tmsid}>
                          <TextInput
                            id='tmsid'
                            value={model.tmsid}
                            onChange={value => onChange('tmsid', value)}
                            placeholder='Enter Channel PID'
                          />
                        </FormItem>
                      </Cluster>

                      <Stack space='small'>
                        <FormItem
                          {...gracenoteForm.dynamicClip}
                          required={gracenoteModel.enabled}
                          permission={permissions.CHANNEL_EDIT}
                        >
                          <TextInput
                            id='dynamicClip'
                            value={gracenoteModel.dynamicClip}
                            onChange={val => handleOnChangeGracenote({dynamicClip: val})}
                          />
                        </FormItem>
                        <Grid maxCols={2} rowGap='small' columnGap='xlarge'>
                          <FormItem
                            {...gracenoteForm.genre}
                            required={gracenoteModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={orderBy(
                                (genres || []).map(g => ({label: g.genre})),
                                'label',
                              )}
                              onChange={value => handleOnChangeGracenote({genre: value.label, subGenre: ''})}
                              value={{label: gracenoteModel.genre || ''}}
                              placeholder='Select Genre'
                            />
                          </FormItem>
                          <FormItem
                            {...gracenoteForm.subGenre}
                            required={gracenoteModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={subgenresOptions}
                              onChange={value => handleOnChangeGracenote({subGenre: value.label})}
                              value={{label: gracenoteModel.subGenre || ''}}
                              placeholder='Select Sub-Genre'
                            />
                          </FormItem>
                          <FormItem
                            {...gracenoteForm.category}
                            required={gracenoteModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={orderBy(
                                (categories || []).map(g => ({label: g.category})),
                                'label',
                              )}
                              placeholder='Select Category'
                              value={{label: gracenoteModel.category || ''}}
                              onChange={value => handleOnChangeGracenote({category: value.label, subCategory: ''})}
                            />
                          </FormItem>
                          <FormItem
                            {...gracenoteForm.subCategory}
                            required={gracenoteModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={subCategoriesOptions}
                              onChange={value => handleOnChangeGracenote({subCategory: value.label})}
                              placeholder='Select Sub-Category'
                              value={{label: gracenoteModel.subCategory || ''}}
                            />
                          </FormItem>
                        </Grid>
                      </Stack>
                    </>
                  ) : (
                    // CFAAS Integration
                    <>
                      <Cluster space='xxxlarge'>
                        <FormItem
                          {...cfaasForm.enabled}
                          child='Toggle'
                          permission={permissions.CHANNEL_EDIT}
                          state={cfaasModel.enabled && gracenoteModel.enabled ? 'error' : undefined}
                        >
                          <Toggle
                            label='Yes'
                            onChange={val => {
                              if (val) {
                                onChange('autoSchedule', false);
                              }
                              handleOnChangeCfaas({enabled: val});
                            }}
                            value={cfaasModel.enabled}
                            id='cfaasToogle'
                          />
                        </FormItem>
                        <FormItem label='Channel Source ID Key'>
                          <TextInput
                            id='sourceIdKey'
                            value={cfaasModel.sourceId?.key}
                            onChange={val =>
                              handleOnChangeCfaas({
                                sourceId: {
                                  ...cfaasModel.sourceId,
                                  key: val,
                                },
                              })
                            }
                            placeholder='Enter Source ID'
                          />
                        </FormItem>
                        <FormItem label='Channel Source Value'>
                          <TextInput
                            id='sourceIdValue'
                            value={cfaasModel.sourceId?.value}
                            onChange={val =>
                              handleOnChangeCfaas({
                                sourceId: {
                                  ...cfaasModel.sourceId,
                                  value: val,
                                },
                              })
                            }
                            placeholder='Enter Value'
                          />
                        </FormItem>
                      </Cluster>
                      <Stack space='small'>
                        <FormItem
                          {...cfaasForm.clipId}
                          required={cfaasModel.enabled}
                          permission={permissions.CHANNEL_EDIT}
                        >
                          <TextInput
                            id='clipId'
                            value={cfaasModel.clipId}
                            onChange={val => handleOnChangeCfaas({clipId: val})}
                          />
                        </FormItem>
                        <Grid maxCols={2} rowGap='small' columnGap='xlarge'>
                          <FormItem
                            {...cfaasForm.genre}
                            required={cfaasModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={orderBy(
                                (genres || []).map(g => ({label: g.genre})),
                                'label',
                              )}
                              onChange={value => handleOnChangeCfaas({genre: value.label, subGenre: ''})}
                              value={{label: cfaasModel.genre || ''}}
                              placeholder='Select Genre'
                            />
                          </FormItem>
                          <FormItem
                            {...cfaasForm.subGenre}
                            required={cfaasModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={subgenresOptions}
                              onChange={value => handleOnChangeCfaas({subGenre: value.label})}
                              value={{label: cfaasModel.subGenre || ''}}
                              placeholder='Select Sub-Genre'
                            />
                          </FormItem>
                          <FormItem
                            {...cfaasForm.category}
                            required={cfaasModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={orderBy(
                                (categories || []).map(g => ({label: g.category})),
                                'label',
                              )}
                              placeholder='Select Category'
                              value={{label: cfaasModel.category || ''}}
                              onChange={value => handleOnChangeCfaas({category: value.label, subCategory: ''})}
                            />
                          </FormItem>
                          <FormItem
                            {...cfaasForm.subCategory}
                            required={cfaasModel.enabled}
                            permission={permissions.CHANNEL_EDIT}
                          >
                            <Select
                              options={subCategoriesOptions}
                              onChange={value => handleOnChangeCfaas({subCategory: value.label})}
                              placeholder='Select Sub-Category'
                              value={{label: cfaasModel.subCategory || ''}}
                            />
                          </FormItem>
                        </Grid>
                      </Stack>
                    </>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
