import * as React from 'react';
import ChannelList from 'components/channelList';
import ChannelEditPage from 'views/programming/channel/edit';
import VodCollectionList from 'components/vodCollectionList';
import VodCollectionEditPage from 'views/programming/vodCollections/edit';
import FeaturedGroupList from 'components/featuredGroupList';
import FeaturedGroupEditPage from 'views/programming/featuredGroups/edit';
import HubList from 'components/hubList';
import HubEditPage from 'views/programming/hubManager/hubs/edit';
import CarouselList from 'components/carouselList';
import CarouselEditPage from 'views/programming/hubManager/carousels/edit';
import {channelPermissions} from 'views/programming/channel/permissions';
import {vodCollectionPermissions} from 'views/programming/vodCollections/permissions';
import {featuredGroupsPermissions} from 'views/programming/featuredGroups/permissions';
import {hubManagerPermissions} from 'views/programming/hubManager/permissions';

import {IRouteItem} from 'routes';

const paths = {
  channelListPage: '/channels',
  channelEditDetailsPageAlt: '/channels/:id',
  channelEditDetailsPage: '/channels/:id/details',
  channelEditDistributionPage: '/channels/:id/distribution',
  channelEditSettingsPage: '/channels/:id/settings',
  channelEditArtworkPage: '/channels/:id/artwork',
  channelEditCatalogPage: '/channels/:id/catalog',
  channelEditProgramPage: '/channels/:id/program',
  vodCollectionListPage: '/vod-collections',
  vodCollectionEditDetailsPageAlt: '/vod-collections/:id',
  vodCollectionEditDetailsPage: '/vod-collections/:id/details',
  vodCollectionEditDistributionPage: '/vod-collections/:id/distribution',
  vodCollectionEditArtworkPage: '/vod-collections/:id/artwork',
  vodCollectionEditProgramPage: '/vod-collections/:id/program',
  featuredGroupListPage: '/featured-groups',
  featuredGroupEditDetailsPageAlt: '/featured-groups/:id',
  featuredGroupEditDetailsPage: '/featured-groups/:id/details',
  hubManagerPage: '/hub-manager',
  hubEditPreviewPageAlt: '/hub-manager/hub/:id',
  hubEditPreviewPage: '/hub-manager/hub/:id/preview',
  hubEditSettingsPage: '/hub-manager/hub/:id/settings',
  carouselListPage: '/hub-manager/carousel',
  carouselEditDetailsPageAlt: '/hub-manager/carousel/:id',
  carouselEditDetailsPage: '/hub-manager/carousel/:id/details',
};

const routes: IRouteItem[] = [
  {
    name: 'Programming',
    icon: 'programming',
    showInSideBar: true,
    requiredPermissions: [...channelPermissions.CHANNEL_VIEW.enabled, ...vodCollectionPermissions.VOD_VIEW.enabled],
    children: [
      {
        name: 'Channels',
        path: paths.channelListPage,
        component: () => <ChannelList showFavoriteSearch={true} />,
        breadcrumb: {items: [{text: 'Channels', route: paths.channelListPage}]},
        requiredPermissions: [...channelPermissions.CHANNEL_VIEW.enabled],
      },
      {
        name: 'VOD Collections',
        path: paths.vodCollectionListPage,
        component: VodCollectionList,
        breadcrumb: {items: [{text: 'VOD Collections', route: paths.vodCollectionListPage}]},
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
      {
        name: 'Featured Groups',
        path: paths.featuredGroupListPage,
        component: FeaturedGroupList,
        breadcrumb: {items: [{text: 'Featured Groups', route: paths.featuredGroupListPage}]},
        requiredPermissions: featuredGroupsPermissions.FEATURED_GROUPS_VIEW.enabled,
      },
      {
        name: 'Hub Manager',
        path: paths.hubManagerPage,
        component: HubList,
        breadcrumb: {items: [{text: 'Hub Manager', route: paths.hubManagerPage}]},
        requiredPermissions: hubManagerPermissions.HUB_VIEW.enabled,
      },
    ],
  },
  {
    name: 'Channel Edit',
    children: [
      {
        name: 'Channel Edit Detail',
        path: paths.channelEditDetailsPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Details', route: paths.channelEditDetailsPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_VIEW.enabled,
      },
      {
        name: 'Channel Edit Detail',
        path: paths.channelEditDetailsPageAlt,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Details', route: paths.channelEditDetailsPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_VIEW.enabled,
      },
      {
        name: 'Channel Edit Distribution',
        path: paths.channelEditDistributionPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Distribution', route: paths.channelEditDistributionPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_VIEW.enabled,
      },
      {
        name: 'Channel Edit Settings',
        path: paths.channelEditSettingsPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Settings', route: paths.channelEditSettingsPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_VIEW.enabled,
      },
      {
        name: 'Channel Edit Artwork',
        path: paths.channelEditArtworkPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Artwork', route: paths.channelEditArtworkPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_VIEW.enabled,
      },
      {
        name: 'Channel Edit Catalog',
        path: paths.channelEditCatalogPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Catalog', route: paths.channelEditCatalogPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_CATALOG_VIEW.enabled,
      },
      {
        name: 'Channel Edit Program',
        path: paths.channelEditProgramPage,
        component: ChannelEditPage,
        breadcrumb: {
          items: [
            {text: 'Channels', route: paths.channelListPage},
            {text: 'Channel Program', route: paths.channelEditProgramPage},
          ],
        },
        requiredPermissions: channelPermissions.CHANNEL_EPG_VIEW.enabled,
      },
    ],
  },
  {
    name: 'VOD Collection Edit',
    children: [
      {
        name: 'VOD Collection Edit Detail',
        path: paths.vodCollectionEditDetailsPage,
        component: VodCollectionEditPage,
        breadcrumb: {
          items: [
            {text: 'VOD Collections', route: paths.vodCollectionListPage},
            {text: 'VOD Collection Details', route: paths.vodCollectionEditDetailsPage},
          ],
        },
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
      {
        name: 'VOD Collection Edit Detail',
        path: paths.vodCollectionEditDetailsPageAlt,
        component: VodCollectionEditPage,
        breadcrumb: {
          items: [
            {text: 'VOD Collections', route: paths.vodCollectionListPage},
            {text: 'VOD Collection Details', route: paths.vodCollectionEditDetailsPage},
          ],
        },
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
      {
        name: 'VOD Collection Edit Distribution',
        path: paths.vodCollectionEditDistributionPage,
        component: VodCollectionEditPage,
        breadcrumb: {
          items: [
            {text: 'VOD Collections', route: paths.vodCollectionListPage},
            {text: 'VOD Collection Distribution', route: paths.vodCollectionEditDistributionPage},
          ],
        },
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
      {
        name: 'VOD Collection Edit Artwork',
        path: paths.vodCollectionEditArtworkPage,
        component: VodCollectionEditPage,
        breadcrumb: {
          items: [
            {text: 'VOD Collections', route: paths.vodCollectionListPage},
            {text: 'VOD Collection Artwork', route: paths.vodCollectionEditArtworkPage},
          ],
        },
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
      {
        name: 'VOD Collection Edit Program',
        path: paths.vodCollectionEditProgramPage,
        component: VodCollectionEditPage,
        breadcrumb: {
          items: [
            {text: 'VOD Collections', route: paths.vodCollectionListPage},
            {text: 'VOD Collection Program', route: paths.vodCollectionEditProgramPage},
          ],
        },
        requiredPermissions: vodCollectionPermissions.VOD_VIEW.enabled,
      },
    ],
  },
  {
    name: 'Featured Group Edit',
    children: [
      {
        name: 'Featured Group Edit Detail',
        path: paths.featuredGroupEditDetailsPage,
        component: FeaturedGroupEditPage,
        breadcrumb: {
          items: [
            {text: 'Featured Groups', route: paths.featuredGroupListPage},
            {text: 'Featured Group Details', route: paths.featuredGroupEditDetailsPage},
          ],
        },
        requiredPermissions: featuredGroupsPermissions.FEATURED_GROUPS_VIEW.enabled,
      },
      {
        name: 'Featured Group Edit Detail',
        path: paths.featuredGroupEditDetailsPageAlt,
        component: FeaturedGroupEditPage,
        breadcrumb: {
          items: [
            {text: 'Featured Groups', route: paths.featuredGroupListPage},
            {text: 'Featured Group Details', route: paths.featuredGroupEditDetailsPage},
          ],
        },
        requiredPermissions: featuredGroupsPermissions.FEATURED_GROUPS_VIEW.enabled,
      },
    ],
  },
  {
    name: 'Hub Config Edit',
    children: [
      {
        name: 'Hub Config Edit',
        path: paths.hubEditPreviewPage,
        component: HubEditPage,
        breadcrumb: {
          items: [
            {text: 'Hub Manager', route: paths.hubManagerPage},
            {text: 'Hub Preview', route: paths.hubEditPreviewPage},
          ],
        },
        requiredPermissions: hubManagerPermissions.HUB_VIEW.enabled,
      },
      {
        name: 'Hub Config Edit',
        path: paths.hubEditPreviewPageAlt,
        component: HubEditPage,
        breadcrumb: {
          items: [
            {text: 'Hub Manager', route: paths.hubManagerPage},
            {text: 'Hub Preview', route: paths.hubEditPreviewPage},
          ],
        },
        requiredPermissions: hubManagerPermissions.HUB_VIEW.enabled,
      },
      {
        name: 'Hub Config Edit Settings',
        path: paths.hubEditSettingsPage,
        component: HubEditPage,
        breadcrumb: {
          items: [
            {text: 'Hub Manager', route: paths.hubManagerPage},
            {text: 'Hub Settings', route: paths.hubEditSettingsPage},
          ],
        },
        requiredPermissions: hubManagerPermissions.HUB_VIEW.enabled,
      },
      {
        name: 'Carousel List',
        path: paths.carouselListPage,
        component: CarouselList,
        breadcrumb: {
          items: [{text: 'Carousel Configs', route: paths.carouselListPage}],
        },
        requiredPermissions: hubManagerPermissions.CAROUSEL_VIEW.enabled,
      },
      {
        name: 'Carousel Config Edit Details',
        path: paths.carouselEditDetailsPage,
        component: CarouselEditPage,
        breadcrumb: {
          items: [
            {text: 'Carousel Configs', route: paths.carouselListPage},
            {text: 'Carousel Details', route: paths.carouselEditDetailsPage},
          ],
        },
        requiredPermissions: hubManagerPermissions.CAROUSEL_VIEW.enabled,
      },
      {
        name: 'Carousel Config Edit Details',
        path: paths.carouselEditDetailsPageAlt,
        component: CarouselEditPage,
        breadcrumb: {
          items: [
            {text: 'Carousel Configs', route: paths.carouselListPage},
            {text: 'Carousel Details', route: paths.carouselEditDetailsPage},
          ],
        },
        requiredPermissions: hubManagerPermissions.CAROUSEL_VIEW.enabled,
      },
    ],
  },
];

export default {
  paths,
  routes,
};
