import * as React from 'react';
import {debounce} from 'lodash-es';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  FormItem,
  Heading,
  ISelectOption,
  ITableCol,
  Popover,
  Select,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  useValidateForm,
} from '@pluto-tv/assemble';

import {useLazyFindQuery as useFindVodCategoriesQuery} from 'features/vodCollections/vodCollectionsApi';
import {TableActions} from 'components/tableActions';

import {IEpisodeVodCategoryEntry, ISelectedCategory} from 'models/episodes';
import CrudError from 'components/crudError';
import {IVodCategory} from 'models/vodCategories';
import vodCollectionRoutes from 'routes/programming.routes';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {episodeVodCategoryValidator} from '../../validators';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';
import {useAppPermissions} from 'app/permissions';
import {popoverActionsProps} from 'helpers/popoverActionProps';

interface IPopover {
  add?: boolean;
  edit?: boolean;
  id?: string;
}

export default ({model, setFields, pristineModel}: INestedEpisodeProps): JSX.Element => {
  const {permissions, ableTo} = useAppPermissions();
  const {CAN_EDIT, editPermission} = useEpisodePermissions(pristineModel);

  const [vodCategoriesOpen, setVodCategoriesOpen] = React.useState<IPopover>({});
  const vodCategories = React.useMemo(
    () => model.vodCategoryEntries?.filter(vce => vce.vodCategory?.name) || [],
    [model.vodCategoryEntries],
  );
  const [vodCategoriesDictionary, setVodCategoriesDictionary] = React.useState<{[key: string]: IVodCategory}>({});

  const [
    searchVodCategories,
    {
      data: collectionsData,
      isSuccess: isCollectionsSuccess,
      isLoading: isCollectionsLoading,
      isError: isCollectionsError,
      error: collectionsError,
    },
  ] = useFindVodCategoriesQuery();

  const [inputValueCollection, setInputValueCollection] = React.useState<string>('');
  const [selectedValueCollection, setSelectedValueCollection] = React.useState<string | null>(null);
  const [vodSelectOptions, setVodSelectOptions] = React.useState<ISelectOption[]>([]);
  const lastQueryRef = React.useRef<string | null>(null);
  const [lastSearchQuery, setLastSearchQuery] = React.useState('');

  const {
    form: selectedCategoryForm,
    model: selectedCategoryModel,
    setFields: selectedCategorySetFields,
    state: formState,
    reset: selectedCategoryReset,
  } = useValidateForm<ISelectedCategory>(episodeVodCategoryValidator);

  React.useEffect(() => {
    if (vodCategoriesOpen.add || (vodCategoriesOpen.edit && vodCategoriesOpen.id)) {
      searchVodCategories({
        limit: 100,
        activeRegions: [model.activeRegion as string],
        archived: false,
      }).then(response => {
        const data = response?.data?.data || [];
        const editingCategoryId = vodCategoriesOpen.edit ? selectedCategoryModel.category : null;

        const options = data
          .filter(cat => {
            const isAlreadySelected = vodCategories.some(existingCat => existingCat.vodCategory.id === cat.id);
            const isEditingCategory = cat.id === editingCategoryId;
            return !isAlreadySelected || isEditingCategory;
          })
          .map((collection: IVodCategory) => ({
            label: collection.name,
            value: collection.id,
          }));
        if (JSON.stringify(options) !== JSON.stringify(vodSelectOptions)) {
          setVodSelectOptions(options);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vodCategoriesOpen]);

  React.useEffect(() => {
    if (isCollectionsSuccess) {
      setVodCategoriesDictionary(
        collectionsData?.data.reduce((dic, cat) => {
          dic[cat.id] = cat;
          return dic;
        }, {}) || {},
      );
    }
  }, [collectionsData, isCollectionsSuccess]);

  const cancelAddOrUpdateCategory = () => {
    setVodCategoriesOpen({});
    setSelectedValueCollection(null);
    setInputValueCollection('');
    selectedCategoryReset();
    setVodSelectOptions([]);
    lastQueryRef.current = null;
  };

  const addOrUpdateCategoryEntry = () => {
    if (!selectedCategoryModel.category) {
      return;
    }

    const selectedVodCategory = vodCategoriesDictionary[selectedCategoryModel.category];

    if (!selectedVodCategory) {
      return;
    }

    if (vodCategoriesOpen.add) {
      setFields({
        vodCategoryEntries: [
          ...(vodCategories || []),
          {
            id: `new-${Date.now()}`,
            episode: model.id || '',
            order: selectedCategoryModel.order || 1,
            vodCategory: selectedVodCategory,
            createdAt: new Date(),
            updatedAt: new Date(),
            changeType: 'I',
          },
        ],
      });
    } else if (vodCategoriesOpen.edit && vodCategoriesOpen.id) {
      setFields({
        vodCategoryEntries: (vodCategories || []).map(voce => {
          if (voce.id !== selectedCategoryModel?.id) {
            return voce;
          }
          return {
            ...voce,
            order: selectedCategoryModel.order || 0,
            vodCategory: selectedVodCategory,
            updatedAt: new Date(),
            changeType: selectedCategoryModel.id.includes('new-') ? 'I' : 'U',
          };
        }),
      });
    }

    setSelectedValueCollection(null);
    setInputValueCollection('');
    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  const handleAddCategory = () => {
    setVodCategoriesOpen({add: true});
    selectedCategorySetFields({id: 'new', order: 1});
  };

  const handleEditCategory = (vodCategory: IEpisodeVodCategoryEntry) => {
    setVodCategoriesOpen({edit: true, id: vodCategory.id});
    setSelectedValueCollection(vodCategory.vodCategory.id);
    selectedCategorySetFields({
      id: vodCategory.id,
      category: vodCategory.vodCategory.id,
      order: vodCategory.order,
    });
  };

  const handleDeleteCategory = (deletedCategory: IEpisodeVodCategoryEntry) => {
    const cts = (vodCategories || [])
      .map(voce => {
        if (voce.id !== deletedCategory?.id) {
          return voce;
        }

        return voce.changeType === 'D' || voce.changeType === 'I'
          ? {...voce, changeType: 'X'}
          : {...voce, changeType: 'D'};
      })
      .filter(voce => voce.changeType !== 'X');

    setFields({
      vodCategoryEntries: cts as IEpisodeVodCategoryEntry[],
    });
  };

  const handleVodCollectionsSearch = React.useCallback(
    async (query: string): Promise<ISelectOption[]> => {
      setInputValueCollection(query);

      if (query.trim().length === 0 || query === lastQueryRef.current) {
        return [];
      }

      lastQueryRef.current = query;

      try {
        const response = await searchVodCategories({
          name: query,
          limit: 10,
          activeRegions: [model.activeRegion as string],
          archived: false,
        });

        const data: any[] = response?.data?.data || [];

        if (data.length === 0) {
          const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
          setVodSelectOptions(noMatches);
          return noMatches;
        }

        const editingCategoryId = vodCategoriesOpen.edit ? selectedCategoryModel.category : null;

        const options = data
          .filter(cat => {
            const isAlreadySelected = vodCategories.some(existingCat => existingCat.vodCategory.id === cat.id);
            const isEditingCategory = cat.id === editingCategoryId;
            return !isAlreadySelected || isEditingCategory;
          })
          .map(d => ({
            label: d.name,
            value: d.id,
          }));

        setVodSelectOptions(options);

        return options;
      } catch (error) {
        const noMatches = [{label: 'No matches found', value: 'no-matches', disabled: true}];
        setVodSelectOptions(noMatches);
        return noMatches;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vodCategoriesOpen],
  );

  const handleVodCollectionsSearchDebounced = React.useMemo(() => {
    return debounce((query: string): Promise<ISelectOption[]> => {
      return handleVodCollectionsSearch(query);
    }, 300);
  }, [handleVodCollectionsSearch]);

  const handleVodCollectionsSearchSafe = (query: string): Promise<ISelectOption[]> => {
    return new Promise(resolve => {
      const result = handleVodCollectionsSearchDebounced(query);

      if (result !== undefined) {
        return result
          .then(resolve)
          .catch(() => resolve([{label: 'No matches found', value: 'no-matches', disabled: true}]));
      }
      return resolve([{label: 'Loading...', value: 'loading', disabled: true}]);
    });
  };

  if (isCollectionsError && (collectionsError as any)?.data?.statusCode !== 404) {
    return <CrudError error='Error loading page data' />;
  }

  if (isCollectionsLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner id='vod-collections-spinner' center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  const noVodCollectionsAvailable = isCollectionsSuccess && collectionsData?.data.length === 0;

  const getSelectValue = (): ISelectOption | undefined => {
    if (selectedValueCollection && vodCategoriesDictionary[selectedValueCollection]?.name) {
      const label = vodCategoriesDictionary[selectedValueCollection]?.name ?? '';
      return {
        label,
        value: selectedValueCollection,
      };
    } else if (inputValueCollection) {
      return {label: inputValueCollection, value: inputValueCollection};
    } else {
      return undefined;
    }
  };

  return (
    <ContentBoxes layout='cover' coverScrolling={true}>
      <Template label='contentBoxesCover'>
        <ContentBox>
          <Template label='header'>
            <Cluster justify='space-between' align='center'>
              <Heading level='h3' color='secondary'>
                VOD Collections
              </Heading>
              {CAN_EDIT && (
                <Popover
                  manualTrigger={true}
                  visible={vodCategoriesOpen.add || (vodCategoriesOpen.edit && !!vodCategoriesOpen.id)}
                  onClickOutside={() => {
                    cancelAddOrUpdateCategory();
                  }}
                  permission={editPermission && permissions.VOD_EDIT}
                  appendToBody={true}
                >
                  <Template label='trigger'>
                    <Button
                      type='primary'
                      permission={editPermission && permissions.VOD_EDIT}
                      onClick={() => {
                        handleAddCategory();
                      }}
                      state={isCollectionsLoading ? 'thinking' : noVodCollectionsAvailable ? 'disabled' : ''}
                    >
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Box
                      id='add-collection-popover'
                      padding={popoverActionsProps.padding}
                      background={popoverActionsProps.background}
                      width={popoverActionsProps.width}
                    >
                      <Stack space='small'>
                        <FormItem label='Collection Name' {...selectedCategoryForm.category}>
                          <Select
                            placeholder='Search Collections'
                            id='category'
                            predicate='value'
                            value={getSelectValue()}
                            onChange={value => {
                              setSelectedValueCollection(value.value);
                              if (inputValueCollection !== value.label) {
                                setInputValueCollection('');
                              }
                              selectedCategorySetFields({category: value.value});
                            }}
                            options={vodSelectOptions}
                            searchable={true}
                            searchPlaceholder='Search Collections'
                            onSearch={query => {
                              if (query !== lastSearchQuery && !!query) {
                                setLastSearchQuery(query);
                                return handleVodCollectionsSearchSafe(query);
                              } else {
                                return vodSelectOptions;
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem label='Order' {...selectedCategoryForm.order}>
                          <TextInput
                            id='number-input'
                            type='number'
                            value={selectedCategoryModel.order}
                            onChange={value => {
                              selectedCategorySetFields({order: value});
                            }}
                          />
                        </FormItem>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button
                              ghost={true}
                              onClick={() => {
                                cancelAddOrUpdateCategory();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              id='add-collection-popover-button'
                              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                              type='primary'
                              onClick={() => {
                                addOrUpdateCategoryEntry();
                              }}
                            >
                              {vodCategoriesOpen.add ? '+ Add' : 'Update'}
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Stack>
                    </Box>
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Template>
          <Template label='content'>
            <Table
              flushTop={true}
              fixedHeader={true}
              emptyMsg={
                noVodCollectionsAvailable
                  ? 'No VOD collections available for the selected active region'
                  : vodCategories.length === 0
                  ? 'No VOD Collections'
                  : ''
              }
              cols={[
                {
                  label: 'Collection Name',
                  colMaxWidth: '25rem',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.vodCategory.name}
                      target='_blank'
                      url={vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row.vodCategory.id)}
                    />
                  ),
                },
                {
                  label: 'Display Name',
                  transform: row => row.vodCategory.displayName,
                },
                {
                  label: 'Published',
                  colMinWidth: '6.875rem',
                  transform: row => (
                    <Status
                      label={row.vodCategory.enabled ? 'Published' : 'Unpublished'}
                      state={row.vodCategory.enabled ? 'success' : 'neutral'}
                    />
                  ),
                },
                {
                  label: 'Archived',
                  transform: row => (row.vodCategory.archived ? 'Yes' : 'No'),
                },
                {
                  label: 'Order',
                  transform: row => row.order,
                },
                ...(CAN_EDIT && ableTo('VOD_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={['edit']}
                            deleteOption={ableTo('SERIES_DELETE') && ableTo('VOD_EDIT')}
                            altTitle='collection'
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'edit':
                                  handleEditCategory(row);
                                  break;
                                case 'delete':
                                  handleDeleteCategory(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<IEpisodeVodCategoryEntry>,
                    ]
                  : []),
              ]}
              rows={vodCategories.filter(voc => voc.changeType !== 'D')}
            ></Table>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};
