import React from 'react';
import {FormItem, Select, TFormFields} from '@pluto-tv/assemble';
import {IHubConfig} from 'models/hubConfigs';

import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';

interface IDistributionDetailsFormSectionProps {
  form: TFormFields<IHubConfig>;
  model: Partial<IHubConfig>;
  onBlur: (fieldName: keyof IHubConfig, setVal?: boolean) => void;
  setFields: (partialModel: Partial<IHubConfig>) => void;
  canEdit: boolean;
}

const DistributionDetailsFormSection = React.memo(
  ({form, model, onBlur, setFields, canEdit}: IDistributionDetailsFormSectionProps) => {
    const {data: deviceList} = useFindDevices({
      offset: 0,
      limit: 250,
      sort: 'name:asc',
    });

    const devices = React.useMemo(
      () =>
        Array.from(
          new Set([
            {label: 'ALL', value: 'ALL'},
            ...(deviceList?.data || []).map(d => ({label: d.name, value: d.platform})),
          ]),
        ),
      [deviceList?.data],
    );

    return (
      <FormItem
        {...form.deviceType}
        label={`Device Distribution (${model.deviceType?.length ? model.deviceType?.length : '0'})`}
        onBlur={() => {
          onBlur('deviceType');
        }}
        permission={canEdit ? '' : 'disabled'}
      >
        <Select
          value={model.deviceType?.map(d => ({label: d.toString(), value: d}))}
          id='deviceDistributionList'
          appendToBody={true}
          predicate='value'
          multiselect={true}
          options={devices}
          searchable={true}
          clearable={canEdit}
          onChange={value => {
            let val = value?.map(val => val.value);
            const prevValuesIncludeAll = model.deviceType?.some(x => x === 'ALL');
            const currValuesIncludeAll = value?.some(x => x.value === 'ALL');
            if (value && value?.length > 1 && prevValuesIncludeAll) {
              val = val?.filter(val => val !== 'ALL');
            } else if (currValuesIncludeAll) {
              val = ['ALL'];
            }
            setFields({deviceType: val});
          }}
        />
      </FormItem>
    );
  },
);

export default DistributionDetailsFormSection;
