import * as React from 'react';
import {
  Expand,
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  FormItem,
  TextInput,
  Select,
  Button,
  useValidateForm,
} from '@pluto-tv/assemble';
import {FormSearchWrapper} from 'components/formSearchWrapper';
import {programmingTypes} from 'helpers/programmingTypes';
import {TSortDirection} from 'models/generic';
import {searchLicensedTitlesValidator} from '../validators';
import {IUserLicensedTitleSearch} from 'models/users';
import {ILicensedTitleSearch} from 'models/licensedTitles';
import {useUserRegions} from 'helpers/useUserRegions';
import {LicensedTitleFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export interface ILicensedTitleSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isSearchExpanded: boolean;
  showFavoriteSearch: boolean;
  isFetching: boolean;
  search?: IUserLicensedTitleSearch;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserLicensedTitleSearch) => void;
  onClear: () => void;
}

const LicensedTitleSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isSearchExpanded,
    showFavoriteSearch,
    isFetching,
    search,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: ILicensedTitleSearchBarProps) => {
    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: searchSetModel,
      reset: searchResetModel,
      form: searchForm,
      getValidation: searchGetValidation,
      onBlur: searchOnBlur,
    } = useValidateForm<ILicensedTitleSearch>(searchLicensedTitlesValidator, 'ask');

    const [searchSelected, setSearchSelected] = React.useState<IUserLicensedTitleSearch | undefined>();

    const {activeRegions} = useUserRegions();
    const {data: partners} = useFindPartnersQuery();
    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const licensedTitleInputId = 'title';

    const handleSearch = async () => {
      const validation = await searchGetValidation();

      if (!validation.state.isValid) {
        return;
      }

      onSearch({
        name: '',
        model: validation.model,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
      }
    }, [search, searchSetModel]);

    React.useEffect(() => {
      if (!isFetching && isSearchExpanded) {
        const seriesTitleInput = document.getElementById(licensedTitleInputId);

        seriesTitleInput?.focus({
          preventScroll: true,
        });
      }
    }, [isFetching, isSearchExpanded]);

    const searchSelectedHandler = (search: IUserLicensedTitleSearch) => {
      setSearchSelected(search);
      searchSetModel(search.model);
      onSearch(search);
    };

    const clearHandler = () => {
      onClear();
      searchResetModel();
      setSearchSelected(undefined);
    };

    return (
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box background='pewter' padding='medium' fullHeight={true}>
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  {showFavoriteSearch && (
                    <LicensedTitleFavoriteSearch
                      searchModel={{
                        name: '',
                        model: searchModel,
                        sortCol: sortCol,
                        sortDir: sortDir,
                      }}
                      getValidation={searchGetValidation}
                      searchSelected={searchSelected}
                      onSearchSelected={searchSelectedHandler}
                      onClearSelection={clearHandler}
                    />
                  )}
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {/* Need to force form re-render on open searchBar to make autoFocus field work properly */}
                {isSearchExpanded && (
                  <FormSearchWrapper onSearch={handleSearch} id='licensedTitlesSeachForm'>
                    <Stack space='small'>
                      <FormItem
                        {...searchForm.title}
                        onBlur={() => {
                          searchOnBlur('title');
                        }}
                      >
                        <TextInput
                          placeholder='Title'
                          id={licensedTitleInputId}
                          value={searchModel.title}
                          onChange={val => {
                            searchOnChange('title', val);
                          }}
                        />
                      </FormItem>
                      <FormItem {...searchForm.partner}>
                        <Select
                          placeholder='Select Partner'
                          clearable={true}
                          predicate='value'
                          searchable={true}
                          value={{label: '', value: searchModel.partner}}
                          id='partner'
                          onSearch={val =>
                            (partners || [])
                              .filter(partner => partner.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(partner => ({label: partner.name, value: partner.id})) || []
                          }
                          options={(partners || []).map(partner => ({label: partner.name, value: partner.id}))}
                          onChange={val => searchSetFields({partner: val?.value})}
                        />
                      </FormItem>
                      <FormItem {...searchForm.regions}>
                        <Select
                          id='regions'
                          placeholder='Select Region(s)'
                          clearable={true}
                          options={activeRegions.map(ar => ({
                            label: `${ar.name} (${ar.code})`,
                            value: ar.code.toLowerCase(),
                          }))}
                          predicate='value'
                          multiselect={true}
                          value={searchModel.regions?.map(region => ({label: region, value: region}))}
                          onChange={val =>
                            searchSetFields({
                              regions: (val || []).map(option => option.value),
                            })
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.programmingType}>
                        <Select
                          id='programmingType'
                          clearable={true}
                          placeholder='Select a Type'
                          onChange={value => searchSetFields({programmingType: value?.label as any})}
                          value={{label: (searchModel.programmingType as string) || ''}}
                          options={programmingTypes.map(pT => ({label: pT}))}
                        />
                      </FormItem>
                    </Stack>
                  </FormSearchWrapper>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='xxxsmall'>
                    <Button state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button type='primary' state={isFetching ? 'thinking' : ''} onClick={handleSearch}>
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

LicensedTitleSearchBar.displayName = 'LicensedTitleSearchBar';
export default LicensedTitleSearchBar;
