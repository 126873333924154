import React from 'react';
import {ICarouselConfigSearchQuery} from 'features/carouselConfigs/carouselConfigsApi';
import {ICarouselConfig} from 'models/carouselConfigs';
import {TSortDirection} from 'models/generic';

const INIT_PARAMS = {
  sortBy: 'createdAt',
  sortDirection: 'dsc' as TSortDirection,
  limit: 50,
  offset: 0,
};

export interface ISearchCarouselData<ISearchData, ICarouselConfigSearchQuery> {
  data: ISearchData;
  totalCount: number;
  currentData: ISearchData;
  isFetching: boolean;
  isError: boolean;
  isLoading: boolean;
  search: (params: ICarouselConfigSearchQuery) => any;
}

const useSearchCarouselData = <T, U extends Record<string, unknown>>(
  useLazyFindQueryHook: () => [
    (params: ICarouselConfigSearchQuery) => any,
    {
      data: T;
      totalCount: number;
      currentData: T;
      isLoading: boolean;
      isFetching: boolean;
      isError: boolean;
      error: Error;
      isSuccess: boolean;
    },
  ],
): ISearchCarouselData<T, U> => {
  const [trigger, {currentData, isLoading, isFetching, isError}] = useLazyFindQueryHook();

  const [allData, setAllData] = React.useState<{data: ICarouselConfig[]; totalCount: number}>({
    data: [],
    totalCount: 0,
  });
  const [allTotalCount, setAllTotalCount] = React.useState(0);

  const doSearch = React.useCallback(
    async (params: ICarouselConfigSearchQuery) => {
      try {
        const response = await trigger({...INIT_PARAMS, ...params, time: new Date().getTime()}).unwrap();

        setAllData(response);
        setAllTotalCount(response.totalCount);

        return {data: response.data, totalCount: response.totalCount};
      } catch (error) {
        setAllData({data: [], totalCount: 0});
        setAllTotalCount(0);

        return {data: [], totalCount: 0};
      }
    },
    [trigger],
  );

  return {
    data: allData as T,
    currentData,
    totalCount: allTotalCount,
    isLoading,
    isFetching,
    isError,
    search: doSearch as any,
  };
};

export default useSearchCarouselData;
