import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {every, cloneDeep, filter, forOwn, forEach} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import {
  Box,
  Button,
  Checkbox,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Cover,
  DateTime,
  FormItem,
  Grid,
  Heading,
  Icon,
  Paragraph,
  Popover,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  Toggle,
  Textarea,
  TextInput,
  useValidateForm,
  Help,
  IDateRange,
  trimModel,
  disablePastDatesBehavior,
} from '@pluto-tv/assemble';

import campaignRoutes from 'routes/campaign.routes';

import {useFindByIdQuery, useUpdateMutation, useDeleteMutation} from 'features/campaigns/campaignsApi';
import {useFindQuery as useFindAppnamesQuery} from 'features/appnames/appnamesApi';

import CrudError from 'components/crudError';
import DeleteConfirmation from 'components/deleteConfirmation';
import {ICampaign, ICampaignSlot} from 'models/campaigns';
import NotAuthorized from 'components/notAuthorized';
import {useAppPermissions} from 'app/permissions';
import {useUserRegions} from 'helpers/useUserRegions';

import {
  campaignDetailsValidator,
  distributionTargetValidator,
  targetRegionValidator,
  publicationDateValidator,
  billboardsValidator,
  miscValidator,
} from '../validators';

import PrimeTimeBillboard from './primeTimeBillboard';
import PrimeTimeBillboardSearch from './primeTimeBillboardSearch';

const getTargetCounts = targets => ({
  enabled: filter(targets, target => target.value).length,
  total: targets.length,
});

const allSelected = targets => {
  if (!targets || !targets.length) {
    return false;
  }

  return every(targets, target => target.value);
};

const errorMatch = /\[((?:(\d(,?))*))]/;

export default (): JSX.Element => {
  const history = useHistory();
  const {id}: {id: string} = useParams();
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('CAMPAIGN_VIEW')) {
    return <NotAuthorized />;
  }

  const cantModify = !ableTo('CAMPAIGN_EDIT');
  const canDelete = ableTo('CAMPAIGN_DELETE');

  const [updateCampaign] = useUpdateMutation();
  const [removeCampaign] = useDeleteMutation();

  const {
    activeRegions,
    territories,
    territoriesByRegion,
    isFetching: isFetchingRegions,
    isError: isErrorRegions,
  } = useUserRegions();

  const {
    data: appNames,
    isFetching: isFetchingAppNames,
    isError: isErrorAppNames,
  } = useFindAppnamesQuery({offset: 0, limit: 1000, sort: 'name:asc'});
  const {data: item, isError, error} = useFindByIdQuery(id, {refetchOnMountOrArgChange: true});

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [slotSaveErrors, setSlotSaveErrors] = React.useState<string[]>([]);
  const [billboardToAddOrReplace, setBillboardToAddOrReplace] = React.useState<null | number>(null);
  const [replacingImages, setReplacingImages] = React.useState(false);
  const [isReplaceSlot, setIsReplaceSlot] = React.useState(false);

  const cancelHandler = () => {
    history.push(campaignRoutes.paths.campaignListPage);
  };

  const saveHandler = async (entity: ICampaign) => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);
    setSlotSaveErrors([]);

    const trimmed = trimModel(entity, 'name', 'displayName', 'description');
    const {_id: id, name, ...props} = trimmed;

    try {
      await updateCampaign({
        id,
        campaign: {
          name,
          ...props,
        },
      }).unwrap();

      setModel(trimmed);
      Toast.success('Success', 'Campaign Updated');
    } catch (e) {
      const duplicateError = (e as any).data?.error;
      if (duplicateError === 'Conflict') {
        Toast.error('Validation Error', `Campaign with name '${entity.name}' already exists.`);
      }

      const validationErrors = (e as any).data?.validationErrors;

      const slotErrors: string[] = [];

      if (validationErrors) {
        forOwn(validationErrors, (val: string[], key: string) => {
          forEach(val, errorMsg => {
            Toast.error('Validation Error', errorMsg);

            if (key === 'primetimeBillboards') {
              // Check for billboard saving errors
              const errorSlotMatch = errorMsg.match(errorMatch);
              if (errorSlotMatch && errorSlotMatch.length > 0) {
                const slots = errorSlotMatch[1].split(',');
                if (slots && slots.length > 0) {
                  slots.forEach(slot => (slotErrors[parseInt(slot) - 1] = slot));
                }
              }
            }
          });
        });
      }

      setSlotSaveErrors(slotErrors);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteHandler = async () => {
    if (!campaign || !campaign._id || !canDelete) {
      toggleDeleteOpen();
      return;
    }

    try {
      await removeCampaign(campaign._id);
      toggleDeleteOpen();
      history.push(campaignRoutes.paths.campaignListPage, {
        deletedCampaignName: campaign.name,
      });
    } catch (e) {
      Toast.error('Error', `Failed to remove ${campaign.name} campaign`);
    }
  };

  const [publicationSettingsLock, setPublicationSettingsLock] = React.useState(true);
  const togglePublicationSettingsLock = () => {
    if (cantModify) return;
    if (publicationSettingsLock) {
      togglePublicationSettingsLockConfirmOpen();
    }
    setPublicationSettingsLock(!publicationSettingsLock);
  };

  const [distributionTargetsLock, setDistributionTargetsLock] = React.useState(true);
  const toggleDistributionTargetsLock = () => {
    if (cantModify) return;
    if (distributionTargetsLock) {
      toggleDistributionTargetsLockConfirmOpen();
    }
    setDistributionTargetsLock(!distributionTargetsLock);
  };

  const [distributionTargets, setDistributionTargets] = React.useState<any[]>([]);
  const [targetCounts, setTargetCounts] = React.useState(getTargetCounts(distributionTargets));

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [publicationSettingsLockConfirmOpen, setPublicationSettingsLockConfirmOpen] = React.useState(false);
  const [distributionTargetsLockConfirmOpen, setDistributionTargetsLockConfirmOpen] = React.useState(false);

  const togglePublishOpen = () => {
    setPublishOpen(!publishOpen);
  };
  const toggleDeleteOpen = () => {
    setDeleteOpen(!deleteOpen);
  };
  const togglePublicationSettingsLockConfirmOpen = () => {
    setPublicationSettingsLockConfirmOpen(!publicationSettingsLockConfirmOpen);
  };
  const toggleDistributionTargetsLockConfirmOpen = () => {
    setDistributionTargetsLockConfirmOpen(!distributionTargetsLockConfirmOpen);
  };

  const {
    form: campaignForm,
    model: campaign,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    state: formState,
    setModel,
    dirtyFields,
  } = useValidateForm<ICampaign>([
    ...campaignDetailsValidator,
    ...distributionTargetValidator,
    ...targetRegionValidator,
    ...publicationDateValidator,
    ...billboardsValidator,
    ...miscValidator,
  ]);

  const [enableTargets, setEnableTargets] = React.useState(allSelected(distributionTargets));

  const updateToggles = targets => {
    setDistributionTargets(targets);
    setTargetCounts(getTargetCounts(targets));

    setFields({
      distribution: targets.filter(t => t.value).map(t => t.label),
    });
  };

  const toggleEnableAll = (val: boolean) => {
    setEnableTargets(val);

    const targets = distributionTargets.map(target => {
      target.value = val;
      return target;
    });

    updateToggles(targets);
  };

  const toggleChanged = (index: number, checked: boolean) => {
    if (!checked) {
      if (enableTargets) {
        setEnableTargets(false);
      }
    }

    const clonedTargets = cloneDeep(distributionTargets);
    clonedTargets[index].value = checked;

    const allTargetsSelected = allSelected(clonedTargets);

    if (allTargetsSelected) {
      if (!enableTargets) {
        setEnableTargets(true);
      }
    }

    updateToggles(clonedTargets);
  };

  React.useEffect(() => {
    if (item && appNames) {
      const targets =
        appNames?.data.map(app => ({
          label: app.name,
          value: item.distribution ? item.distribution.some(d => d === app.name) : false,
        })) || [];

      setDistributionTargets(targets);
      setTargetCounts(getTargetCounts(targets));
      setEnableTargets(allSelected(targets));

      // initializes validator fields with campaign information
      setModel(item as ICampaign);
    }
  }, [item, appNames, setModel]);

  const openSearch = pos => {
    setReplacingImages(false);
    setBillboardToAddOrReplace(pos);
  };

  const openReplace = (position: number, replaceImages = false) => {
    setIsReplaceSlot(true);
    setReplacingImages(replaceImages ? true : false);
    setBillboardToAddOrReplace(position);
    setSlotSaveErrors([]);
  };

  const onSearchClose = () => {
    setBillboardToAddOrReplace(null);
    setIsReplaceSlot(false);
  };

  const onContentFound = (position: number, content: ICampaignSlot) => {
    const newBillboard = !isReplaceSlot
      ? [...(campaign.primetimeBillboards || []), content]
      : campaign.primetimeBillboards?.map((ptb, index) => (index === position ? content : ptb));

    setBillboardToAddOrReplace(null);
    setFields({
      primetimeBillboards: newBillboard,
    });
  };

  const onRemoveBillboard = (indexToRemove: number) => {
    const newItems = campaign.primetimeBillboards?.filter((item, index) => index !== indexToRemove);
    setFields({
      primetimeBillboards: newItems,
    });
    setSlotSaveErrors([]);
  };

  if (isError || isErrorAppNames || isErrorRegions) {
    return <CrudError error={error} />;
  }

  if (!campaign || campaign._id !== id || isFetchingRegions || isFetchingAppNames) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutterTop='medium'
        gutterBottom='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium' wrap={false}>
            <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
              {pristineModel.name}
            </Heading>
            <Cluster space='small' align='center' wrap={false}>
              <Toggle
                label='Office Only'
                reverse={true}
                value={campaign.plutoOfficeOnly}
                onChange={value => setFields({plutoOfficeOnly: value})}
                permission={permissions.CAMPAIGN_EDIT}
              />
              <Popover
                manualTrigger={true}
                visible={publishOpen}
                onClickOutside={() => setPublishOpen(false)}
                allowedPlacements={['bottom', 'bottom-end']}
              >
                <Template label='trigger'>
                  <Button
                    type='solid'
                    icon='arrowdown'
                    iconPosition='right'
                    state={!campaign.primetimeBillboards || campaign.primetimeBillboards.length < 5 ? 'disabled' : ''}
                    onClick={() => togglePublishOpen()}
                    permission={permissions.CAMPAIGN_EDIT}
                  >
                    {campaign.published ? (
                      <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                    ) : (
                      <Status label='Unpublished' state='neutral' />
                    )}
                  </Button>
                </Template>
                <Template label='popover'>
                  <Stack space='small'>
                    <Status
                      label='Unpublished'
                      state='neutral'
                      onClick={() => {
                        setFields({published: false});
                        togglePublishOpen();
                      }}
                    />
                    <Status
                      label='Published'
                      state='success'
                      onClick={() => {
                        setFields({published: true});
                        togglePublishOpen();
                      }}
                    />
                  </Stack>
                </Template>
              </Popover>
              {canDelete && (
                <Popover
                  manualTrigger={true}
                  visible={deleteOpen}
                  onClickOutside={() => setDeleteOpen(false)}
                  allowedPlacements={['bottom-end']}
                  maxWidth='22rem'
                >
                  <Template label='trigger'>
                    <Button type='delete' onClick={() => toggleDeleteOpen()}>
                      Delete
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message={'Are you sure you want to delete ' + pristineModel.name + '?'}
                      cancelButtonFunction={() => toggleDeleteOpen()}
                      proceedButtonFunction={() => deleteHandler()}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <ContentBoxes layout='columns'>
            <ContentBoxColumn>
              <ContentBox title='Details'>
                <Stack space='medium'>
                  <FormItem {...campaignForm.name} onBlur={() => onBlur('name')} permission={permissions.CAMPAIGN_EDIT}>
                    <TextInput onChange={value => onChange('name', value)} value={campaign.name} id='title' />
                  </FormItem>
                  <FormItem
                    {...campaignForm.displayName}
                    onBlur={() => onBlur('displayName')}
                    permission={permissions.CAMPAIGN_EDIT}
                  >
                    <TextInput
                      onChange={value => onChange('displayName', value)}
                      value={campaign.displayName}
                      id='displayName'
                    />
                  </FormItem>
                  <FormItem
                    {...campaignForm.description}
                    onBlur={() => onBlur('description')}
                    permission={permissions.CAMPAIGN_EDIT}
                  >
                    <Textarea
                      onChange={value => onChange('description', value)}
                      value={campaign.description}
                      id='description'
                      minHeight='6.25rem'
                    />
                  </FormItem>
                </Stack>
              </ContentBox>
            </ContentBoxColumn>
            <ContentBoxColumn>
              <ContentBox>
                <Template label='header'>
                  <Cluster space='xxsmall' align='end'>
                    <Heading level='h3' color='secondary'>
                      Publication Settings
                    </Heading>
                    {(!campaign.primetimeBillboards || campaign.primetimeBillboards.length === 0) && (
                      <Icon
                        icon={publicationSettingsLock ? 'lock' : 'unlocked'}
                        size='medium'
                        color='neutral'
                        onClick={() => {
                          togglePublicationSettingsLock();
                          setPublicationSettingsLockConfirmOpen(false);
                        }}
                      />
                    )}
                    {campaign.primetimeBillboards && campaign.primetimeBillboards.length > 0 && (
                      <Popover
                        maxWidth='25rem'
                        manualTrigger={true}
                        visible={publicationSettingsLockConfirmOpen}
                        onClickOutside={() => setPublicationSettingsLockConfirmOpen(false)}
                      >
                        <Template label='trigger'>
                          <Icon
                            icon={publicationSettingsLock ? 'lock' : 'unlocked'}
                            size='medium'
                            color='neutral'
                            onClick={
                              publicationSettingsLock
                                ? () => togglePublicationSettingsLockConfirmOpen()
                                : () => togglePublicationSettingsLock()
                            }
                          ></Icon>
                        </Template>
                        <Template label='popover'>
                          <Box padding='xxsmall'>
                            <Sidebar gap='large'>
                              <Icon icon='warning' size='xxxlarge' />
                              <Box paddingTop='xsmall'>
                                <Stack space='large'>
                                  <Heading level='h3'>Are you sure?</Heading>
                                  <Paragraph>
                                    Changing a campaign&apos;s metadata can cause selected carousel content to no longer
                                    be valid. Please ensure all configured content is still valid before saving changes.
                                  </Paragraph>
                                  <Cluster justify='space-between'>
                                    <div></div>
                                    <Cluster space='small'>
                                      <Button onClick={() => togglePublicationSettingsLockConfirmOpen()}>Cancel</Button>
                                      <Button type='primary' onClick={() => togglePublicationSettingsLock()}>
                                        I understand, unlock
                                      </Button>
                                    </Cluster>
                                  </Cluster>
                                </Stack>
                              </Box>
                            </Sidebar>
                          </Box>
                        </Template>
                      </Popover>
                    )}
                  </Cluster>
                </Template>
                <Template label='content'>
                  <Stack space='medium'>
                    <FormItem
                      {...campaignForm.activeRegion}
                      {...{state: publicationSettingsLock ? 'disabled' : campaignForm.activeRegion?.state}}
                    >
                      <Select
                        id='targetRegion'
                        value={{label: campaign.activeRegion || '', value: campaign.activeRegion}}
                        options={(activeRegions || []).map(ar => ({label: `${ar.name} (${ar.code})`, value: ar.code}))}
                        onChange={value => {
                          const activeRegion = value.value;
                          const regionFilter = territoriesByRegion[value.value]?.map(t => t.id) || [];

                          setFields({
                            activeRegion,
                            regionFilter,
                          });
                        }}
                        predicate='value'
                        appendToBody={true}
                      />
                    </FormItem>
                    <FormItem
                      {...campaignForm.regionFilter}
                      {...{state: publicationSettingsLock ? 'disabled' : campaignForm.regionFilter?.state}}
                    >
                      <Select
                        id='territories'
                        value={campaign.regionFilter?.map(d => ({label: d, value: d}))}
                        multiselect={true}
                        searchable={true}
                        options={(territories || []).map(t => ({label: t.name, value: t.id}))}
                        onChange={value =>
                          setFields({
                            regionFilter: (value || []).map(v => v.value),
                          })
                        }
                        appendToBody={true}
                        predicate='value'
                      />
                    </FormItem>
                    <FormItem
                      {...campaignForm.start}
                      {...{state: publicationSettingsLock ? 'disabled' : campaignForm.start?.state}}
                    >
                      <DateTime
                        id='publicationDate'
                        placeholder='Select campaign range'
                        range={true}
                        onBeforeDateRender={disablePastDatesBehavior}
                        time={true}
                        appendToBody={true}
                        value={{start: new Date(campaign?.start || ''), end: new Date(campaign?.end || '')}}
                        onChange={value => {
                          const end = luxon
                            .fromJSDate((value as IDateRange).end as Date)
                            .endOf('minute')
                            .toJSDate();

                          setFields({
                            start: (value as IDateRange).start,
                            end,
                          });
                        }}
                      ></DateTime>
                    </FormItem>
                  </Stack>
                </Template>
              </ContentBox>
            </ContentBoxColumn>
            <ContentBox gridItemFull={true}>
              <Template label='header'>
                <Stack space='xlarge'>
                  <Box paddingTop='xxsmall'>
                    <Cluster space='xxsmall' align='end'>
                      <Heading level='h3' color='secondary'>
                        Distribution Targets
                      </Heading>
                      {(!campaign.primetimeBillboards || campaign.primetimeBillboards.length === 0) && (
                        <Icon
                          icon={distributionTargetsLock ? 'lock' : 'unlocked'}
                          size='medium'
                          color='neutral'
                          onClick={() => {
                            toggleDistributionTargetsLock(), setDistributionTargetsLockConfirmOpen(false);
                          }}
                        />
                      )}
                      {campaign.primetimeBillboards && campaign.primetimeBillboards.length > 0 && (
                        <Popover
                          maxWidth='25rem'
                          manualTrigger={true}
                          visible={distributionTargetsLockConfirmOpen}
                          onClickOutside={() => setDistributionTargetsLockConfirmOpen(false)}
                        >
                          <Template label='trigger'>
                            <Icon
                              icon={distributionTargetsLock ? 'lock' : 'unlocked'}
                              size='medium'
                              color='neutral'
                              onClick={
                                distributionTargetsLock
                                  ? () => toggleDistributionTargetsLockConfirmOpen()
                                  : () => toggleDistributionTargetsLock()
                              }
                            ></Icon>
                          </Template>
                          <Template label='popover'>
                            <Box padding='xxsmall'>
                              <Sidebar gap='large'>
                                <Icon icon='warning' size='xxxlarge' />
                                <Box paddingTop='xsmall'>
                                  <Stack space='large'>
                                    <Heading level='h3'>Are you sure?</Heading>
                                    <Paragraph>
                                      Changing a campaign&apos;s metadata can cause selected carousel content to no
                                      longer be valid. Please ensure all configured content is still valid before saving
                                      changes.
                                    </Paragraph>
                                    <Cluster justify='space-between'>
                                      <div></div>
                                      <Cluster space='small'>
                                        <Button onClick={() => toggleDistributionTargetsLockConfirmOpen()}>
                                          Cancel
                                        </Button>
                                        <Button type='primary' onClick={() => toggleDistributionTargetsLock()}>
                                          I understand, unlock
                                        </Button>
                                      </Cluster>
                                    </Cluster>
                                  </Stack>
                                </Box>
                              </Sidebar>
                            </Box>
                          </Template>
                        </Popover>
                      )}
                    </Cluster>
                  </Box>
                  <Stack space='small'>
                    <Heading level='h4' color='secondary'>
                      {targetCounts.enabled} of {targetCounts.total} Enabled
                    </Heading>
                    <Help state={campaignForm.distribution?.state || 'normal'}>Select at least one</Help>
                  </Stack>
                  <Checkbox
                    label='Enable All'
                    state={distributionTargetsLock ? 'disabled' : ''}
                    onChange={toggleEnableAll}
                    value={enableTargets}
                    permission={permissions.CAMPAIGN_EDIT}
                    indeterminate={targetCounts.enabled > 0 && targetCounts.enabled !== targetCounts.total}
                  />
                </Stack>
              </Template>
              <Template label='content'>
                <Box height='12.5rem'>
                  <Grid minimum='21.875rem' gap='xlarge'>
                    {distributionTargets.map((target, i) => (
                      <Toggle
                        key={target.label}
                        label={target.label}
                        value={target.value}
                        state={distributionTargetsLock ? 'disabled' : ''}
                        onChange={val => toggleChanged(i, val)}
                        permission={permissions.CAMPAIGN_EDIT}
                      />
                    ))}
                  </Grid>
                </Box>
              </Template>
            </ContentBox>
            <ContentBox gridItemFull={true}>
              <PrimeTimeBillboard
                readonly={
                  cantModify ||
                  (dirtyFields.end || dirtyFields.activeRegion || dirtyFields.regionFilter || dirtyFields.start
                    ? true
                    : false)
                }
                helpText={campaignForm.primetimeBillboards?.helpText}
                billboards={campaign.primetimeBillboards || []}
                saveErrors={slotSaveErrors}
                removeHandler={index => onRemoveBillboard(index)}
                searchHandler={(position: number) => openSearch(position)}
                replaceHandler={(position: number, replaceImages) => openReplace(position, replaceImages)}
                changeHandler={items =>
                  setFields({
                    primetimeBillboards: items,
                  })
                }
              />
            </ContentBox>
          </ContentBoxes>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={() => saveHandler(campaign as ICampaign)}
                  id='save'
                  permission={permissions.CAMPAIGN_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
      {billboardToAddOrReplace !== null && (
        <PrimeTimeBillboardSearch
          isOpen={true}
          campaignID={campaign._id}
          region={campaign.activeRegion}
          startDate={campaign.start}
          endDate={campaign.end}
          billboard={
            billboardToAddOrReplace !== null ? (campaign.primetimeBillboards || [])[billboardToAddOrReplace] : undefined
          }
          replacingImages={replacingImages}
          position={billboardToAddOrReplace}
          closeHandler={() => onSearchClose()}
          contentFoundHandler={(position, content) => onContentFound(position, content)}
        />
      )}
    </>
  );
};
