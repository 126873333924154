import {TColors, TScale, TSize} from '@pluto-tv/assemble';

interface IPopoverActionProps {
  background: TColors;
  padding: TScale;
  width: TSize;
  widthWide: TSize;
}

export const popoverActionsProps: Partial<IPopoverActionProps> = {
  background: 'charcoal',
  padding: 'small',
  width: '18.75rem',
  widthWide: '25rem',
};
