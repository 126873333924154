import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Divider,
  Heading,
  Notification,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {capitalize, cloneDeep, some, remove} from 'lodash-es';

import {useFindByIdQuery, useUpdateMutation, useDeleteMutation, useIsValidQuery} from 'features/series/seriesApi';

import {
  seriesAssetsValidator,
  seriesDetailsValidator,
  seriesHeaderValidator,
  seriesSchedulingValidator,
  seriesSettingsValidator,
} from '../validators';
import {useSeriesPermissions} from '../permissions/useSeriesPermissions';

import NotAuthorized from 'components/notAuthorized';
import DeleteConfirmation from 'components/deleteConfirmation';
import ConfirmRouteChange from 'components/confirmRouteChange';
import CrudError from 'components/crudError';

import contentRoutes from 'routes/content.routes';
import {ISeries} from 'models/series';
import {useUserRegionModel} from 'helpers/useUserRegions';
import {
  useInsertMutation as useInsertCategoryEntryMutation,
  useUpdateMutation as useUpdateCategoryEntryMutation,
  useDeleteMutation as useDeleteCategoryEntryMutation,
} from 'features/vodCategoryEntries/vodCategoryEntriesApi';

import SeriesDetails from './details';
import SeriesSettings from './settings';
import SeriesEpisodes from './episodes';
import SeriesScheduling from './scheduling';
import SeriesAssets from './assets';

const seriesTabs = ['details', 'settings', 'episodes', 'scheduling', 'assets'] as const;

export type TSeriesTabs = typeof seriesTabs[number];

const getActiveTab = (pathname: string): TSeriesTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as TSeriesTabs;

  if (seriesTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'details';
};

export default (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const history = useHistory();
  const activeTab: TSeriesTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasFlaggedFields, setHasFlaggedFields] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [insertVodCategoryEntry] = useInsertCategoryEntryMutation();
  const [updateVodCategoryEntry] = useUpdateCategoryEntryMutation();
  const [deleteVodCategoryEntry] = useDeleteCategoryEntryMutation();

  const {
    data: series,
    isError: isSeriesError,
    error: seriesError,
    isLoading: isSeriesFetching,
    refetch: fetchSeries,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: seriesValidationMessage,
    isFetching: isSeriesValidationMessageFetching,
    refetch: refetchSeriesValidationMessage,
  } = useIsValidQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateSeries] = useUpdateMutation();
  const [deleteSeries] = useDeleteMutation();

  const canViewRegion = useUserRegionModel(series);
  const {CAN_VIEW, CAN_DELETE, editPermission} = useSeriesPermissions(series);

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<ISeries>([
    ...seriesDetailsValidator,
    ...seriesSettingsValidator,
    ...seriesHeaderValidator,
    ...seriesAssetsValidator,
    ...seriesSchedulingValidator,
  ]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(contentRoutes.paths.seriesListPage);
    }
  }, [isLeaving, history]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveVodCategories = async () => {
    setIsSaving(true);
    try {
      await Promise.all(
        (model.vodCategoryEntries || []).map(async voc => {
          switch (voc.changeType) {
            case 'D':
              await deleteVodCategoryEntry({
                id: voc.id,
                series: voc.series,
              }).unwrap();
              break;
            case 'U':
              await updateVodCategoryEntry({
                id: voc.id,
                vodCategoryEntry: {
                  id: voc.id,
                  series: voc.series,
                  vodCategory: voc.vodCategory.id,
                  order: voc.order,
                },
              }).unwrap();
              break;
            case 'I':
              await insertVodCategoryEntry({
                series: voc.series,
                order: voc.order,
                vodCategory: voc.vodCategory.id,
              }).unwrap();
              break;
          }
        }),
      );

      // If it needs to go and saveSeries then it won't refetch series at this point
      if (!Object.keys(dirtyFields).some(x => x !== 'vodCategoryEntries')) {
        await fetchSeries();
      }

      await refetchSeriesValidationMessage();

      Toast.success('Success', 'Series - VOD Collections Updated');
      setIsSaving(false);
    } catch (e: any) {
      Toast.error(
        'Error',
        e?.data?.message.length > 0
          ? `Failed to save series. ${capitalize(e?.data?.message)}`
          : 'Failed to save series. Please try again',
      );
      setIsSaving(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveSeries = async (updateFields: string[]) => {
    setIsSaving(true);
    // Save data to API here
    try {
      const transFormedModel = {
        ...model,
        channels: (model.channels || []).map(channel => channel.id),
        customReferences: (model.customReferences || []).map(cr => ({
          id: cr.id,
          type: cr.type,
          value: cr.value.id,
        })),
        vodCategoryEntries: model.vodCategoryEntries && model.vodCategoryEntries.map(vc => vc.id),
      };

      updateSeries({
        id,
        series: transFormedModel as any,
        fields: updateFields,
      }).then((response: any) => {
        // 404 errors are catched in this block
        if (response.error) {
          if (response?.error?.status === 503) {
            Toast.error('Error', 'Failed to save series. Please try again');
            return;
          }
          const error = response?.error?.data?.message;
          Toast.error(
            'Error',
            error.length > 0
              ? `Failed to save series. ${capitalize(error)}`
              : 'Failed to save series. Please try again',
          );
          return;
        }

        const toastMsg = updateFields.includes('channels') ? 'Series - Channels Updated' : 'Series Updated';

        Toast.success('Success', toastMsg);
        setIsSaving(false);
      });
    } catch (e: any) {
      Toast.error(
        'Error',
        e?.data?.message.length > 0
          ? `Failed to save series. ${capitalize(e?.data?.message)}`
          : 'Failed to save series. Please try again',
      );
      setIsSaving(false);
    }
  };

  const saveData = React.useCallback(async () => {
    if (dirtyFields.vodCategoryEntries) {
      await saveVodCategories();
    }

    if (Object.keys(dirtyFields).some(x => x !== 'vodCategoryEntries')) {
      const updateFields = remove(Object.keys(dirtyFields), field => field !== 'vodCategoryEntries');
      await saveSeries(updateFields);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields]);

  React.useEffect(() => {
    // Fields we need to have special messaging for
    const checkedFields = ['duration', 'breakpoints', 'origin'];

    setHasFlaggedFields(some(checkedFields, field => dirtyFields[field]));
  }, [dirtyFields]);

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleDeleteOpen = React.useCallback(() => {
    setDeleteOpen(prev => !prev);
  }, []);

  const deleteHandler = React.useCallback(async () => {
    if (!CAN_DELETE) {
      toggleDeleteOpen();
      return;
    }

    try {
      await deleteSeries(id).unwrap();
      Toast.success('Success', 'Series Removed');
      cancelHandler();
    } catch (e: any) {
      Toast.error(
        'Error',
        e?.data?.message.length > 0
          ? `Failed to remove series. ${capitalize(e?.data?.message)}`
          : 'Failed to remove series.',
      );
    } finally {
      toggleDeleteOpen();
    }
  }, [toggleDeleteOpen, id, deleteSeries, CAN_DELETE]);

  // Handler for episode initial load or after save
  React.useEffect(() => {
    if (series) {
      const clonedSeries = cloneDeep(series);
      setModel(clonedSeries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series]);

  if (isSeriesError) {
    return <CrudError error={seriesError} />;
  }

  if (isSeriesFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (!CAN_VIEW || !canViewRegion) {
    return <NotAuthorized />;
  }

  return (
    <>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={saveData}
        hasFlaggedFields={hasFlaggedFields}
        isValid={formState.isValid}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel.name}
                </Heading>
                <Cluster align='flex-start' space='medium' wrap={false}>
                  <Cluster space='xxsmall' wrap={false}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Active Region:
                    </Heading>
                    <Heading level='h5' color='primary'>
                      {pristineModel.activeRegion?.toUpperCase()}
                    </Heading>
                  </Cluster>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Popover
                  manualTrigger={true}
                  visible={publishOpen}
                  onClickOutside={() => setPublishOpen(false)}
                  allowedPlacements={['bottom', 'bottom-end']}
                >
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={editPermission}
                    >
                      {model.published ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='small'>
                      <Status
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({published: false});
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({published: true});
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
                {CAN_DELETE && (
                  <Popover
                    manualTrigger={true}
                    visible={deleteOpen}
                    onClickOutside={() => setDeleteOpen(false)}
                    allowedPlacements={['bottom-end']}
                    maxWidth='22rem'
                  >
                    <Template label='trigger'>
                      <Button type='delete' onClick={() => toggleDeleteOpen()}>
                        Delete
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <DeleteConfirmation
                        message={'Are you sure you want to delete ' + pristineModel.name + '?'}
                        cancelButtonFunction={() => toggleDeleteOpen()}
                        proceedButtonFunction={() => deleteHandler()}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Cluster>
            {!isSeriesValidationMessageFetching && seriesValidationMessage !== null && (
              <Notification type='error'>{capitalize(seriesValidationMessage)}</Notification>
            )}
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              <Button
                active={activeTab === 'details'}
                onClick={() => history.push(contentRoutes.paths.seriesEditDetailsPage.replace(':id', id))}
              >
                Details
              </Button>
              <Button
                active={activeTab === 'settings'}
                onClick={() => history.push(contentRoutes.paths.seriesEditSettingsPage.replace(':id', id))}
              >
                Settings
              </Button>
              <Button
                active={activeTab === 'episodes'}
                onClick={() => history.push(contentRoutes.paths.seriesEditEpisodesPage.replace(':id', id))}
              >
                Episodes
              </Button>
              <Button
                active={activeTab === 'scheduling'}
                onClick={() => history.push(contentRoutes.paths.seriesEditSchedulingPage.replace(':id', id))}
              >
                Scheduling
              </Button>
              <Button
                active={activeTab === 'assets'}
                onClick={() => history.push(contentRoutes.paths.seriesEditAssetsPage.replace(':id', id))}
              >
                Assets
              </Button>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'details' && (
            <SeriesDetails
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'settings' && (
            <SeriesSettings
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'episodes' && (
            <SeriesEpisodes
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'scheduling' && (
            <SeriesScheduling
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
          {activeTab === 'assets' && (
            <SeriesAssets
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
            />
          )}
        </Template>

        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={saveData}
                  id='save'
                  permission={editPermission}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
