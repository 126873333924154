import React from 'react';
import {ISelectOption, Select} from '@pluto-tv/assemble';

const MainCategoriesFormSelect = React.lazy(() => import('components/mainCategoriesFormSelect'));
const VodCategoriesAutocompleteFormSelect = React.lazy(() => import('components/vodCategoriesAutocomplete'));
const ChannelsAutocompleteFormSelect = React.lazy(() => import('components/featuredGroupList/components/Autocomplete'));
const SeriesAutocompleteFormSelect = React.lazy(() => import('components/seriesAutocomplete'));
const EpisodesAutocompleteFormSelect = React.lazy(() => import('components/episodeAutocomplete'));
const MlcarouselsAutocomplete = React.lazy(() => import('components/mlcarouselsAutocomplete'));

interface IAssociatedCarouselSelectProps {
  source: string;
  service: string;
  associatedCarousel?: {name: string; id: string};
  onChange: (value?: ISelectOption) => void;
  activeRegionsList: string[];
  isError: boolean;
  activeRegion: string;
}

const AssociatedCarouselSelect = React.memo(
  ({
    source,
    service,
    associatedCarousel,
    activeRegionsList,
    onChange,
    isError,
    activeRegion,
  }: IAssociatedCarouselSelectProps) => {
    return (
      <>
        {source === 'mediacatalog' && service === 'main-categories' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <MainCategoriesFormSelect
              id='associatedCarousel'
              modelCategories={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              setFields={(val: ISelectOption) => onChange(val)}
              activeRegions={activeRegionsList}
            />
          </React.Suspense>
        ) : source === 'vod' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <VodCategoriesAutocompleteFormSelect
              activeRegions={activeRegionsList}
              id='associatedCarousel'
              value={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              state={isError ? 'disabled' : 'normal'}
              setFields={(value: ISelectOption) => onChange(value)}
            />
          </React.Suspense>
        ) : source === 'recommender' && service === 'similar-channels' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <ChannelsAutocompleteFormSelect
              id='associatedCarousel'
              value={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              state={isError ? 'disabled' : 'normal'}
              activeRegions={activeRegionsList}
              onChange={(value?: ISelectOption) => onChange(value)}
            />
          </React.Suspense>
        ) : source === 'recommender' && service === 'similar-tvshows' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <SeriesAutocompleteFormSelect
              id='associatedCarousel'
              value={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              state={isError ? 'disabled' : 'normal'}
              activeRegions={activeRegionsList}
              onChange={(value?: ISelectOption) => onChange(value)}
            />
          </React.Suspense>
        ) : source === 'recommender' && service === 'similar-movies' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <EpisodesAutocompleteFormSelect
              id='associatedCarousel'
              value={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              state={isError ? 'disabled' : 'normal'}
              activeRegions={activeRegionsList}
              onChange={(value?: ISelectOption) => onChange(value)}
            />
          </React.Suspense>
        ) : source === 'mlcarousels' ? (
          <React.Suspense fallback={<React.Fragment />}>
            <MlcarouselsAutocomplete
              activeRegion={activeRegion}
              id='associatedCarousel'
              value={{label: associatedCarousel?.name, value: associatedCarousel?.id} as ISelectOption}
              state={isError ? 'disabled' : 'normal'}
              setFields={(value: ISelectOption) => onChange(value)}
            />
          </React.Suspense>
        ) : (
          <Select
            state='disabled'
            options={[]}
            placeholder='Select Carousel'
            id='associatedCarousel'
            appendToBody={true}
          />
        )}
      </>
    );
  },
);

AssociatedCarouselSelect.displayName = 'AssociatedCarouselSelect';
export default AssociatedCarouselSelect;
