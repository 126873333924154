import * as React from 'react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Calendar,
  Center,
  Cluster,
  Cover,
  DateTime,
  Divider,
  Expand,
  FormItem,
  Grid,
  Heading,
  Help,
  Icon,
  ISimpleScheduleEntry,
  Label,
  LazyLoadingContainer,
  Paragraph,
  Popover,
  Range,
  Select,
  Spinner,
  Stack,
  TZoomLevelsScheduler,
  Template,
  TextInput,
  Timeline,
  TimelineEntry,
  Toast,
  Toggle,
  summon,
  secondsToHms,
  IScheduleEntry,
  ISelectedDrop,
  useUndo,
  IDateRange,
  trigger,
} from '@pluto-tv/assemble';
import {debounce, get} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import {popoverActionsProps} from 'helpers/popoverActionProps';

import {hasOverlap} from 'views/programming/channel/utils/recurrence';
import {
  ITimeline,
  useFindDraftTimelinesQuery,
  useFindTimelinesQuery,
} from 'features/channelTimelines/channelTimelinesApi';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {IChannelCatalogItem} from 'models/channelCatalog';
import EpisodesDetails, {
  isSimpleScheduleEntry,
} from 'views/programming/channel/edit/program/components/EpisodesDetails';
import {StateColorType} from 'views/programming/channel/utils';
import {
  findMaxManualOverride,
  formatDateWithTimezone,
  formatScreeningTime,
  getStringMonth,
  nextDay,
  nextMonday,
  prevDay,
  prevSunday,
  startEndOfWeek,
  disablePastDates,
} from 'utils/dateUtils';
import {INestedChannelProgramProps} from '../nestedPropsInterface';
import {
  ITimeframe,
  getDraftTimeline,
  getMinStart,
  getTimelineState,
  mapTimelinesIntoTimelinesEntry,
  mapToSimpleScheduleEntries,
  addEpisodes,
  getStartTimeToDrop,
  sanitizeDraftEventList,
  addDays,
} from './utilities';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';
import {recalculateEventTimes} from './utilities';
import {useMultiSelect} from 'views/programming/channel/edit/program/hooks/useMultiSelect';
import {useChannelCatalogQueue} from 'views/programming/channel/edit/catalog/hooks/useChannelCatalogQueue';
import {
  IChannelCatalogItemWithState,
  useMemoryQueueApiProvider,
} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import {LoadingBox} from 'components/loadingBox';
import {useAppPermissions} from 'app/permissions';
import EpisodeRecurrence from 'views/programming/channel/edit/program/components/EpisodeRecurrence';
import useRecurrenceValidation from 'views/programming/channel/edit/program/hooks/useRecurrenceValidation';

import './index.css';

export type SelectMode = 'vertical-select' | 'horizontal-select';

const EpisodeQueueSearchModal = React.lazy(
  () => import('views/programming/channel/edit/program/components/EpisodeQueueSearchModal'),
);

const LeaveCalendarDialog = React.lazy(
  () => import('views/programming/channel/edit/program/components/LeaveCalendarDialog'),
);
// const Expand = React.lazy(() => import("@pluto-tv/assemble").then((response) => ({default: response.Expand})));

const ProgramPage = React.memo(
  ({
    model,
    programModel,
    onGapsChange,
    programHasChanges,
    setProgramFields,
    setProgramModel,
    onClickSaveHandler,
    isDraft,
    lastProgrammingUpdate,
    attemptToLeave,
    cancelAttemptToLeave,
    setGapChecksPending,
    isChannelDraftDiscarded,
    isDiscardTimelineDraftsLoading,
  }: INestedChannelProgramProps) => {
    const {id: channelId} = useParams<{id: string}>();

    // Ref to track mounted state
    const isMountedRef = React.useRef(false);

    // Update the ref on mount/unmount
    React.useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
      };
    }, []);

    const [isQueueExpanded, setIsQueueExpanded] = React.useState(false);
    const [isRightExpanded, setIsRightExpanded] = React.useState(false);
    const [isSnapOn, setIsSnapOn] = React.useState(false);

    const [isAddEpisodeOpen, setIsAddEpisodeOpen] = React.useState(false);
    const [isRecurrenceOpen, setIsRecurrenceOpen] = React.useState(false);
    const [timeToAddNewEntry, setTimeToAddNewEntry] = React.useState<number | undefined>();

    const [dayReference, setDayReference] = React.useState<Date>(new Date());
    const [viewType, setViewType] = React.useState<'day' | 'week'>('week');
    const [episodeInfo, setEpisodeInfo] = React.useState<'details' | 'recurrence'>('details');

    const [periodToMove, setPeriodToMove] = React.useState<'next' | 'prev' | 'date' | ''>('');
    const [isOpenLeaveDialog, setIsOpenLeaveDialog] = React.useState(false);

    const clipboardRef = React.useRef<(ISimpleScheduleEntry | IChannelCatalogItem)[]>([]);
    const clipboardTimelinesRef = React.useRef<ITimeline[]>([]);

    const [datePickerPopover, setDatePickerPopover] = React.useState(false);
    const pickedDate = React.useRef<Date>();
    const datepickerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      if (datePickerPopover) {
        datepickerRef.current?.focus();
      }
    });

    const [composedRecurrenceMessage, setComposedRecurrenceMessage] = React.useState<boolean>(false);

    const [lastRepeatSchedule, setLastRepeatSchedule] = React.useState<number>();

    const [copyMode, setCopyMode] = React.useState<SelectMode>('vertical-select');

    const {set: setUndo, undo, redo, canUndo, canRedo, reset: resetUndo} = useUndo<ITimeline[]>();

    const {
      isValidRecurrence,
      recurrenceWarning,
      repeatPeriod,
      endRepeatType,
      endRepeatDate,
      timeframe,
      cycleOver,
      occurrences,
      sunRepeat,
      monRepeat,
      tueRepeat,
      wedRepeat,
      thuRepeat,
      friRepeat,
      satRepeat,
      positioning,
      setPositioning,
      setRepeatPeriod,
      setEndRepeatType,
      setEndRepeatDate,
      setTimeframe,
      setCycleOver,
      setOccurrences,
      setSunRepeat,
      setMonRepeat,
      setTueRepeat,
      setWedRepeat,
      setThuRepeat,
      setFriRepeat,
      setSatRepeat,

      handleAddRepeatSchedule: handleRepeatSchedule,
    } = useRecurrenceValidation(channelId);

    const timelineRef = React.useRef<any>();

    const {ableTo, permissions} = useAppPermissions();
    // end recurrence options

    // Entries to display in the calendar view
    const [publishedTimelinesEntries, setPublishedTimelinesEntries] = React.useState<ISimpleScheduleEntry[]>([]);
    const [draftTimelinesEntries, setDraftTimelinesEntries] = React.useState<ISimpleScheduleEntry[] | undefined>();

    const draftTimelines = React.useMemo(() => programModel.draft, [programModel.draft]);

    // Used to clean the entries arrays to not display the green pulse when the calendar
    // is switched from draft to publish or viceversa.
    const [isReady, setIsReady] = React.useState(false);

    React.useEffect(() => {
      setPublishedTimelinesEntries([]);
      setDraftTimelinesEntries([]);
      setIsReady(true);
    }, [isDraft]);

    React.useEffect(() => {
      if (!isReady || !draftTimelines || !isDraft) return;

      setDraftTimelinesEntries(
        mapTimelinesIntoTimelinesEntry(
          draftTimelines || [],
          !!(model.tmsid && model.gracenoteIntegration?.dynamicClip),
          isDraft && ableTo('CHANNEL_EPG_EDIT'),
        ),
      );
    }, [model.gracenoteIntegration?.dynamicClip, model.tmsid, draftTimelines, isDraft, ableTo, isReady]);

    const setDraftTimelinesToModel = React.useCallback(
      (timelines: ITimeline[]): void => {
        setProgramFields({draft: timelines});
      },
      [setProgramFields],
    );

    React.useEffect(() => {
      setGapChecksPending(true);
      setTimeout(() => setShouldCheckForGaps(true), 2500);
    }, [draftTimelinesEntries, setGapChecksPending]);

    const [autofillDaysOptions, setAutofillDaysOptions] = React.useState<{label: string; value: number}[]>([]);
    const [daysToAutofillSelected, setDaysToAutofillSelected] = React.useState<number>(1);

    const [startDate, setStartDate] = React.useState<Date>();
    const [endDate, setEndDate] = React.useState<Date>();

    const [isAddingQueueAutofill, setIsAddingQueueAutofill] = React.useState(false);

    const [selectedEpisodes, setSelectedEpisodes] = React.useState<ISimpleScheduleEntry[]>([]);
    const [selectedQueue, setSelectedQueue] = React.useState<IChannelCatalogItem[]>([]);

    // Flag used to show entries in the right panel when click on them
    const [shouldOpenRightInfoPanel, setShouldOpenRightInfoPanel] = React.useState(false);

    const [isEditingRecurrenceField, setIsEditingRecurrenceField] = React.useState(false);
    const [recurrenceResponseFromModal, setRecurrenceResponse] = React.useState();

    const [lockedEvent, setLockedEvent] = React.useState<ITimeline | null>(null);

    const [zoomLevel, setZoomLevel] = React.useState<TZoomLevelsScheduler>(0);
    const [increment, setIncrement] = React.useState<'small' | 'medium'>('medium');

    const timestampRef = React.useRef<number | null>();

    const handleCopyMode = (mode: SelectMode) => {
      if (mode === copyMode) return;
      setCopyMode(mode);
      trigger('changeTimelineDragHorizontal', mode === 'horizontal-select');
    };

    const handleCopy = () => {
      if (!ableTo('CHANNEL_EPG_EDIT') || (!selectedEpisodes.length && !selectedQueue.length)) return;
      const isTimelineList = selectedEpisodes.length > 0;

      // keep track of copied timelines entries, they are needed when pasting in other weeks
      if (isTimelineList) {
        clipboardTimelinesRef.current = selectedEpisodes.reduce((acc, episode) => {
          const timeline = draftTimelines?.find(timeline => timeline.id === episode.id);
          if (!timeline) return acc;
          return [...acc, timeline];
        }, [] as ITimeline[]);
      }

      clipboardRef.current = isTimelineList ? selectedEpisodes : selectedQueue;

      Toast.success(`${clipboardRef.current.length} items copied to clipboard`);
      // Trigger event so the Timeline component can handle it and show the highlight and the paste icon
      trigger(
        'timeline-copy',
        !isTimelineList
          ? mapToSimpleScheduleEntries(clipboardRef.current)
          : (clipboardRef.current as ISimpleScheduleEntry[]),
      );
    };

    const handleUndo = () => {
      if (!ableTo('CHANNEL_EPG_EDIT') || !canUndo()) return;

      const previousTimeline = undo();

      if (previousTimeline) {
        setDraftTimelinesToModel(previousTimeline);
      }
    };

    const handleRedo = () => {
      if (!ableTo('CHANNEL_EPG_EDIT') || !canRedo()) return;

      const timeline = redo();

      if (timeline) {
        setDraftTimelinesToModel(timeline);
      }
    };

    const handleDelete = () => {
      const episodesToRemove = selectedEpisodes.filter(episode => !episode.locked);
      if (
        !ableTo('CHANNEL_EPG_EDIT') ||
        !episodesToRemove.length ||
        (isRightExpanded && episodeInfo === 'recurrence' && isEditingRecurrenceField)
      )
        return;

      const newDraftTimeline = (draftTimelines || []).filter(timeline => {
        return !episodesToRemove.some(episode => episode.id === timeline.id);
      });

      const allDraftTimelinesSanitized = sanitizeDraftEventList(
        newDraftTimeline,
        getCurrentStartDate().start,
        isSnapOn,
      );

      setDraftTimelinesToModel(allDraftTimelinesSanitized);
      setSelectedEpisodes(prevEpisodes => prevEpisodes.filter(episode => episode.locked));
      setUndo(allDraftTimelinesSanitized);
      trigger('selectedTimelineEntries', []);
    };

    const handleRemove = (id: string) => {
      const newArray = (draftTimelines || []).filter(item => item.id !== id);

      const {start: currStartDate} = getCurrentStartDate();
      const allDraftTimelinesSanitized = sanitizeDraftEventList(newArray, currStartDate, isSnapOn);

      setDraftTimelinesToModel(allDraftTimelinesSanitized);

      setSelectedEpisodes(prevItems => prevItems.filter(item => item.id !== id));
      setUndo(allDraftTimelinesSanitized);
    };

    const debouncedZoom = React.useMemo(() => debounce(val => setZoomLevel(val), 500), []);

    // These are used to enable the Autofill button
    const [existGapsInTimeline, setExistGapsInTimeline] = React.useState(false);
    const [openMsgWhenAutofillDisabled, setOpenMsgWhenAutofillDisabled] = React.useState(false);

    const [shouldCheckForGaps, setShouldCheckForGaps] = React.useState(true);

    useToggleSearchBarOnSlash(
      setIsQueueExpanded,
      isQueueExpanded,
      model?.gracenoteIntegration?.enabled || isAddEpisodeOpen,
    );

    const {
      currentData: draftTimelinesApiResponse,
      isLoading: isDraftTimelinesLoading,
      isFetching: isDraftTimelinesFetching,
      isSuccess: isDraftTimelinesSuccess,
      isError: isDraftTimelinesError,
    } = useFindDraftTimelinesQuery(
      {
        channelId,
        startDate: startDate?.getTime() || 0,
        endDate: endDate?.getTime() || 0,
        lastUpdate: lastProgrammingUpdate.valueOf(),
        draftDiscarded: isChannelDraftDiscarded,
      },
      {skip: !startDate && !endDate, refetchOnMountOrArgChange: true},
    );

    const {
      currentData: timelines,
      isLoading: isTimelinesLoading,
      isFetching: isTimelinesFetching,
      isSuccess: isTimelinesSuccess,
      isError: isTimelinesError,
    } = useFindTimelinesQuery(
      {
        channelId,
        startDate: startDate?.getTime() || 0,
        endDate: endDate?.getTime() || 0,
        lastUpdate: lastProgrammingUpdate.valueOf(),
        draftDiscarded: isChannelDraftDiscarded,
      },
      {skip: !startDate && !endDate, refetchOnMountOrArgChange: true},
    );

    const {createNewTimelinesAutoFill, findEpisodeInQueue} = useMemoryQueueApiProvider();

    const {
      queueItems: queueItemsRetrieved,
      isLazyLoading: isQueueLazyLoading,
      isLoading: isQueueLoading,
      isError: isQueueError,
      totalItems: queueTotalItems,
      handleLazyLoad: handleQueueLazyLoad,
      duration: queueDuration,
    } = useChannelCatalogQueue(false, model.id);

    const [handleClickQueueEntries] = useMultiSelect<IChannelCatalogItem>(
      queueItemsRetrieved,
      selectedQueue,
      setSelectedQueue,
    );

    const [handleClickItems] = useMultiSelect<ISimpleScheduleEntry>(
      isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries,
      selectedEpisodes,
      setSelectedEpisodes,
    );

    const canModifyEdit = React.useMemo(
      (): boolean =>
        !model?.gracenoteIntegration?.enabled &&
        !model?.cfaasIntegration?.enabled &&
        isDraft &&
        ableTo('CHANNEL_EPG_EDIT'),
      [isDraft, model?.gracenoteIntegration?.enabled, model?.cfaasIntegration?.enabled, ableTo],
    );

    const handleCloseRightPanel = React.useCallback(() => {
      setIsRightExpanded(false);
      setShouldOpenRightInfoPanel(false);
      setSelectedEpisodes([]);
      setSelectedQueue([]);
      setEpisodeInfo('details');
      trigger('selectedTimelineEntries', []);
      setIsRecurrenceOpen(false);
    }, []);

    // This will close right panel and clean all selected list items
    useKeyboardShortcut('escape', handleCloseRightPanel);

    // Open recurrence from shortcut
    useKeyboardShortcut('ctrl+i, command+i', () => setIsRecurrenceOpen(!!(selectedEpisodes.length === 1)));

    React.useEffect(() => {
      if (selectedEpisodes.length || selectedQueue.length) {
        setEpisodeInfo('details');
      }
    }, [selectedEpisodes, selectedQueue]);

    React.useEffect(() => {
      handleCloseRightPanel();
    }, [handleCloseRightPanel, isDraft]);

    const isScheduleLoading = React.useMemo(() => {
      if (isDraft) {
        return isDraftTimelinesLoading || isDraftTimelinesFetching;
      }

      return isTimelinesLoading || isTimelinesFetching;
    }, [isDraft, isTimelinesLoading, isDraftTimelinesLoading, isDraftTimelinesFetching, isTimelinesFetching]);

    const transformEntry = React.useCallback((item: IScheduleEntry) => <EpisodeTemplate item={item} />, []);
    const transformTopEntry = React.useCallback((item: IScheduleEntry) => <EpisodeTopTemplate item={item} />, []);

    const getTimelinesConsideringError = (isSuccess: boolean, timelines?: ITimeline[]): ITimeline[] => {
      if (isSuccess) {
        return timelines || [];
      }

      return [];
    };

    React.useEffect(() => {
      if (isDraftTimelinesLoading || isTimelinesLoading || isDraftTimelinesFetching || isTimelinesFetching) return;

      if ((isDraftTimelinesSuccess || isDraftTimelinesError) && (isTimelinesSuccess || isTimelinesError)) {
        const {events, borderEvent} = getDraftTimeline(
          getTimelinesConsideringError(isDraftTimelinesSuccess, draftTimelinesApiResponse),
          getTimelinesConsideringError(isTimelinesSuccess, timelines),
        );

        const draftEvents = events.map(item => {
          const start = new Date(item.start);
          const {state, stateMsgList} = getTimelineState({...item, ...item.episode});
          return {
            ...item,
            start,
            stop: new Date(item.stop),
            locked: start < getMinStart() || model.gracenoteIntegration?.enabled || model.cfaasIntegration?.enabled,
            state,
            stateMsgList,
          };
        });

        setProgramModel({
          draft: draftEvents,
          published: timelines?.map(t => ({...t, stop: new Date(t.stop)})),
          startDate: startDate?.getTime() || 0,
          endDate: endDate?.getTime() || 0,
        });

        if (borderEvent) {
          setLockedEvent(borderEvent);
        }
        setUndo(draftEvents, {locked: true});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isDraftTimelinesLoading,
      isTimelinesLoading,
      draftTimelinesApiResponse,
      timelines,
      endDate,
      isDraftTimelinesSuccess,
      isTimelinesSuccess,
      isDraftTimelinesError,
      isTimelinesError,
      model.gracenoteIntegration?.dynamicClip,
      model.gracenoteIntegration?.enabled,
      model.tmsid,
      setProgramModel,
      startDate,
    ]);

    React.useEffect(() => {
      let {startDate: newStartDate, endDate: newEndDate} = startEndOfWeek(dayReference);

      newStartDate = new Date(newStartDate.setHours(0, 0, 0, 0));
      newEndDate = new Date(newEndDate.setHours(23, 59, 59, 999));

      // when switching from viewType day to week there's no need to set again the start and end dates.
      if (startDate?.getTime() === newStartDate.getTime() && endDate!.getTime() === newEndDate.getTime()) return;

      setStartDate(newStartDate);
      setEndDate(newEndDate);

      const todayDate = new Date();
      let totalDays: number;
      let selectedDayToStart = 1;

      if (newStartDate > todayDate) {
        // Future week
        totalDays = 7;
      } else if (newEndDate < todayDate) {
        // Previous week
        totalDays = 0;
        selectedDayToStart = 0;
      } else {
        // current week
        totalDays = 7 - todayDate.getDay() + 1;
      }

      const options: {label: string; value: number}[] = [];

      for (let index = 0; index < totalDays; index++) {
        options.push({label: (index + 1).toString(), value: index + 1});
      }

      setAutofillDaysOptions(options);
      setDaysToAutofillSelected(selectedDayToStart);
      cancelAttemptToLeave();

      isDraft ? setDraftTimelinesEntries([]) : setPublishedTimelinesEntries([]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayReference]);

    const [isPageLoading, setIsPageLoading] = React.useState(true);

    React.useEffect(() => {
      setTimeout(() => {
        if (isMountedRef.current) {
          setIsPageLoading(!startDate || isDraftTimelinesLoading || isTimelinesLoading || isQueueLoading);
        }
      }, 500);
    }, [isDraftTimelinesLoading, isTimelinesLoading, isQueueLoading, startDate]);

    React.useEffect(() => {
      if (isDraft || !isReady) return;

      if (!isTimelinesLoading && timelines?.length) {
        const publishedTimelines: ITimeline[] = timelines.map(timeline => {
          const {state, stateMsgList} = getTimelineState({...timeline, ...timeline.episode});

          return {
            ...timeline,
            start: new Date(timeline.start),
            stop: new Date(timeline.stop),
            locked:
              new Date(timeline.start) < getMinStart() ||
              model.gracenoteIntegration?.enabled ||
              model.cfaasIntegration?.enabled,
            state,
            stateMsgList,
          };
        });
        setPublishedTimelinesEntries(
          mapTimelinesIntoTimelinesEntry(
            publishedTimelines,
            !!(model.tmsid && model.gracenoteIntegration?.dynamicClip),
            false,
          ),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isTimelinesLoading,
      model.tmsid,
      timelines,
      isDraft,
      isReady,
      model.gracenoteIntegration?.enabled,
      model.cfaasIntegration?.enabled,
    ]);

    React.useEffect(() => {
      if (isQueueError) {
        Toast.error('There was an error fetching the Episodes queue, please try again.');
        setIsAddingQueueAutofill(false);
      }
    }, [isQueueError]);

    React.useEffect(() => {
      const checkForGaps = () => {
        if (isDraft && shouldCheckForGaps && endDate) {
          if (!draftTimelines?.length) {
            setGapChecksPending(false);
            setExistGapsInTimeline(true);
            onGapsChange([]);
            return;
          }

          let existGaps = false;
          let gaps = timelineRef?.current?.getGaps();

          onGapsChange(gaps);

          if (gaps?.length > 0) {
            const now = new Date();
            gaps = gaps.filter(gap => gap.end <= endDate.getTime() && gap.end > now.getTime());
            existGaps = gaps?.length > 0;
          }

          if (!existGaps && endDate && draftTimelines?.length) {
            // the last event ends before the endDate of the calendar
            existGaps = draftTimelines[draftTimelines.length - 1].stop <= endDate;
          }
          setExistGapsInTimeline(existGaps);
          setShouldCheckForGaps(false);
          setGapChecksPending(false);
        }
      };

      checkForGaps();
    }, [draftTimelines, endDate, isDraft, onGapsChange, shouldCheckForGaps, setGapChecksPending]);

    const switchView = React.useCallback(
      (viewType: 'day' | 'week'): void => {
        if (viewType === 'day') {
          const today = new Date();
          const dayRefereceToSet = startDate && endDate && startDate <= today && endDate >= today ? today : startDate;

          setDayReference(dayRefereceToSet!);
        }

        setViewType(viewType);
      },
      [endDate, startDate],
    );

    // define canMoveToPeriod as a function that receives an string and returns a boolean
    const canMoveToPeriod = (dayToMove: Date): boolean => {
      if (!programHasChanges) {
        return true;
      } else if (viewType === 'week') {
        return false;
      }
      // view type === 'day'
      if (dayToMove >= startDate! && dayToMove <= endDate!) {
        return true;
      }
      return false;
    };

    const cleanPeriod = () => {
      timestampRef.current = undefined;
      setShouldOpenRightInfoPanel(false);
      setSelectedEpisodes([]);
      setSelectedQueue([]);
      resetUndo();
    };

    // move to period can receive a boolean or undefined
    const moveToPeriod = (moveWithoutSaving = false): void => {
      if (!periodToMove) {
        return;
      }
      if (periodToMove === 'next') {
        moveToNextPeriod(moveWithoutSaving);
      } else if (periodToMove === 'prev') {
        moveToPrevPeriod(moveWithoutSaving);
      } else {
        if (pickedDate.current) {
          moveToDate(pickedDate.current, moveWithoutSaving);
        }
      }

      cleanPeriod();
    };

    const moveToNextPeriod = (moveWithoutSaving = false) => {
      const dayToMove = viewType === 'week' ? nextMonday(dayReference) : nextDay(dayReference);

      if (!canMoveToPeriod(dayToMove) && !moveWithoutSaving) {
        setPeriodToMove('next');
        setIsOpenLeaveDialog(true);
        attemptToLeave(true);
        return;
      }
      setPeriodToMove('');

      setDayReference(dayToMove);
      cleanPeriod();
    };

    const moveToPrevPeriod = (moveWithoutSaving = false) => {
      const dayToMove = viewType === 'week' ? prevSunday(dayReference) : prevDay(dayReference);

      if (!canMoveToPeriod(dayToMove) && !moveWithoutSaving) {
        setPeriodToMove('prev');
        setIsOpenLeaveDialog(true);
        attemptToLeave(true);
        return;
      }
      setPeriodToMove('');
      setDayReference(dayToMove);
      cleanPeriod();
    };

    const moveToDate = (dayToMove: Date | IDateRange, moveWithoutSaving = false) => {
      if (!canMoveToPeriod(dayToMove as Date) && !moveWithoutSaving) {
        pickedDate.current = dayToMove as Date;
        setPeriodToMove('date');
        setIsOpenLeaveDialog(true);
        attemptToLeave(true);
        setDatePickerPopover(false);
        return;
      }

      pickedDate.current = undefined;
      setPeriodToMove('');
      setDayReference(dayToMove as Date);
      cleanPeriod();

      setDatePickerPopover(false);
    };

    const currentDayToRender = React.useMemo(() => {
      if (dayReference) {
        const month = getStringMonth(dayReference.getMonth());
        const year = dayReference?.getFullYear();
        const day = dayReference?.getDate();

        return `${month} ${day}, ${year}`;
      }
    }, [dayReference]);

    const currentWeekToRender = React.useMemo(() => {
      if (startDate && endDate) {
        const startMonth = getStringMonth(startDate.getMonth());
        const startDay = startDate.getDate();
        const startYear = startDate?.getFullYear();
        const endDay = endDate.getDate();
        const endMonth = getStringMonth(endDate.getMonth());
        const endYear = endDate.getFullYear();

        return `${startMonth} ${startDay} ${endYear !== startYear ? `,${startYear}` : ''} - ${
          endMonth !== startMonth ? endMonth : ''
        } ${endDay}, ${endYear}`;
      }
      return null;
    }, [startDate, endDate]);

    // Start date where events can be added or deleted
    const getCurrentStartDate = React.useCallback(() => {
      const now = new Date();

      const lockedEventStop = lockedEvent ? new Date(lockedEvent.stop) : null;
      const today = lockedEventStop && lockedEventStop > now ? lockedEventStop : now;
      let start = startDate;

      if (!start || start < today) {
        start = today;
      }

      return {start, today};
    }, [lockedEvent, startDate]);

    //#region AUTOFILL

    const getLastTimelineEntry = (start: Date, stop: Date): ITimeline | undefined => {
      if (draftTimelines?.length) {
        const lastTimeline = draftTimelines
          .filter(draft => (draft.start >= start && draft.start <= stop) || (draft.stop >= start && draft.stop <= stop))
          ?.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())[0];

        return lastTimeline;
      }
    };

    const getTimeframes = (start: Date, stop: Date) => {
      // Gaps to fill in

      // if there's no timelines, create only 1 timeframe from our start and stop original values
      let timeframes = [{start, stop}];

      /**
       * This finds the starting point in which to begin autofilling content.
       * This start point is the end of the last programmed episode in the programming range.
       */
      const filteredDraftTimelines = draftTimelines?.filter(
        draft => (draft.stop > start && draft.stop <= stop) || (draft.start >= start && draft.start < stop),
      );

      if (filteredDraftTimelines?.length) {
        let currStart = start;

        timeframes = [];

        filteredDraftTimelines
          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
          ?.forEach(timeline => {
            // if timeline.start is greater than our original start point it means
            // that we have a gap, therefore we add this timeframe in our list
            // and set the start point to be the end of that timeline
            const timelineStart = new Date(timeline.start);
            if (timelineStart.getTime() > currStart.getTime()) {
              timeframes.push({start: currStart, stop: timelineStart});
            }

            currStart = new Date(timeline.stop);
          });

        // check if there's a gap between our last timeline and our stop
        if (stop && stop.getTime() > currStart.getTime()) {
          timeframes.push({start: currStart.getTime() > start.getTime() ? currStart : start, stop: stop});
        }
      }

      return timeframes;
    };

    const createTimelines = async (timeframes: ITimeframe[], lastDraftTimeline: ITimeline | undefined) => {
      const newTimelines = await createNewTimelinesAutoFill(timeframes, lastDraftTimeline);
      const allDraftTimelines = [...(draftTimelines || []), ...(newTimelines || [])];

      const {start} = getCurrentStartDate();
      const allDraftTimelinesSanitized = sanitizeDraftEventList(allDraftTimelines, start || new Date(), isSnapOn);

      setDraftTimelinesToModel(allDraftTimelinesSanitized);
      setUndo(allDraftTimelinesSanitized);
    };

    const autofill = async () => {
      setIsAddingQueueAutofill(true);
      handleCloseRightPanel();

      const daysInMiliSec = (daysToAutofillSelected || 7) * 24 * 60 * 60 * 1000;
      // eslint-disable-next-line prefer-const
      let {start, today} = getCurrentStartDate();
      if (model.tmsid && model.gracenoteIntegration?.dynamicClip && draftTimelines) {
        const {maxManualOverride, todayPlus30Min} = findMaxManualOverride(draftTimelines);

        if (maxManualOverride) {
          start = maxManualOverride;
        } else if (start <= today) {
          start = todayPlus30Min;
        }
      }
      const stopInMiliSec = start.getTime() + daysInMiliSec;

      let stop = new Date(stopInMiliSec);

      if (endDate && stop > endDate) {
        stop = endDate;
      }

      const timeframes = getTimeframes(start, stop);
      const lastTimeline = getLastTimelineEntry(start, stop);

      await createTimelines(timeframes, lastTimeline);
      // Reset state
      setIsAddingQueueAutofill(false);
    };
    //#endregion AUTOFILL

    const addSeriesToCalendar = (items: IChannelCatalogItemWithState[], doToast = true): boolean => {
      if (!!timeToAddNewEntry && items.length) {
        let nextStartEpisode = timeToAddNewEntry;
        let episodeIndex = (draftTimelines || []).length;

        const newDraftTimelines = items.map(item => {
          const timeline: ITimeline = {
            id: `${item.id}-${episodeIndex}`,
            start: new Date(new Date(nextStartEpisode).setMilliseconds(0)),
            stop: new Date(nextStartEpisode + item.allotment * 1000),
            episode: item,
            state: item.state,
            stateMsgList: item.stateMsgList,
            isRecurrenceEvent: false,
          };

          nextStartEpisode = nextStartEpisode + item.allotment * 1000;

          episodeIndex = episodeIndex + 1;

          return timeline;
        });

        addEpisodesToCalendar(newDraftTimelines, doToast);
        return true;
      }
      return false;
    };

    const addEpisodesToCalendar = React.useCallback(
      (newDraftTimelines: ITimeline[], doToast = true) => {
        if (!newDraftTimelines.length) return;

        const currentWeekDraftTimelies = draftTimelines?.filter(draft => startDate && draft.stop >= startDate);

        const allDraftTimelines = addEpisodes(newDraftTimelines || [], currentWeekDraftTimelies || []);

        const allDraftTimelinesSanitized = sanitizeDraftEventList(
          allDraftTimelines,
          getCurrentStartDate().start || new Date(),
          isSnapOn,
        );

        setDraftTimelinesToModel(allDraftTimelinesSanitized);
        setUndo(allDraftTimelinesSanitized);
        if (doToast) {
          Toast.success(`${newDraftTimelines.length} Episodes were added to the Calendar`);
        }
      },
      [draftTimelines, getCurrentStartDate, isSnapOn, setDraftTimelinesToModel, setUndo, startDate],
    );

    const addTimelinesToCalendar = React.useCallback(
      (items: (ISimpleScheduleEntry | IChannelCatalogItem)[], timeToAddNewEntry?: number) => {
        const listHasTimelines = items.some(isSimpleScheduleEntry);

        const startDateToDrop = listHasTimelines
          ? getStartTimeToDrop(items as ISimpleScheduleEntry[], timeToAddNewEntry)
          : timeToAddNewEntry;

        if (
          (!listHasTimelines && !timeToAddNewEntry) ||
          (!startDateToDrop && !timestampRef.current) ||
          (startDateToDrop &&
            startDate &&
            endDate &&
            (new Date(startDateToDrop) < startDate || new Date(startDateToDrop) > endDate))
        ) {
          Toast.error(`No area defined to paste the ${items.length === 1 ? 'entry' : 'entries'} on the calendar.`);
          return;
        }

        if (startDateToDrop) timestampRef.current = startDateToDrop;

        addEpisodesToCalendar(
          recalculateEventTimes(
            items,
            clipboardTimelinesRef.current || [],
            new Date((startDateToDrop || timestampRef.current)!),
            !timeToAddNewEntry ? 'vertical-select' : copyMode,
          ),
        );

        setSelectedEpisodes([]);
        setSelectedQueue([]);
        setShouldOpenRightInfoPanel(false);
      },
      [addEpisodesToCalendar, copyMode, endDate, startDate],
    );

    const handleAddRepeatSchedule = async (episodeToRepeat: IChannelCatalogItem | ISimpleScheduleEntry) => {
      const lastRepeatRequest = new Date().getTime();
      setLastRepeatSchedule(lastRepeatRequest);

      const response = await handleRepeatSchedule(episodeToRepeat, lastRepeatRequest);
      setRecurrenceResponse(response);
    };

    const findOverlappingRecurrentEvents = React.useCallback((allEvents: ITimeline[], recurrentEvents: any[]) => {
      const overlappingRecurrentEvents: any[] = [];
      if (recurrentEvents.length === 0) {
        return overlappingRecurrentEvents;
      }

      recurrentEvents.forEach(recurrentEvent => {
        const recurrentEventStart = luxon.fromISO(recurrentEvent.start);
        const recurrentEventEnd = luxon.fromISO(recurrentEvent.stop);
        if (hasOverlap(recurrentEventStart, recurrentEventEnd, allEvents)) {
          overlappingRecurrentEvents.push({
            start: recurrentEventStart.toISO(),
            stop: recurrentEventEnd.toISO(),
          });
        }
      });
      return overlappingRecurrentEvents;
    }, []);

    const mergeWithoutDuplicates = (draftTimelines: ITimeline[], futureEvents: ITimeline[]) => {
      // Filter out duplicates from futureEvents
      const uniqueFutureEvents = futureEvents.filter(item => {
        return !draftTimelines.some(draft => draft.start === item.start && draft.stop === item.stop);
      });
      return [...draftTimelines, ...uniqueFutureEvents];
    };

    const timelineOnDrop = React.useCallback(
      (selected: ISelectedDrop[], timestamp: number) => {
        if (!selected || !selected.length || !timestamp) {
          return;
        }

        // check if the operation is about moving timelines around the schedule or dropping new ones from the queue
        const isDropping =
          !draftTimelines || !draftTimelines?.find(dt => selected.some(sel => dt.id === sel.id && sel.start));

        if (isDropping) {
          const selection = selectedQueue.length ? selectedQueue : selected;
          const droppedTimelines = selection.map(item =>
            findEpisodeInQueue({...item, episode: item.id}),
          ) as IChannelCatalogItem[];

          addEpisodesToCalendar(
            recalculateEventTimes(
              droppedTimelines,
              clipboardTimelinesRef.current || [],
              new Date(timestamp!),
              copyMode,
            ),
          );
        } else {
          // It is moving timelines position
          const selection = selected || selectedEpisodes;
          const movedTimelines = (draftTimelines || []).filter(dt =>
            selection.some(sel => dt.id === sel.id && sel.start),
          );

          let nextStartEpisode = timestamp;
          const newTimelines = movedTimelines.map(item => {
            const duration = item.episode.allotment * 1000;
            const timeline: ITimeline = {
              ...item,
              start: new Date(new Date(nextStartEpisode).setMilliseconds(0)),
              stop: new Date(nextStartEpisode + duration),
            };
            nextStartEpisode =
              copyMode === 'vertical-select'
                ? nextStartEpisode + duration
                : addDays(new Date(nextStartEpisode), 1).getTime();
            return timeline;
          });

          const existingWithoutMovedTimelines = (draftTimelines || []).filter(
            dt => !selection.some(sel => dt.id === sel.id && sel.start),
          );
          const allDraftTimelines = addEpisodes(newTimelines || [], existingWithoutMovedTimelines);
          const allDraftTimelinesSanitized = sanitizeDraftEventList(
            allDraftTimelines,
            getCurrentStartDate().start || new Date(),
            isSnapOn,
          );

          setDraftTimelinesToModel(allDraftTimelinesSanitized);
          setUndo(allDraftTimelinesSanitized);
        }
        handleCloseRightPanel();
      },
      [
        addEpisodesToCalendar,
        copyMode,
        draftTimelines,
        findEpisodeInQueue,
        getCurrentStartDate,
        handleCloseRightPanel,
        isSnapOn,
        selectedEpisodes,
        selectedQueue,
        setDraftTimelinesToModel,
        setUndo,
      ],
    );

    React.useEffect(() => {
      const checkRepeatScheduleResp = async () => {
        if (!recurrenceResponseFromModal || !lastRepeatSchedule) return;

        /**
         * Triggered when the search is successful
         * - check if there are overlapping events with the current timeline events
         * - if there are no overlapping events, add them to the calendar
         * - if there are overlapping events, display an error message
         */
        const {
          data: repeatScheduleItems,
          error: repeatScheduleError,
          originalArgs: repeatScheduleOriginalArgs,
        } = recurrenceResponseFromModal as any;

        // if isRepeatScheduleSuccess failed, run a toast error to inform the user there are no episodes to add
        if (repeatScheduleError) {
          const message =
            get(repeatScheduleError, 'error.data.message', null) ||
            get(repeatScheduleError, 'error.error.data.message', null) ||
            '';
          if ((message as string) === 'Timelines not found by channel, start and params') {
            Toast.error('There are no episodes to add to the Calendar');
          }
          setRecurrenceResponse(undefined);
          setComposedRecurrenceMessage(false);
          return;
        }

        if (repeatScheduleItems?.length && repeatScheduleOriginalArgs?.lastUpdate === lastRepeatSchedule) {
          // create an empty array to store the draft-timelines that are in the future
          const futureEvents: ITimeline[] = [];
          try {
            // fetch the draft-timelines that are in the future to check for overlapping events
            const startScheduleDate = new Date(repeatScheduleItems[0].start);

            // get the latest element of repeatScheduleItems to use it as stop
            const stopScheduleDate = new Date(repeatScheduleItems[repeatScheduleItems.length - 1].stop);

            let startScheduleTime: number | undefined;
            const stopScheduleTime = stopScheduleDate.getTime();

            // if recurrence starts in a future week
            if (endDate && startScheduleDate > endDate) {
              startScheduleTime = startScheduleDate.getTime();

              // if recurrence starts in the current week and ends in a future week
            } else if (endDate && stopScheduleDate > endDate) {
              startScheduleTime = endDate.getTime() + 1;
            }

            if (startScheduleTime && stopScheduleTime) {
              const resp = await summon.get<void, ITimeline[]>(
                `channels/${channelId}/timeline-drafts?start=${startScheduleTime}&lastUpdate=${lastProgrammingUpdate.valueOf()}&stop=${stopScheduleTime}`,
              );

              if (!resp) return;
              // push the events, but parsing the start and stop for each item as Date
              futureEvents.push(
                ...resp.map(item => {
                  return {
                    ...item,
                    start: new Date(item.start),
                    stop: new Date(item.stop),
                  };
                }),
              );
            }
          } catch (e) {}
          // Concatenate draftTimelines with futureEvents
          const allDraftTimelines = mergeWithoutDuplicates(draftTimelines || [], futureEvents);
          const overlapingEvents = findOverlappingRecurrentEvents(allDraftTimelines, repeatScheduleItems);
          if (overlapingEvents.length > 0) {
            if (composedRecurrenceMessage) {
              // warn the user about the overlapping events
              Toast.warning(
                '1 episode was added to the calendar. Overlapping events were detected and the Recurrence could not be fulfilled as configured.',
              );
            } else {
              Toast.error(
                `There are ${overlapingEvents.length} events that overlap with the current timeline. Please remove them before adding the new events.`,
              );
            }
            setRecurrenceResponse(undefined);
            setComposedRecurrenceMessage(false);
            return;
          }

          let episodeIndex = (draftTimelines || []).length - 1;
          const newEvents = [
            ...(draftTimelines || []),
            ...(repeatScheduleItems || []).map(item => {
              const episode = findEpisodeInQueue(item);
              const start = new Date(item.start);

              episodeIndex = episodeIndex + 1;
              return {
                ...item,
                id: `${item.episode}-${episodeIndex}`,
                start,
                stop: new Date(item.stop),
                locked: start < getMinStart() || model.gracenoteIntegration?.enabled || model.cfaasIntegration?.enabled,
                episode: episode || item.episode || {},
                state: episode?.state,
                stateMsgList: episode?.stateMsgList,
                isRecurrenceEvent: true,
                title: episode?.series.name,
                subTitle: episode?.name,
              };
            }),
          ];

          const {start} = getCurrentStartDate();
          const allDraftTimelinesSanitized = sanitizeDraftEventList(newEvents, start, isSnapOn);
          setDraftTimelinesToModel(allDraftTimelinesSanitized);
          setUndo(allDraftTimelinesSanitized);

          // check if an initial episode was added to the calendar along with the recurrence
          const totalAdded = composedRecurrenceMessage ? repeatScheduleItems.length + 1 : repeatScheduleItems.length;
          Toast.success(`${totalAdded} Episodes were added to the Calendar`);
          setComposedRecurrenceMessage(false);
          setRecurrenceResponse(undefined);
        }
      };

      setTimeout(() => checkRepeatScheduleResp(), 100);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recurrenceResponseFromModal, lastRepeatSchedule]);

    const showScreeningDate = React.useMemo(
      () => shouldOpenRightInfoPanel && selectedEpisodes.length === 1,
      [selectedEpisodes.length, shouldOpenRightInfoPanel],
    );

    const selectedItemsForDetails = React.useMemo(() => {
      if (shouldOpenRightInfoPanel) {
        return [...selectedEpisodes, ...selectedQueue];
      } else {
        return [];
      }
    }, [selectedEpisodes, selectedQueue, shouldOpenRightInfoPanel]);

    React.useEffect(() => {
      setIsRightExpanded(Boolean(selectedItemsForDetails.length) && shouldOpenRightInfoPanel);
    }, [selectedItemsForDetails.length, shouldOpenRightInfoPanel]);

    const dayValue = React.useMemo(
      () => (viewType === 'day' ? dayReference : startDate || new Date()),
      [dayReference, startDate, viewType],
    );

    const lazyLoadQueueItems = React.useMemo(
      () =>
        queueItemsRetrieved.map((item, index) => (
          <TimelineEntry
            type='schedule'
            key={item.id}
            entry={{
              active: selectedQueue.some(queueItem => queueItem.id === item.id),
              duration: item.duration,
              draggable: ableTo('CHANNEL_EPG_EDIT'),
              fullHeight: '3rem',
              height: 'auto',
              id: item.id,
              author: item.author,
              number: item.number,
              season: item.season,
              start: new Date(),
              offsetTop: '0px',
              rating: item.rating,
              title: item.series.name,
              subTitle: item.name,
              state: item.state.toString() as StateColorType,
              errorMessagesWithState:
                item.stateMsgList.length > 0
                  ? item.stateMsgList.map(state => ({
                      state: state.color as StateColorType,
                      message: state.label,
                    }))
                  : undefined,
            }}
            position='relative'
            transform={transformEntry}
            onClick={(_id, ev) => {
              if (!selectedItemsForDetails.length) {
                if ((ev.target as any)?.tagName.toLowerCase() === 'h5') {
                  // will open right panel if the user clicked on the episode/series name
                  // and the panel is closed
                  setShouldOpenRightInfoPanel(true);
                } else {
                  isRightExpanded && setIsRightExpanded(false);
                  shouldOpenRightInfoPanel && setShouldOpenRightInfoPanel(false);
                }
              }

              if (selectedEpisodes.length > 0) {
                setSelectedEpisodes([]);
              }

              handleClickQueueEntries([index]);
            }}
          />
        )),
      [
        ableTo,
        handleClickQueueEntries,
        isRightExpanded,
        queueItemsRetrieved,
        selectedEpisodes,
        selectedItemsForDetails,
        selectedQueue,
        shouldOpenRightInfoPanel,
        transformEntry,
      ],
    );

    const onEntryClick = React.useCallback(
      (id, ev) => {
        if (!selectedItemsForDetails.length) {
          if ((ev.target as any)?.tagName.toLowerCase() === 'h5') {
            // will open right panel if the user clicked on the episode/series name
            // and the panel is closed
            setShouldOpenRightInfoPanel(true);
          } else {
            isRightExpanded && setIsRightExpanded(false);
            shouldOpenRightInfoPanel && setShouldOpenRightInfoPanel(false);
          }
        }

        if (selectedQueue.length > 0) {
          setSelectedQueue([]);
        }
        handleClickItems([
          (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries).findIndex(entry => entry.id === id),
        ]);
      },
      [
        draftTimelinesEntries,
        handleClickItems,
        isDraft,
        isRightExpanded,
        publishedTimelinesEntries,
        selectedItemsForDetails,
        selectedQueue,
        shouldOpenRightInfoPanel,
      ],
    );

    const onClickConflictGroup = React.useCallback(
      entries => {
        if (!selectedItemsForDetails.length) {
          setShouldOpenRightInfoPanel(true);
        }

        if (selectedQueue.length > 0) {
          setSelectedQueue([]);
        }

        const indexes: number[] = [];

        entries.forEach(e => {
          const entryIndex = (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries).findIndex(
            entry => entry.id === e.id,
          );

          if (entryIndex > -1) {
            indexes.push(entryIndex);
          }
        });

        handleClickItems(indexes);
      },
      [
        draftTimelinesEntries,
        handleClickItems,
        isDraft,
        publishedTimelinesEntries,
        selectedItemsForDetails,
        selectedQueue,
      ],
    );

    const canDrop = React.useCallback(timestamp => new Date().valueOf() < timestamp, []);

    const onAddEntry = React.useCallback((timestamp: number) => {
      setTimeToAddNewEntry(timestamp);
      setIsAddEpisodeOpen(true);
      setSelectedEpisodes([]);
    }, []);

    const handleDrop = React.useCallback(() => {
      if (!ableTo('CHANNEL_EPG_EDIT') || !clipboardRef.current.length) return;

      addTimelinesToCalendar(clipboardRef.current, timelineRef.current.getTimestamp());
    }, [ableTo, addTimelinesToCalendar]);

    const preventAddTimeline = React.useMemo(
      () =>
        !isDraft ||
        !ableTo('CHANNEL_EPG_EDIT') ||
        model.gracenoteIntegration?.enabled ||
        model.cfaasIntegration?.enabled,
      [ableTo, isDraft, model],
    );

    const timelineEntries = React.useMemo(
      () => (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries || []),
      [draftTimelinesEntries, isDraft, publishedTimelinesEntries],
    );

    const isTimelineLoading = React.useMemo(
      () => isScheduleLoading || !startDate || isDiscardTimelineDraftsLoading,
      [isDiscardTimelineDraftsLoading, isScheduleLoading, startDate],
    );

    const handleBulkRemove = React.useCallback(
      (entries: ISimpleScheduleEntry[]) => {
        const newArray = (draftTimelines || []).filter(item => !entries.find(e => e.id === item.id));

        const {start: currStartDate} = getCurrentStartDate();
        const allDraftTimelinesSanitized = sanitizeDraftEventList(newArray, currStartDate, isSnapOn);

        setDraftTimelinesToModel(allDraftTimelinesSanitized);

        setSelectedEpisodes(prevItems => prevItems.filter(item => !entries.find(e => item.id === e.id)));
        setUndo(allDraftTimelinesSanitized);
      },
      [draftTimelines, getCurrentStartDate, isSnapOn, setDraftTimelinesToModel, setUndo],
    );

    const estimateItemHeight = React.useCallback(() => 119.167, []);

    useKeyboardShortcut('ctrl+c, command+c', handleCopy);
    useKeyboardShortcut('ctrl+v, command+v', handleDrop);
    useKeyboardShortcut('ctrl+z, command+z', handleUndo);
    useKeyboardShortcut('shift+ctrl+z, shift+command+z', handleRedo);
    useKeyboardShortcut('delete, backspace', (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }
      handleDelete();
    });

    return (
      <>
        <React.Suspense fallback={<LoadingBox />}>
          <LeaveCalendarDialog
            programHasChanges={programHasChanges}
            isOpenLeaveDialog={isOpenLeaveDialog}
            onClickSaveHandler={onClickSaveHandler}
            attemptToLeave={attemptToLeave}
            cancelAttemptToLeave={cancelAttemptToLeave}
            setIsOpenLeaveDialog={setIsOpenLeaveDialog}
            moveToPeriod={moveToPeriod}
          />
        </React.Suspense>

        <Cluster growNthChild={2} fullHeight wrap={false}>
          <Expand
            isExpanded={isQueueExpanded && ableTo('CHANNEL_EPG_EDIT')}
            fullHeightContainer
            height='100%'
            width='18.825rem'
          >
            <Template label='expandable'>
              <Box borderRight borderSize='1.3125rem' borderColor='shadow' fullHeight background='pewter'>
                <Box
                  borderTop={true}
                  borderSize='0.125rem'
                  borderColor='cavern'
                  fullHeight
                  paddingY={{mobile: 'medium', wide: 'large'}}
                  paddingX={{mobile: 'medium', wide: 'xlarge'}}
                >
                  <Cover scrolling={true} gutterTop='xlarge'>
                    <Template label='header'>
                      <Stack space='large'>
                        <Cluster justify='space-between' align='start'>
                          <Cluster space='small' align='end'>
                            <Heading level='h3'>Queue</Heading>
                            <Paragraph size='small' color='secondary'>
                              {queueTotalItems} {queueTotalItems === 1 ? 'Episode' : 'Episodes'}
                            </Paragraph>
                          </Cluster>
                          <Icon icon='collapseleft' size='large' onClick={() => setIsQueueExpanded(false)} />
                        </Cluster>
                        <Divider color='graphite' />
                        <Cluster space='small'>
                          <Heading level='h5'>
                            Total Content Hours: {isQueueLoading ? '' : secondsToHms(queueDuration || -1)}
                          </Heading>
                          {isQueueLoading && <Spinner size='xxsmall' />}
                        </Cluster>
                        <Divider color='graphite' />
                        <Stack space='xxsmall'>
                          <Cluster growNthChild={1} space='medium'>
                            <FormItem label='Auto-Fill'>
                              <Select
                                value={{label: daysToAutofillSelected.toString()}}
                                options={autofillDaysOptions}
                                onChange={value => setDaysToAutofillSelected(value.value)}
                                state={model.gracenoteIntegration?.enabled ? 'disabled' : ''}
                              />
                            </FormItem>
                            <Box paddingTop='large'>
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={
                                  !existGapsInTimeline &&
                                  openMsgWhenAutofillDisabled &&
                                  !isDraftTimelinesLoading &&
                                  !isQueueLoading
                                }
                              >
                                <Template label='trigger'>
                                  <div
                                    onMouseOver={() =>
                                      !existGapsInTimeline ? setOpenMsgWhenAutofillDisabled(true) : null
                                    }
                                    onMouseLeave={() =>
                                      !existGapsInTimeline ? setOpenMsgWhenAutofillDisabled(false) : null
                                    }
                                  >
                                    <Button
                                      id='autoFillButton'
                                      onClick={autofill}
                                      permission={permissions.CHANNEL_EPG_EDIT}
                                      state={
                                        !existGapsInTimeline || isPageLoading || model.gracenoteIntegration?.enabled
                                          ? 'disabled'
                                          : isAddingQueueAutofill
                                          ? 'thinking'
                                          : ''
                                      }
                                    >
                                      Fill
                                    </Button>
                                  </div>
                                </Template>
                                <Template label='popover'>
                                  <Box paddingX='small' paddingY='xxxxsmall' background='charcoal'>
                                    <Stack>
                                      <Icon space='xxsmall' size='small' icon='info' color='info' iconAlign='center'>
                                        <Help>There are no empty slots in the calendar week to auto fill.</Help>
                                      </Icon>
                                    </Stack>
                                  </Box>
                                </Template>
                              </Popover>
                            </Box>
                          </Cluster>
                          <Divider color='graphite' />
                        </Stack>
                      </Stack>
                    </Template>

                    <Template label='cover'>
                      {isQueueExpanded && (
                        <LazyLoadingContainer
                          virtual={true}
                          estimateItemHeight={estimateItemHeight}
                          onLazyLoad={handleQueueLazyLoad}
                          lazyLoadScrollOffset={10}
                          lazyLoading={isQueueLazyLoading}
                          loading={isQueueLoading}
                          size='medium'
                          items={lazyLoadQueueItems}
                        />
                      )}
                    </Template>
                  </Cover>
                </Box>
              </Box>
            </Template>
          </Expand>
          <Box
            borderTop
            borderSize='0.125rem'
            borderColor='cavern'
            fullHeight
            background='pewter'
            paddingY={{mobile: 'medium', wide: 'large'}}
            paddingX={{mobile: 'medium', wide: 'xlarge'}}
            overflow='auto'
            id='epg-box'
          >
            <Cover scrolling={true}>
              <Template label='header'>
                <Cluster space={{desktop: 'large', wide: 'xxxlarge'}} justify='center' wrap={true} id='epg-header'>
                  <Box marginTop='large' paddingTop='xxxxxxsmall' minWidth='4.3rem'>
                    {!isDraft && (
                      <Button icon='print' onClick={() => window.print()}>
                        Print
                      </Button>
                    )}
                    {canModifyEdit && (
                      <Icon
                        space='xxsmall'
                        icon='collapseleft'
                        rotate={isQueueExpanded ? '' : '180deg'}
                        onClick={() => setIsQueueExpanded(!isQueueExpanded)}
                      >
                        Queue
                      </Icon>
                    )}
                  </Box>
                  <Box minWidth='4rem'>
                    {canModifyEdit && (
                      <FormItem child='Toggle' label='Snap On' state={isPageLoading ? 'disabled' : ''}>
                        <Toggle label='Yes' value={isSnapOn} onChange={() => setIsSnapOn(!isSnapOn)} />
                      </FormItem>
                    )}
                  </Box>
                  <Box minWidth='6.875rem'>
                    {canModifyEdit && (
                      <FormItem label='Increments'>
                        <Select
                          predicate='value'
                          value={{value: increment, label: ''}}
                          options={[
                            {label: '1 Min.', value: 'small'},
                            {label: '15 Min.', value: 'medium'},
                          ]}
                          onChange={val => setIncrement(val.value)}
                        />
                      </FormItem>
                    )}
                  </Box>
                  <Box minWidth='15rem'>
                    <Stack space='smallNegative'>
                      <div
                        id='channel-title'
                        style={{
                          visibility: 'collapse',
                          marginTop: 'calc(1rem / 1.25 * -1)',
                          maxWidth: '15.25rem',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Cluster justify='center' space='small' align='center'>
                          <Heading level='h3' color={isDraft ? 'warning' : 'success'}>
                            {model.name}
                          </Heading>
                        </Cluster>
                      </div>
                    </Stack>
                    <Stack space='small'>
                      <Cluster justify='center' space='small' align='center' id='draft-published'>
                        <Heading level='h3' color={isDraft ? 'warning' : 'success'}>
                          {isDraft ? 'Draft' : 'Published'}
                        </Heading>
                        {!isDraft && <Icon icon='lock' color='success' />}
                      </Cluster>
                      <Cluster space='small' align='center' wrap={false} id='dates-shown'>
                        <Icon icon='chevronleft' onClick={moveToPrevPeriod} />
                        <Center textCenter={true}>
                          <Popover
                            manualTrigger={true}
                            visible={datePickerPopover}
                            onClickOutside={() => setDatePickerPopover(false)}
                            allowedPlacements={['bottom', 'top']}
                          >
                            <Template label='trigger'>
                              <div onClick={() => setDatePickerPopover(p => !p)}>
                                <Heading level='h3'>
                                  {viewType === 'week' ? currentWeekToRender : currentDayToRender}
                                </Heading>
                              </div>
                            </Template>
                            <Template label='popover'>
                              <Box
                                padding={popoverActionsProps.padding}
                                background={popoverActionsProps.background}
                                width={popoverActionsProps.widthWide}
                              >
                                <div tabIndex={-1} style={{outline: 'none'}} ref={datepickerRef}>
                                  <Calendar
                                    id='timeline-day-chooser'
                                    value={dayValue}
                                    onBeforeDateRender={day => {
                                      if (viewType === 'week') {
                                        const isDisabled = day.date?.getDay() !== 1;

                                        return {
                                          ...day,
                                          isDisabled,
                                        };
                                      }

                                      return day;
                                    }}
                                    weekStartsOnMonday={true}
                                    onChange={moveToDate}
                                  />
                                </div>
                              </Box>
                            </Template>
                          </Popover>
                        </Center>
                        <Icon icon='chevronright' onClick={moveToNextPeriod} />
                      </Cluster>
                    </Stack>
                  </Box>
                  <Box minWidth='4.125rem'>
                    {canModifyEdit && (
                      <Cluster space='xxxsmall' wrap={false}>
                        <Stack space='small'>
                          <Label>Horiz.</Label>
                          <Icon
                            color={copyMode === 'horizontal-select' ? 'primary' : 'inherit'}
                            icon='horizontal'
                            onClick={() => handleCopyMode('horizontal-select')}
                          />
                        </Stack>
                        <Stack space='small'>
                          <Label>Vert.</Label>
                          <Icon
                            color={copyMode === 'vertical-select' ? 'primary' : 'inherit'}
                            icon='vertical'
                            onClick={() => handleCopyMode('vertical-select')}
                          />
                        </Stack>
                      </Cluster>
                    )}
                  </Box>
                  <Box minWidth='3.5rem' maxWidth='7.0rem'>
                    <FormItem label='Scale'>
                      <Range id='zoom-slider' min={0} max={2} value={zoomLevel} onChange={debouncedZoom} />
                    </FormItem>
                  </Box>
                  <Box marginTop='medium' paddingTop='xxxxxsmall' minWidth='4rem' height='3.2rem'>
                    <Cluster buttonGroup={true} wrap={false}>
                      <Button active={viewType === 'day' ? true : false} onClick={() => switchView('day')}>
                        Day
                      </Button>
                      <Button active={viewType === 'week' ? true : false} onClick={() => switchView('week')}>
                        Week
                      </Button>
                    </Cluster>
                  </Box>
                </Cluster>
              </Template>

              <Template label='cover'>
                <Timeline
                  id='epg'
                  loading={isTimelineLoading}
                  selected={selectedEpisodes as ISimpleScheduleEntry[]}
                  onPaste={handleDrop}
                  preventAdd={preventAddTimeline}
                  ref={timelineRef}
                  days={viewType === 'day' ? 1 : 7}
                  startDate={dayValue}
                  zoomLevel={zoomLevel}
                  type='schedule'
                  intervals={increment}
                  entries={timelineEntries}
                  entryMinWidth='13rem'
                  canDrop={canDrop}
                  onClearConflictGroup={handleBulkRemove}
                  onClickConflictGroup={onClickConflictGroup}
                  onEntryClick={onEntryClick}
                  onAddEntry={onAddEntry}
                  onDrop={timelineOnDrop}
                  transformEntry={transformEntry}
                  transformTopEntry={transformTopEntry}
                  onHandleWhenClickOutsideAnEntry={handleCloseRightPanel}
                />
              </Template>
            </Cover>
          </Box>
          <Expand
            isExpanded={isRightExpanded}
            fullHeightContainer
            height='100%'
            widthAtDesktopAndDown='25rem'
            width='33rem'
          >
            <Template label='expandable'>
              <Box borderLeft borderSize='1.3125rem' borderColor='shadow' fullHeight background='pewter'>
                <Box borderTop={true} borderSize='0.125rem' borderColor='cavern' fullHeight>
                  <Box paddingY='large' paddingRight='medium' paddingLeft='medium' fullHeight>
                    <Cover scrolling={true} gutterTop='large'>
                      <Template label='header'>
                        <Stack space='large'>
                          <Stack space='xxsmall'>
                            <Cluster wrap={false} space='xsmall' justify='space-between' align='start'>
                              <Icon icon='collapseleft' size='large' rotate='180deg' onClick={handleCloseRightPanel} />
                              {selectedItemsForDetails.length === 1 && (
                                <Heading level='h3' truncate truncateBackgroundHover='pewter'>
                                  {selectedItemsForDetails[0].name}
                                </Heading>
                              )}
                              {selectedItemsForDetails.length > 1 && (
                                <Heading level='h3' truncate truncateBackgroundHover='pewter'>
                                  {`(${selectedItemsForDetails.length}) Episodes Selected`}
                                </Heading>
                              )}
                            </Cluster>
                            {showScreeningDate && (
                              <Box paddingLeft='xlarge'>
                                <Paragraph color='secondary' size='small'>
                                  {formatScreeningTime(
                                    isSimpleScheduleEntry(selectedItemsForDetails[0])
                                      ? selectedItemsForDetails[0].start
                                      : undefined,
                                    selectedItemsForDetails[0].allotment as number,
                                  )}
                                </Paragraph>
                              </Box>
                            )}
                          </Stack>
                          <Divider color='graphite' />
                          <Cluster buttonGroup={true} justify='center'>
                            <Button
                              active={episodeInfo === 'details' ? true : false}
                              onClick={() => setEpisodeInfo('details')}
                            >
                              Details
                            </Button>
                            {selectedItemsForDetails.length === 1 && (
                              <Button
                                active={episodeInfo === 'recurrence' ? true : false}
                                onClick={() => setEpisodeInfo('recurrence')}
                                permission={permissions.CHANNEL_EPG_EDIT}
                              >
                                Recurrence
                              </Button>
                            )}
                          </Cluster>
                        </Stack>
                      </Template>
                      <Template label='cover'>
                        {episodeInfo === 'details' && selectedItemsForDetails.length > 0 && (
                          <EpisodesDetails
                            items={selectedItemsForDetails}
                            onRemove={isDraft ? handleRemove : undefined}
                            onError={handleCloseRightPanel}
                          />
                        )}
                        {episodeInfo === 'recurrence' && (
                          <Stack space='xlarge'>
                            <Heading level='h3' color='secondary'>
                              Set Recurrence
                            </Heading>
                            <Stack space='medium'>
                              <Heading level='h4'>Repeat</Heading>
                              <Grid minimum='11.25rem' gap='medium'>
                                <FormItem
                                  label='Repeat Every'
                                  helpText={recurrenceWarning.timeframe}
                                  state={recurrenceWarning.timeframe ? 'error' : ''}
                                  onFocus={() => {
                                    setIsEditingRecurrenceField(true);
                                  }}
                                  onBlur={() => {
                                    setIsEditingRecurrenceField(false);
                                  }}
                                >
                                  <TextInput
                                    type='number'
                                    value={repeatPeriod}
                                    onChange={value => {
                                      setRepeatPeriod(value);
                                      setIsEditingRecurrenceField(true);
                                    }}
                                  />
                                </FormItem>
                                <FormItem label='Timeframe'>
                                  <Select
                                    predicate='value'
                                    options={[
                                      {label: 'Day(s)', value: 'day'},
                                      {label: 'Week(s)', value: 'week'},
                                      {label: 'Month(s)', value: 'month'},
                                    ]}
                                    value={{label: '', value: timeframe}}
                                    onChange={value => {
                                      setTimeframe(value.value);
                                    }}
                                  />
                                </FormItem>
                              </Grid>
                              {timeframe === 'week' && (
                                <Stack space='small'>
                                  <Label>Repeat on which day(s) of the week</Label>
                                  <Cluster buttonGroup={true}>
                                    <Button active={sunRepeat} onClick={() => setSunRepeat(!sunRepeat)}>
                                      Sun
                                    </Button>
                                    <Button active={monRepeat} onClick={() => setMonRepeat(!monRepeat)}>
                                      Mon
                                    </Button>
                                    <Button active={tueRepeat} onClick={() => setTueRepeat(!tueRepeat)}>
                                      Tue
                                    </Button>
                                    <Button active={wedRepeat} onClick={() => setWedRepeat(!wedRepeat)}>
                                      Wed
                                    </Button>
                                    <Button active={thuRepeat} onClick={() => setThuRepeat(!thuRepeat)}>
                                      Thu
                                    </Button>
                                    <Button active={friRepeat} onClick={() => setFriRepeat(!friRepeat)}>
                                      Fri
                                    </Button>
                                    <Button active={satRepeat} onClick={() => setSatRepeat(!satRepeat)}>
                                      Sat
                                    </Button>
                                  </Cluster>
                                  {/** Provides some extra space */}
                                  <Box height='10px'></Box>
                                </Stack>
                              )}
                              {timeframe === 'month' && (
                                <FormItem label='Positioning'>
                                  <Select
                                    predicate='value'
                                    options={[
                                      {label: 'Same Day of the Week', value: 'week'},
                                      {label: 'Same Day of the Month', value: 'month'},
                                    ]}
                                    value={{label: '', value: positioning}}
                                    onChange={opt => {
                                      setPositioning(opt.value);
                                    }}
                                  />
                                </FormItem>
                              )}
                            </Stack>
                            <Stack space='medium'>
                              <Heading level='h4'>End Repeat</Heading>
                              <FormItem label='End Type'>
                                <Select
                                  predicate='value'
                                  options={[
                                    {label: 'End On Date', value: 'date'},
                                    {label: 'End On Occurence', value: 'after'},
                                  ]}
                                  value={{label: '', value: endRepeatType}}
                                  onChange={value => {
                                    setEndRepeatType(value.value);
                                  }}
                                />
                              </FormItem>
                              {endRepeatType === 'date' && (
                                <div
                                  onBlur={() => {
                                    setIsEditingRecurrenceField(false);
                                  }}
                                  onInput={() => {
                                    setIsEditingRecurrenceField(true);
                                  }}
                                  onFocus={() => {
                                    setIsEditingRecurrenceField(true);
                                  }}
                                >
                                  <FormItem
                                    label='Date'
                                    helpText={recurrenceWarning.occurrences}
                                    state={recurrenceWarning.occurrences ? 'error' : ''}
                                  >
                                    <DateTime
                                      id='endRecurrenceDate'
                                      onBeforeDateRender={date => disablePastDates(date, startDate)}
                                      value={endRepeatDate}
                                      appendToBody={true}
                                      onChange={val => {
                                        setEndRepeatDate(val as Date);
                                        setIsEditingRecurrenceField(true);
                                      }}
                                    />
                                  </FormItem>
                                </div>
                              )}
                              {endRepeatType === 'after' && (
                                <FormItem
                                  label='End after how many occurrences'
                                  helpText={recurrenceWarning.occurrences}
                                  state={recurrenceWarning.occurrences ? 'error' : ''}
                                  onFocus={() => {
                                    setIsEditingRecurrenceField(true);
                                  }}
                                  onBlur={() => {
                                    setIsEditingRecurrenceField(false);
                                  }}
                                >
                                  <TextInput
                                    type='number'
                                    value={occurrences}
                                    onChange={value => setOccurrences(value)}
                                  />
                                </FormItem>
                              )}
                            </Stack>
                            <Stack space='medium'>
                              <Heading level='h4'>Cycle</Heading>
                              <FormItem label='Cycle Over'>
                                <Select
                                  predicate='value'
                                  options={[
                                    {label: 'Episodes and Seasons in the Series', value: 'series'},
                                    {label: 'Episode in this Season', value: 'season'},
                                  ]}
                                  value={{label: '', value: cycleOver}}
                                  onChange={value => {
                                    setCycleOver(value.value);
                                  }}
                                />
                              </FormItem>
                            </Stack>
                            <Cluster justify='space-between'>
                              <div></div>
                              <Button
                                type='primary'
                                state={isValidRecurrence ? '' : 'disabled'}
                                onClick={() =>
                                  handleAddRepeatSchedule(
                                    selectedEpisodes.length ? selectedEpisodes[0] : selectedQueue[0],
                                  )
                                }
                              >
                                Apply
                              </Button>
                            </Cluster>
                          </Stack>
                        )}
                      </Template>
                    </Cover>
                  </Box>
                </Box>
              </Box>
            </Template>
          </Expand>
        </Cluster>
        {isAddEpisodeOpen && (
          <React.Suspense fallback={<React.Fragment />}>
            <EpisodeQueueSearchModal
              isAddEpisodeOpen={isAddEpisodeOpen}
              setIsAddEpisodeOpen={setIsAddEpisodeOpen}
              isRecurrenceOpen={isRecurrenceOpen}
              setIsRecurrenceOpen={setIsRecurrenceOpen}
              addEpisodesToCalendar={addSeriesToCalendar}
              setComposedRecurrenceMessage={setComposedRecurrenceMessage}
              channelId={channelId}
              startDate={startDate}
              setRecurrenceResponse={setRecurrenceResponse}
              timeToAddNewEntry={timeToAddNewEntry}
              setLastRepeatSchedule={setLastRepeatSchedule}
            />
          </React.Suspense>
        )}
        {isRecurrenceOpen && selectedEpisodes.length === 1 && (
          <EpisodeRecurrence
            channelId={channelId}
            startDate={startDate}
            searchSelected={selectedEpisodes[0]}
            setIsRecurrenceOpen={setIsRecurrenceOpen}
            isRecurrenceOpen={isRecurrenceOpen}
            setRecurrenceResponse={setRecurrenceResponse}
            setLastRepeatSchedule={setLastRepeatSchedule}
          />
        )}
      </>
    );
  },
);

const EpisodeTemplate = ({item}: {item: IScheduleEntry}): JSX.Element => (
  <div className='template-test'>
    <Stack space='small'>
      <div className='hide-print'>
        <Heading level='h6' renderAs='h5' color='secondary'>
          {item.author}
        </Heading>
      </div>
      <div className='print-margin'>
        <Heading level='h6' renderAs='h5' color='secondary'>
          (S{item.season}: E{item.number}) {item.rating}
        </Heading>
      </div>
      <div className='hide-print'>
        <Heading level='h6' renderAs='h5' monospace monospaceWeight='normal' color='secondary'>
          {secondsToHms(item.duration || 0)}
        </Heading>
      </div>
    </Stack>
  </div>
);

const EpisodeTopTemplate = ({item}: {item: IScheduleEntry}) => {
  const start = new Date(item.start);
  const stopMs = start.getTime() + item.duration * 1000;
  const stop = new Date(stopMs);

  return (
    <Heading level='h6' renderAs='h5' color='secondary'>
      {`${formatDateWithTimezone(start, 'h:mm a')} - ${formatDateWithTimezone(stop, 'h:mm a')}`}
    </Heading>
  );
};

export default ProgramPage;
