import {Field, RuleGroupType} from '../QueryBuilder/models/queryBuilder';
import {IGenre} from 'models/genres';
import {IAgeRange} from 'models/ageRanges';
import {IContentRating} from 'models/contentRatings';
import {ILanguage} from 'models/languages';
import {ICategory} from 'models/categories';
import {IPartner} from 'models/partners';

const seriesType = [
  {label: 'Film', value: 'film'},
  {label: 'TV', value: 'tv'},
  {label: 'Web Original', value: 'web-original'},
  {label: 'Music Video', value: 'music-video'},
  {label: 'Live', value: 'live'},
];

interface QueryBuilderFieldsProps {
  genres: IGenre[] | undefined;
  ageRanges: IAgeRange[] | undefined;
  contentRatings: IContentRating[] | undefined;
  languages: ILanguage[] | undefined;
  mainCategories: ICategory[] | undefined;
  partners: IPartner[] | undefined;
}

export const getQueryBuilderFields = ({
  genres,
  ageRanges,
  contentRatings,
  languages,
  mainCategories,
  partners,
}: QueryBuilderFieldsProps): Field[] => {
  return [
    {
      name: 'custom',
      label: 'Custom',
      type: 'custom',
      operators: [],
    },
    {
      name: 'title',
      label: 'Title',
      type: 'string',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'notEqual', label: 'Not Equal'},
        {value: 'contains', label: 'Contains'},
      ],
    },
    {
      name: 'primaryGenre',
      label: 'Genre',
      type: 'select-search',
      options:
        genres?.map(item => ({
          value: item.genre,
          label: item.genre,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'notEqual',
          label: 'Not Equal',
        },
      ],
    },
    {
      name: 'partner',
      label: 'Partner',
      type: 'select-search',
      options:
        partners?.map(item => ({
          value: item.name,
          label: item.name,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'notEqual',
          label: 'Not Equal',
        },
      ],
    },
    {
      name: 'actor',
      label: 'Actor',
      type: 'string',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'notEqual', label: 'Not Equal'},
        {value: 'contains', label: 'Contains'},
      ],
    },
    {
      name: 'director',
      label: 'Director',
      type: 'string',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'notEqual', label: 'Not Equal'},
        {value: 'contains', label: 'Contains'},
      ],
    },
    {
      name: 'ageRange',
      label: 'Age Range',
      type: 'select-search',
      options:
        ageRanges?.map(item => ({
          value: item.name,
          label: item.displayName,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'less',
          label: 'Less',
        },
        {
          value: 'lessOrEqual',
          label: 'Less or equal',
        },
        {
          value: 'greater',
          label: 'Greater',
        },
        {
          value: 'greaterOrEqual',
          label: 'Greater or equal',
        },
      ],
    },
    {
      name: 'rating',
      label: 'Rating',
      type: 'select-search',
      options:
        contentRatings?.map(item => ({
          value: item.value,
          label: item.value,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'notEqual',
          label: 'Not Equal',
        },
      ],
    },
    {
      name: 'publishingYear',
      label: 'Publishing Year',
      type: 'year',
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'less',
          label: 'Less',
        },
        {
          value: 'lessOrEqual',
          label: 'Less or equal',
        },
        {
          value: 'greater',
          label: 'Greater',
        },
        {
          value: 'greaterOrEqual',
          label: 'Greater or equal',
        },
      ],
    },
    {
      name: 'releaseDate',
      label: 'Release Date',
      type: 'date-mask',
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'less',
          label: 'Less',
        },
        {
          value: 'lessOrEqual',
          label: 'Less or equal',
        },
        {
          value: 'greater',
          label: 'Greater',
        },
        {
          value: 'greaterOrEqual',
          label: 'Greater or equal',
        },
      ],
    },
    {
      name: 'seriesType',
      label: 'Series Type',
      type: 'select-search',
      options: seriesType,
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
      ],
    },
    {
      name: 'metadataLanguage',
      label: 'Metadata Language',
      type: 'select-search',
      options:
        languages?.map(item => ({
          value: item.iso639_1,
          label: item.name,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
      ],
    },
    {
      name: 'category',
      label: 'Category',
      type: 'select-search',
      options:
        mainCategories?.map(item => ({
          value: item.category,
          label: item.category,
        })) ?? [],
      operators: [
        {
          value: 'equal',
          label: 'Equal',
        },
        {
          value: 'notEqual',
          label: 'Not Equal',
        },
        {
          value: 'contains',
          label: 'Contains',
        },
      ],
    },
    {
      name: 'availStart',
      label: 'Avail Start',
      type: 'date',
      operators: [
        {value: 'equal', label: 'Equal'},
        {
          value: 'less',
          label: 'Before',
        },
        {
          value: 'lessOrEqual',
          label: 'On or Before',
        },
        {
          value: 'greater',
          label: 'After',
        },
        {
          value: 'greaterOrEqual',
          label: 'On or After',
        },
      ],
    },
    {
      name: 'availEnd',
      label: 'Avail End',
      type: 'date',
      operators: [
        {value: 'equal', label: 'Equal'},
        {
          value: 'less',
          label: 'Before',
        },
        {
          value: 'lessOrEqual',
          label: 'On or Before',
        },
        {
          value: 'greater',
          label: 'After',
        },
        {
          value: 'greaterOrEqual',
          label: 'On or After',
        },
      ],
    },
    {
      name: 'availStartWindow',
      label: 'Avail Start Window',
      type: 'number',
      operators: [
        {value: 'lessOrEqual', label: 'Next'},
        {value: 'greaterOrEqual', label: 'Previous'},
      ],
    },
    {
      name: 'availEndWindow',
      label: 'Avail End Window',
      type: 'number',
      operators: [
        {value: 'lessOrEqual', label: 'Next'},
        {value: 'greaterOrEqual', label: 'Previous'},
      ],
    },
    {
      name: 'rank',
      label: 'Rank',
      type: 'number',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'less', label: 'Less'},
        {value: 'lessOrEqual', label: 'Less Or Equal'},
        {value: 'greater', label: 'Greater'},
        {value: 'greaterOrEqual', label: 'Greater or equal'},
      ],
    },
    {
      name: 'ivaScore',
      label: 'IVA Score',
      type: 'number',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'less', label: 'Less'},
        {value: 'lessOrEqual', label: 'Less Or Equal'},
        {value: 'greater', label: 'Greater'},
        {value: 'greaterOrEqual', label: 'Greater or equal'},
      ],
    },
    {
      name: 'searchTrendingRank',
      label: 'Search Trending Rank',
      type: 'number',
      operators: [
        {value: 'equal', label: 'Equal'},
        {value: 'less', label: 'Less'},
        {value: 'lessOrEqual', label: 'Less Or Equal'},
        {value: 'greater', label: 'Greater'},
        {value: 'greaterOrEqual', label: 'Greater or equal'},
      ],
    },
  ];
};

export const initialQuery: RuleGroupType = {
  combinator: 'and',
  rules: [
    {
      combinator: 'and',
      rules: [],
    },
  ],
};
